import axios from "axios";
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

export const getForm = createAsyncThunk(
    'getForm',
    async () => {
        const response = await axios.get('http://localhost:5000/posts')
        return response.data
    }
)
export const setForm = createAsyncThunk(
    'setForm',
    async (form, thunkAPI) => {
        const response = await axios.post('http://localhost:5000/posts', form)
        return response.data
    }
)
export const formSlice = createSlice({
    name: 'form',
    initialState: {
        forms: []
    },
    extraReducers: {
        [getForm.fulfilled]: (state, action) => {
            state.forms = action.payload
        },
        [setForm.fulfilled]: (state, action) => {
            state.forms = [...state.forms, action.payload]
        }
    }
})
export default formSlice.reducer
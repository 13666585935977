import React from 'react';
import '../App.scss'

const Footer = () => {

    const email = "hire@aument.com";
    const phone = "+374 77 47 18 67";

    return (
        <div className="footer">
            <img style={{width:'200px',height:'auto'}} src={require("../img/Frame4029.png")} alt=""/>
            <div style={{color:'white'}}>
                <p>Feedback</p>
                <ul>
                    <li>
                        Phone: <a style={{textDecoration:'none',color:'white'}} href={`tel:${phone}`}>{phone}</a>

                    </li>
                    <li>
                        Email: <a style={{textDecoration:'none',color:'white'}} href={`mailto:${email}`}>{email}</a>
                    </li>
                    <li>
                        <a style={{color:'#0579B6'}} target="_blank" href="https://www.linkedin.com/mwlite/company/aument-it">
                            <i style={{fontSize:'25px'}} className="fa fa-linkedin-square" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
import React from 'react';

const Svg3 = () => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_30_13" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <rect width="40" height="40" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_30_13)">
                    <path d="M14.25 33.3334H25.75L27.4167 26.6667H12.5833L14.25 33.3334ZM14.25 36.6667C13.4722 36.6667 12.7917 36.4306 12.2083 35.9584C11.625 35.4862 11.2361 34.875 11.0417 34.125L9.16667 26.6667H30.8333L28.9583 34.125C28.7639 34.875 28.375 35.4862 27.7917 35.9584C27.2083 36.4306 26.5278 36.6667 25.75 36.6667H14.25ZM8.33333 23.3334H31.6667V20H8.33333V23.3334ZM20 13.3334C20 10.5556 20.9722 8.19449 22.9167 6.25004C24.8611 4.3056 27.2222 3.33337 30 3.33337C30 5.83337 29.2083 8.00004 27.625 9.83337C26.0417 11.6667 24.0556 12.7778 21.6667 13.1667V16.6667H35V23.3334C35 24.25 34.6739 25.0345 34.0217 25.6867C33.3683 26.34 32.5833 26.6667 31.6667 26.6667H8.33333C7.41667 26.6667 6.63167 26.34 5.97833 25.6867C5.32611 25.0345 5 24.25 5 23.3334V16.6667H18.3333V13.1667C15.9444 12.7778 13.9583 11.6667 12.375 9.83337C10.7917 8.00004 10 5.83337 10 3.33337C12.7778 3.33337 15.1389 4.3056 17.0833 6.25004C19.0278 8.19449 20 10.5556 20 13.3334Z" fill="currentcolor"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_30_13" x1="-0.346262" y1="-6.43578" x2="35.0857" y2="30.8578" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00CBFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                </defs>
            </svg>

        </div>
    );
};

export default Svg3;
import React, { useState } from "react";
import "../App.scss";

const AboutUs = () => {
    const [open,setOpen] = useState(false)
  return (
    <div id="aboutUs" className="aboutUs">
      <p
        className="aboutUsText"
        style={{
          lineHeight: "76px",
          fontSize: "59.7503px",
          color: "white",
          textAlign: "justify",
        }}
      >
        about us
      </p>
      <p
        className="aboutText"
        style={{
          maxWidth: "639px",
          textAlign: "justify ",
          fontSize: "15px",
          lineHeight: "21px",
          color: "white",
          marginTop: "72px",
        }}
      >
        Welcome to Aument IT, a premier provider of outsourced and staffing
        solutions for businesses around the world. We specialize in offering
        high-quality IT services to companies of all sizes, from startups to
        large corporations, helping them to reduce costs and improve
        productivity. With years of experience in the industry, we understand
        the importance of providing top-notch services to our clients. Our team
        of experts is highly skilled and knowledgeable in various areas of IT,
        including software development, database management, network
        administration, cybersecurity, and more.
        <p style={{display:!open?"none":"block",transition:'1s'}}>
             We leverage this expertise to
        deliver customized solutions that meet the unique needs of each of our
        clients. We offer a range of services to meet your IT needs, including
        outsourcing and staffing. Our outsourcing services allow you to tap into
        the expertise of our skilled professionals without the added cost of
        hiring and training full-time employees. We can help you with project
        development, ongoing maintenance, and support for your IT
        infrastructure. Our staffing solutions, on the other hand, provide you
        with access to a pool of talented IT professionals who can help you fill
        skill gaps and scale your team up or down as needed. We have a rigorous
        screening process to ensure that we only provide you with the best
        candidates who are a perfect match for your specific requirements. At
        Aument IT, we are committed to providing our clients with exceptional
        services and ensuring that they achieve their business goals. We believe
        in building long-term relationships with our clients and becoming a
        trusted partner that they can rely on for all their IT needs. If you are
        looking for reliable and cost-effective outsourced or staffing solutions
        for your business, look no further than Aument IT. Contact us today to
        learn more about our services and how we can help you take your business
        to the next level.</p>
      </p>
      <button onClick={()=>setOpen(!open)} style={{ marginTop: "33px" }}>see more</button>
    </div>
  );
};

export default AboutUs;

import React from "react";
import "../App.scss";
import Expert1 from "../img/expert1";
import "../img/expert.scss";

const Expert = () => {
  return (
    <div className="expert" id="becomeAnExpert">
      <h1
        className="becomeAnExpert"
        style={{
          fontSize: "59.7503px",
          lineHeight: "76px",
          textAlign: "right",
          marginBottom: "13px",
        }}
      >
        become an expert
      </h1>
      <div
        className="expertMainSvgBox"
        style={{
          width: "46%",
          height: "500px",
          position: "absolute",
          left: "-50px",
        }}
      >
        <Expert1 />
      </div>

      <p class="expertText">
        At Aument IT, we believe in empowering our team to become experts in
        their field. We understand that the world of IT is constantly evolving,
        and it's essential to stay up-to-date with the latest technologies and
        best practices to provide our clients with the highest level of service.
        We encourage our team members to continually develop their skills and
        knowledge, and we provide them with the necessary resources and support
        to do so. We invest heavily in training and development programs that
        allow our team members to stay ahead of the curve. Our training programs
        cover various aspects of IT, including software development, database
        management, network administration, cybersecurity, and more. We also
        encourage our team members to pursue industry certifications and attend
        industry conferences to stay abreast of the latest trends and
        technologies. In addition to training and development programs, we
        foster a culture of collaboration and knowledge sharing within our team.
        We believe that when our team members work together and share their
        expertise, everyone benefits. This approach has helped us build a team
        of experts who can deliver customized solutions that meet the unique
        needs of our clients. At Aument IT, we are committed to providing our
        clients with exceptional services and solutions that are backed by our
        team of experts. Our team's dedication to continuous learning and
        development enables us to provide our clients with the highest level of
        service and support. We are proud to be a company of experts who are
        passionate about what we do and are committed to delivering the best
        possible outcomes for our clients.
      </p>
      <button
        style={{
          padding: "15px 50px",
          background:
            "linear-gradient(139.47deg,#00cbff -24.01%,#00ffa1 90.74%)",
          borderRadius: "10px",
          outline: "none",
          border: "none",
          color: "white",
          marginTop: "33px",
          cursor: "pointer",
        }}
      >
        <a style={{textDecoration:'none',color:'white'}} href="#contactUs">join team</a>
      </button>
    </div>
  );
};

export default Expert;

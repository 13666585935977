import React, { useRef, useState } from 'react';
import "../App.scss"
import Back2 from "../img/back2";
import Logo11 from "../img/logo11";

const Logo = () => {

    return (
        <div style={{
            position:'relative'
        }} id="logo" className="logoBox">
            <Back2/>
            <Logo11/>
            <p className='weAreRisingExperts' style={{
                fontSize: '56px',
                lineHeight: "77px",
                color: "white",
                textAlign:'center',
                marginTop:'50px'
            }}>
                we are rising experts
            </p>
            <button style={{
                cursor:"pointer"
            }}>
                <a style={{textDecoration:"none",color:'white',fontFamily:'Mont,serif'}} href="#contactUs">
                    hire an expert
                </a>
            </button>
        </div>
    );
};

export default Logo;
import React from 'react';

const Svg1 = () => {
    return (
        <div>
            <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.01827 15.3335L15.1849 0.333496L24.3516 15.3335H6.01827ZM24.3516 33.6668C22.2683 33.6668 20.4972 32.9379 19.0383 31.4802C17.5805 30.0213 16.8516 28.2502 16.8516 26.1668C16.8516 24.0835 17.5805 22.3124 19.0383 20.8535C20.4972 19.3957 22.2683 18.6668 24.3516 18.6668C26.4349 18.6668 28.206 19.3957 29.6649 20.8535C31.1227 22.3124 31.8516 24.0835 31.8516 26.1668C31.8516 28.2502 31.1227 30.0213 29.6649 31.4802C28.206 32.9379 26.4349 33.6668 24.3516 33.6668ZM0.184937 32.8335V19.5002H13.5183V32.8335H0.184937ZM24.3516 30.3335C25.5183 30.3335 26.5044 29.9307 27.3099 29.1252C28.1155 28.3196 28.5183 27.3335 28.5183 26.1668C28.5183 25.0002 28.1155 24.0141 27.3099 23.2085C26.5044 22.4029 25.5183 22.0002 24.3516 22.0002C23.1849 22.0002 22.1988 22.4029 21.3933 23.2085C20.5877 24.0141 20.1849 25.0002 20.1849 26.1668C20.1849 27.3335 20.5877 28.3196 21.3933 29.1252C22.1988 29.9307 23.1849 30.3335 24.3516 30.3335ZM3.51827 29.5002H10.1849V22.8335H3.51827V29.5002ZM11.9349 12.0002H18.4349L15.1849 6.75016L11.9349 12.0002Z"
                    fill="currentcolor"/>
            </svg>
        </div>
    );
};

export default Svg1;
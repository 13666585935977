import React from 'react';

const Logo11 = () => {
    return (
        <div style={{
            width:'39.5%',
            maxWidth:'39.5%',
            minWidth:'242px'
        }}>
            <svg width="100%" height="100%" viewBox="0 0 657 123" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_5_3)">
                    <path d="M133.184 80.0222V30.4246H169.678V80.6671C169.678 86.884 173.265 90.4868 178.639 90.4868C184.014 90.4868 187.6 86.884 187.6 80.6671V30.4246H224.095V80.0136C224.095 105.87 205.848 122.079 178.648 122.079C151.448 122.079 133.201 105.879 133.201 80.0136L133.184 80.0222Z" fill="white"/>
                    <path d="M374.446 62.9967V118.811H337.96V66.7629C337.96 59.8925 335.512 56.9431 330.95 56.9431C325.25 56.9431 322.477 61.1995 322.477 69.2221V118.811H286.967V66.7629C286.967 60.546 284.357 56.9431 279.795 56.9431C274.583 56.9431 271.484 61.5263 271.484 69.2221V118.811H234.99V30.4246H260.889L264.406 38.2581C270.962 31.2931 279.88 27.1485 289.886 27.1485C300.387 27.1485 309.28 31.7488 315.108 39.4361C321.887 31.766 331.592 27.1485 342.505 27.1485C362.378 27.1485 374.437 42.3683 374.437 62.9881L374.446 62.9967Z" fill="white"/>
                    <path d="M481.644 82.3094H415.81C417.762 90.9082 423.248 95.2419 431.464 95.2419C437.977 95.2419 443.515 92.7827 445.963 88.3629H480.335C474.147 109.473 455.412 122.079 431.464 122.079C402.142 122.079 380.968 102.112 380.968 74.4502C380.968 46.788 401.979 27.1485 431.464 27.1485C460.949 27.1485 482.457 46.6247 482.457 73.3065C482.457 76.2559 482.132 79.1967 481.644 82.3094ZM416.118 65.4559H447.135C445.338 58.0438 439.783 53.839 431.473 53.839C423.65 53.839 418.301 57.717 416.118 65.4559Z" fill="white"/>
                    <path d="M545.338 27.1571C566.512 27.1571 581.336 42.2135 581.336 64.639V118.811H544.85V69.2221C544.85 61.8616 541.752 57.6052 536.377 57.6052C530.026 57.6052 526.278 62.3518 526.278 70.211V118.82H489.783V30.4246H516.007L519.114 37.5616C525.456 30.8374 534.417 27.1485 545.329 27.1485L545.338 27.1571Z" fill="white"/>
                    <path d="M656.276 87.056V118.811H631.679C612.131 118.811 600.405 106.867 600.405 87.2193V57.9234H584.118V48.9205L627.93 1.95421H635.753V30.4246H655.789V57.9234H636.891V80.0222C636.891 84.2786 639.664 87.056 643.892 87.056H656.276Z" fill="white"/>
                    <path d="M68.9679 18.1542C40.5786 18.1542 17.5557 41.2848 17.5557 69.8069C17.5557 98.3289 40.5786 121.451 68.9679 121.451C79.8803 121.451 90.0139 118.029 98.3329 112.199V119C98.3329 120.35 99.4285 121.451 100.772 121.451H117.941C119.285 121.451 120.38 120.35 120.38 119V69.7983C120.38 41.2762 97.3658 18.1456 68.9765 18.1456L68.9679 18.1542ZM68.9679 93.0235C56.224 93.0235 45.8508 82.6104 45.8508 69.8069C45.8508 57.0033 56.224 46.5817 68.9679 46.5817C81.7119 46.5817 92.0765 57.0033 92.0765 69.8069C92.0765 82.6104 81.7119 93.0235 68.9679 93.0235Z" fill="url(#paint0_linear_5_3)"/>
                    <path d="M68.1806 44.5954C55.4367 44.5954 45.0635 55.017 45.0635 67.8206C45.0635 71.2343 45.7995 74.476 47.1176 77.3996C46.2959 75.0177 45.8509 72.4639 45.8509 69.8069C45.8509 57.0033 56.2241 46.5817 68.968 46.5817C78.3141 46.5817 86.385 52.188 90.0225 60.2193C86.89 51.1304 78.2799 44.5954 68.1806 44.5954Z" fill="url(#paint1_linear_5_3)"/>
                    <path d="M90.0223 60.2279C90.844 62.6097 91.2976 65.1635 91.2976 67.8206C91.2976 80.6241 80.9329 91.0372 68.189 91.0372C58.8429 91.0372 50.772 85.4394 47.126 77.3996C50.267 86.4884 58.8771 93.0235 68.9764 93.0235C81.7204 93.0235 92.085 82.6104 92.085 69.8069C92.085 66.3932 91.3489 63.1514 90.0223 60.2279Z" fill="url(#paint2_linear_5_3)"/>
                    <path opacity="0.36" d="M120.38 70.0046V118.923C120.38 120.324 119.25 121.459 117.855 121.459H100.858C99.4626 121.459 98.3329 120.324 98.3329 118.923V112.207C111.616 102.912 120.32 87.4773 120.38 70.0046Z" fill="url(#paint3_linear_5_3)"/>
                    <path d="M14.3033 29.2208C22.183 29.2208 28.5707 22.8032 28.5707 14.8867C28.5707 6.97021 22.183 0.552612 14.3033 0.552612C6.42362 0.552612 0.0358887 6.97021 0.0358887 14.8867C0.0358887 22.8032 6.42362 29.2208 14.3033 29.2208Z" fill="url(#paint4_linear_5_3)"/>
                    <path d="M116.34 49.703C118.437 55.3438 119.593 61.4489 119.593 67.8206C119.593 96.3426 96.5784 119.473 68.1891 119.473C46.895 119.473 28.6221 106.463 20.8251 87.933C28.1257 107.521 46.9292 121.459 68.9765 121.459C97.3743 121.459 120.389 98.3375 120.389 69.8155C120.389 62.6871 118.951 55.8855 116.349 49.703H116.34Z" fill="url(#paint5_linear_5_3)"/>
                    <g opacity="0.58">
                        <path d="M87.6003 27.931C81.6863 22.574 72.0577 20.4673 64.603 21.0434C53.819 21.8775 45.8594 25.8759 47.6996 29.8227C48.4185 31.3705 51.6965 31.0695 55.5907 29.4874C60.2039 27.6128 64.3292 23.2619 71.4072 23.8208C78.8533 24.4055 82.1313 27.5956 86.2823 30.4848C88.1224 31.766 90.4333 30.4848 87.6089 27.931H87.6003Z" fill="url(#paint6_radial_5_3)"/>
                    </g>
                    <g opacity="0.3">
                        <path d="M44.6441 27.5354C44.045 27.931 43.7198 28.8854 43.9508 29.2982C44.3274 29.9775 45.5 30.2183 46.0392 28.7909C46.5784 27.3635 45.654 26.8647 44.6441 27.5354Z" fill="url(#paint7_radial_5_3)"/>
                    </g>
                    <g opacity="0.3">
                        <path d="M47.922 33.503C48.3242 34.2081 49.3769 34.638 49.8648 34.4059C50.6608 34.0189 51.0031 32.7119 49.4283 31.9982C47.8535 31.2845 47.2458 32.3164 47.922 33.503Z" fill="url(#paint8_radial_5_3)"/>
                    </g>
                    <g opacity="0.3">
                        <path d="M26.5337 64.8798C26.9359 65.5849 27.9886 66.0148 28.4765 65.7826C29.2725 65.3957 29.6148 64.0887 28.04 63.375C26.4652 62.6613 25.8575 63.6932 26.5337 64.8798Z" fill="url(#paint9_radial_5_3)"/>
                    </g>
                    <path d="M23.2044 53.667C21.9121 56.8227 23.2644 60.9759 25.2157 61.2855C27.5865 61.6552 30.1627 57.4505 29.1613 53.4434C28.1599 49.4364 24.5054 50.4769 23.2044 53.667Z" fill="url(#paint10_linear_5_3)"/>
                    <path d="M37.5319 35.2399C39.2522 34.5176 40.3306 32.3765 39.6972 31.4995C38.9184 30.4332 36.2994 31.0609 35.1354 33.0043C33.9714 34.9476 35.7945 35.9708 37.5319 35.2399Z" fill="url(#paint11_linear_5_3)"/>
                    <g opacity="0.58">
                        <path d="M41.4175 32.5829C40.0224 34.6294 41.1436 37.6132 42.5045 37.8797C44.8581 38.3269 46.4586 36.0224 46.4586 32.9269C46.4586 29.8313 42.8297 30.5192 41.4261 32.5829H41.4175Z" fill="url(#paint12_radial_5_3)"/>
                    </g>
                    <g opacity="0.58">
                        <path d="M26.585 45.395C25.4382 46.788 26.3197 48.8001 27.4324 48.9635C29.3495 49.2473 30.6761 47.6737 30.6932 45.5842C30.7104 43.4947 27.749 43.9848 26.5936 45.395H26.585Z" fill="url(#paint13_radial_5_3)"/>
                    </g>
                    <g opacity="0.58">
                        <path d="M24.1974 64.0543C23.5042 62.3775 21.3217 62.2314 20.6627 63.1428C19.5243 64.7078 20.2861 66.6253 22.1176 67.6142C23.9492 68.603 24.8992 65.7482 24.1974 64.0543Z" fill="url(#paint14_radial_5_3)"/>
                    </g>
                    <g opacity="0.3">
                        <path d="M6.30077 10.5787C6.31789 11.0259 6.71159 11.516 7.01114 11.5246C7.49899 11.5418 8.00396 10.9915 7.42196 10.2434C6.83997 9.48669 6.27509 9.83064 6.30077 10.5873V10.5787Z" fill="url(#paint15_radial_5_3)"/>
                    </g>
                    <g opacity="0.3">
                        <path d="M9.99828 6.29656C10.0154 6.7437 10.4091 7.23382 10.7087 7.24242C11.1965 7.25962 11.7015 6.7093 11.1195 5.96121C10.5375 5.21312 9.9726 5.54847 9.99828 6.30516V6.29656Z" fill="url(#paint16_radial_5_3)"/>
                    </g>
                    <path d="M6.18952 4.48222C4.7431 5.68604 4.33228 8.0593 5.19671 8.7128C6.25799 9.51248 8.59453 8.13668 9.14228 5.92681C9.69004 3.71693 7.64451 3.2698 6.18096 4.48222H6.18952Z" fill="url(#paint17_linear_5_3)"/>
                    <g opacity="0.58">
                        <path d="M11.1196 2.38413C10.1952 2.76247 10.1096 3.9749 10.606 4.34464C11.4619 4.98095 12.5146 4.55961 13.0624 3.55356C13.6102 2.54751 12.0439 2.00578 11.111 2.39273L11.1196 2.38413Z" fill="url(#paint18_radial_5_3)"/>
                    </g>
                    <g opacity="0.58">
                        <path d="M5.13689 10.8453C5.23104 9.84783 4.20399 9.21152 3.64768 9.47808C2.6891 9.94242 2.56928 11.0775 3.20262 12.0319C3.83597 12.995 5.04275 11.8513 5.13689 10.8453Z" fill="url(#paint19_radial_5_3)"/>
                    </g>
                    <path d="M28.5705 14.8867C28.5705 22.8062 22.1857 29.2208 14.3031 29.2208C9.37331 29.2208 5.03404 26.71 2.47498 22.9007C5.08539 26.1081 9.0652 28.146 13.5072 28.146C21.3898 28.146 27.7746 21.7313 27.7746 13.8119C27.7746 10.8539 26.8845 8.1109 25.3525 5.82364C27.3638 8.29148 28.5705 11.4472 28.5705 14.8867Z" fill="url(#paint20_linear_5_3)"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_5_3" x1="-0.76857" y1="-12.1305" x2="107.928" y2="114.398" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00CBFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_5_3" x1="44.1819" y1="38.9288" x2="72.0876" y2="71.4152" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00AEFF"/>
                        <stop offset="1" stopColor="#00E6A1"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_5_3" x1="53.8018" y1="52.6868" x2="81.716" y2="85.1818" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00FFFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_5_3" x1="128.374" y1="108.69" x2="100.72" y2="89.1297" gradientUnits="userSpaceOnUse">
                        <stop offset="0.31" stopColor="#00CBFF" stopOpacity="0"/>
                        <stop offset="1" stopColor="#0000A1"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_5_3" x1="-5.34755" y1="-8.2009" x2="103.349" y2="118.327" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00CBFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_5_3" x1="37.1808" y1="34.3886" x2="107.379" y2="115.96" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00AEFF"/>
                        <stop offset="1" stopColor="#00E6A1"/>
                    </linearGradient>
                    <radialGradient id="paint6_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.9447 28.2061) rotate(-180) scale(30.6402 30.7835)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF" stopOpacity="0"/>
                    </radialGradient>
                    <radialGradient id="paint7_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.9558 32.3382) rotate(18.1088) scale(2.52947 2.36187)">
                        <stop stopColor="#A1FFFF"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint8_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52.0245 33.9052) rotate(-68.8102) scale(2.87117 2.66168)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint9_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.6861 65.2077) rotate(-68.8102) scale(2.87117 2.66168)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <linearGradient id="paint10_linear_5_3" x1="15.4544" y1="59.488" x2="22.5653" y2="57.2862" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF" stopOpacity="0.1"/>
                    </linearGradient>
                    <linearGradient id="paint11_linear_5_3" x1="46.0073" y1="37.6539" x2="42.3915" y2="35.7628" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF" stopOpacity="0.1"/>
                    </linearGradient>
                    <radialGradient id="paint12_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(44.7345 35.6715) rotate(-3.16469) scale(6.97957 6.04483)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint13_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.4809 45.2625) rotate(-3.1647) scale(5.71603 4.11048)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint14_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.4991 61.369) rotate(114.519) scale(5.73804 4.09471)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint15_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.95777 12.6621) rotate(-40.7821) scale(1.58196 1.47262)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint16_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.6529 8.38666) rotate(-40.7821) scale(1.58197 1.47262)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <linearGradient id="paint17_linear_5_3" x1="-2.08958" y1="4.77418" x2="1.9372" y2="5.53803" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF" stopOpacity="0.1"/>
                    </linearGradient>
                    <radialGradient id="paint18_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.94285 -1.22627) rotate(25.0223) scale(3.15737 2.26681)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <radialGradient id="paint19_radial_5_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.7015 10.3217) rotate(142.561) scale(3.16017 2.2648)">
                        <stop stopColor="#A1FFFF" stopOpacity="0.8"/>
                        <stop offset="1" stopColor="#95D4FF"/>
                    </radialGradient>
                    <linearGradient id="paint20_linear_5_3" x1="7.46471" y1="6.85549" x2="68.0087" y2="77.3356" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00AEFF"/>
                        <stop offset="1" stopColor="#00E6A1"/>
                    </linearGradient>
                    <clipPath id="clip0_5_3">
                        <rect width="656.24" height="121.526" fill="white" transform="translate(0.0358887 0.552612)"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};

export default Logo11;
import React, {useState} from 'react';
import "../App.scss"
import Svg1 from "../img/svg1";
import Svg2 from "../img/svg2";
import Svg3 from "../img/svg3";
const WhatWeDo = () => {

    const [card, setCard] = useState(false)
    const [card1, setCard1] = useState(false)
    const [card2, setCard2] = useState(false)
    const [card3, setCard3] = useState(false)
    return (
        <div className="whatWeDo" id="whatWeDo">
            <p className='whatWeDoText' style={{
                fontSize: '60px',
                color: 'white',
                lineHeight: '76px',
                margin:"0 auto"
            }}>
                what we do
            </p>
            <div className="cards">
                <div className={card ? "cardTrue" : "card"}>
                    <Svg1/>
                    <div className={card ? "divTrue2" : "div2"}>
                        <h1>
                            Software Consulting Services
                        </h1>
                        <p>
                            Software consulting services help
                            plan and implement software improvements
                            as well as staff software development
                            projects with skilled resources.
                            As a software consulting
                        </p>
                    </div>
                    <button onClick={() => {
                        if(card1===true || card2===true || card3===true){
                            setCard1(false)
                            setCard2(false)
                            setCard3(false)
                        }
                        setCard(!card)
                    }}>see more
                    </button>
                </div>
                <div className={card1 ? "cardTrue1" : "card1"}>
                    <Svg2/>
                    <div className={card1 ? "divTrue1" : "div1"}>
                        <h1>
                            Custom Software Development Services
                        </h1>
                        <p style={{
                            height: '92px'
                        }}>
                            Custom software development is a way to
                            get superior software due to advanced
                            functionality a custom solution provides.
                            Building on the experience from over 5 years,
                        </p>
                    </div>
                    <button onClick={() => {
                        if (card===true || card2 === true || card3 === true) {
                            setCard2(false)
                            setCard(false)
                            setCard3(false)
                        }
                        setCard1(!card1)
                    }}>see more
                    </button>
                </div>
                <div className={card2 ? "cardTrue2" : "card2"}>
                    <Svg3/>
                    <div className={card2 ? "divTrue2" : "div2"}>
                        <h1>
                            Software Development Outsourcing
                        </h1>
                        <p>
                            Means handing over all or some software development
                            processes to a third-party vendor while keeping
                            the desired degree of control over the project.
                            This practice helps
                        </p>
                    </div>
                    <button onClick={() => {
                        if (card===true || card1 === true || card3 === true) {
                            setCard1(false)
                            setCard(false)
                            setCard3(false)
                        }
                        setCard2(!card2)
                    }}>see more
                    </button>
                </div>
                <div className={card3 ? "cardTrue2" : "card2"}>
                    <Svg3/>
                    <div className={card3 ? "divTrue2" : "div2"}>
                        <h1>
                            Software Maintenance and Support
                        </h1>
                        <p>
                            A software maintenance company with 5+ years of
                            experience in ITSM, Aument It provides organizations
                            in 30+ industries with maintenance and support services and
                        </p>
                    </div>
                    <button onClick={() => {
                        if(card===true || card1===true || card2===true){
                            setCard1(false)
                            setCard(false)
                            setCard2(false)
                        }
                        setCard3(!card3)
                    }}>see more
                    </button>
                </div>
            </div>
        </div>
    );
};
export default WhatWeDo;
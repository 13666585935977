import React, { useState } from "react";
import "../App.scss";
import ContactImg from "../img/contactImg";
import axios from "axios";

const Contact = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [title, setTitle] = useState();
  const [message, setMessage] = useState("");
  let emailReg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const sendDataContactUs = async (e) => {
    e.preventDefault();
    if (
      name.trim().length &&
      emailReg.test(mail) &&
      title.trim().length &&
      message.trim().length
    ) {
      try {
        const response = await axios.post("", {
          name,
          email: mail,
          title,
          message,
        });
        console.log(response.data);
        setName("");
        setMail("");
        setTitle("");
        setMessage("");
      } catch (error) {
        console.log(error);
      }
    }
  };
  console.log(window.innerWidth);
  return (
    <div className="contact" id="contactUs">
      {window.innerWidth > 499 && <ContactImg />}
      <h1   
        className="contactH1"
        style={{
          fontSize: "60px",
          lineHeight: "77px",
        }}
      >
        CONTACT US
      </h1>
      <p
        className="contactP"
        style={{
          maxWidth: "500px",
          lineHeight: "20px",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        Send us a message and tell us how we can help and how we can reach you.
      </p>
      <form
        style={{
          marginTop: "5px",
          width: "500px",
          zIndex: "1",
          textAlign: "left",
        }}
        onSubmit={sendDataContactUs}
      >
        <div
          className="contactText"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="inpBox">
            <p
              style={{
                fontSize: "smaller",
                textAlign: "left",
              }}
            >
              Full name
            </p>
            <input
              style={{
                width: "100%",
                background: "white",
                borderRadius: "5px",
                lineHeight: "20px",
                color: "#aeaeae",
                padding: "15px 24px",
                outline: "none",
                fontSize: "16px",
                margin: "0 auto",
              }}
              type="text"
              placeholder="Full Name"
              name="user_name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="inpBox">
            <p
              style={{
                fontSize: "smaller",
              }}
            >
              E-mail
            </p>
            <input
              style={{
                width: "100%",
                background: "white",
                borderRadius: "5px",
                lineHeight: "20px",
                color: "#aeaeae",
                padding: "15px 24px",
                outline: "none",
                fontSize: "16px",
                margin: "0 auto",
              }}
              type="email"
              placeholder="E-mail"
              name="user_email"
              required
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </div>
          <div className="inpBox">
            <p
              style={{
                fontSize: "smaller",
              }}
            >
              Title
            </p>
            <input
              style={{
                width: "100%",
                background: "white",
                borderRadius: "5px",
                lineHeight: "20px",
                color: "#aeaeae",
                padding: "15px 24px",
                outline: "none",
                fontSize: "16px",
                margin: "0 auto",
              }}
              type="text"
              placeholder="Title"
              name="subject"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="inpBox">
            <p
              style={{
                fontSize: "smaller",
              }}
            >
              Message
            </p>
            <textarea
              style={{
                width: "100%",
                background: "white",
                borderRadius: "5px",
                lineHeight: "20px",
                color: "#aeaeae",
                padding: "15px 24px",
                outline: "none",
                fontSize: "16px",
                margin: "0 auto",
              }}
              name="message"
              cols="30"
              rows="10"
              placeholder="Type something..."
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button
            onClick={sendDataContactUs}
            className="contactBtn"
            style={{
              padding: "15px 70px",
              background:
                "linear-gradient(139.47deg,#00cbff -24.01%,#00ffa1 90.74%)",
              border: "none",
              borderRadius: "17px",
              outline: "none",
              color: "white",
              marginTop: "20px",
            }}
            type="submit"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};
export default Contact;

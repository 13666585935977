import React from 'react';
import "../App.scss"

const Header = () => {
    return (
        <ul id="header" className="Nav">
            <li>
                <a href="#aboutUs">about us</a>
            </li>
            <li>
                <a href="#whatWeDo">what we do</a>
            </li>
            <li>
                <a href="#becomeAnExpert">become an expert</a>
            </li>
            <li>
                <a href="#contactUs">contact us</a>
            </li>
        </ul>
    );
};

export default Header;
import './App.scss'
import Header from "./components/header";
import Logo from "./components/logo";
import AboutUs from "./components/aboutUs";
import WhatWeDo from "./components/whatWeDo";
import Expert from "./components/expert";
import Contact from "./components/contact";
import Swipper from "./components/swipper";
import Back1 from "./img/back1";
import Footer from "./components/footer";
import OurPartners from "./components/ourPartners";


function App() {


    return (
        <div className="App">
            <main className="main">
                <Back1/>
                <Header/>
                <Logo/>
                <AboutUs/>
                <WhatWeDo/>
                <Expert/>
                <Swipper/>
                <OurPartners/>
                <Contact/>
            </main>
            <Footer/>

        </div>
    );
}

export default App;

import React, {useEffect, useRef} from 'react';
import './back2.scss'
const ContactImg = () => {
    // const myElem = useRef(null)
    // const svgRef = useRef(null) //may be deleted
    // const handleMouseEnter = () => {
    //     if (myElem.current) {
    //         const tags = Array.from(myElem.current.querySelectorAll('path'));
    //         tags.forEach(tag => {
    //             tag.style.transform = `translate(${Math.random() * 25}px, ${(Math.random() * 35) - 17}px)`;
    //             tag.style.transition = "1s"
    //         })
    //     }
    // }
    // const handleMouseLeave = () => {
    //     if (myElem.current) {
    //         const tags = Array.from(myElem.current.querySelectorAll('path'));
    //         tags.forEach((tag) => {
    //             tag.style.transform = `translate(0px, 0px)`;
    //         });
    //     }
    // }
    // useEffect(() => { //may be deleted
    //     const observer = new IntersectionObserver(
    //       (entries) => {
    //         entries.forEach((entry) => {
    //           if (entry.isIntersecting) {
    //             handleMouseEnter()
    //             svgRef.current.classList.add('animate');
    //           } else {
    //             handleMouseLeave()
    //             svgRef.current.classList.remove('animate');
    //           }
    //         });
    //       },
    //       { threshold: 0.5 }
    //     );
    //
    //     observer.observe(svgRef.current);
    //
    //     return () => {
    //       observer.unobserve(svgRef.current);
    //     };
    // }, []);
    return (
        <div className='contactIMG' style={{
            position:'absolute',
            top:'0px',
        }}>
            <svg max="1458" height="1070" viewBox="0 0 1458 1504" fill="none" xmlns="http://www.w3.org/2000/svg">
                <svg width="1458" height="1504" viewBox="0 0 1458 1504" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_30_81)">
                        <path
                            d="M341.054 1148.1C368.962 1152.03 395.541 1162.52 418.607 1178.71C459.353 1206.65 468.675 1255.96 455.356 1299.84C444.407 1335.95 417.141 1370.28 378.962 1383.66C354.945 1392.09 330.203 1397.33 304.161 1393.44C106.991 1363.89 142.197 1555.3 82.8644 1642.19C42.4573 1700.96 -17.5668 1710.26 -59.7883 1668.77C-101.101 1628.07 -92.4858 1584.21 -68.1662 1544.86C-60.1194 1531.81 -45.7129 1519.51 -32.5961 1506.49C-16.4675 1490.48 -1.90412 1473.35 -3.20278 1451.23C-7.0593 1384.52 -76.6424 1403.68 -128.561 1346.89C-217.567 1249.5 -87.1547 1089.79 54.0654 1230.55C182.935 1359.04 223.064 1131.15 341.054 1148.1Z"
                            stroke="url(#paint0_linear_30_81)" strokeWidth="0.02" strokeMiterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M336.989 1143.62C372.345 1148.56 397.219 1162.76 418.459 1174.87C462.54 1200.01 474.188 1251.06 461.923 1296.66C451.752 1334.47 423.089 1372.19 382.427 1383.87C355.471 1391.61 330.7 1398.63 305.514 1395.31C111.925 1369.87 146.213 1560.23 85.0328 1646.99C43.3563 1706.1 -17.9213 1713.49 -59.9164 1670.96C-101.039 1629.11 -91.2228 1585.1 -66.992 1545.35C-58.9412 1532.07 -45.0079 1519.49 -32.4094 1506.29C-16.9787 1490.02 -3.01426 1472.95 -4.71951 1450.7C-9.80367 1384.06 -79.2563 1401.84 -131.056 1344.14C-218.829 1246.35 -89.8573 1087.76 50.5345 1226.17C178.657 1352.58 219.188 1127.17 336.989 1143.62Z"
                            stroke="url(#paint1_linear_30_81)" strokeWidth="0.04" strokeMiterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M333.369 1139.43C370.47 1144.49 395.604 1160.42 418.748 1171.35C466.078 1193.71 480.142 1246.5 468.926 1293.78C459.583 1333.29 429.368 1374.29 386.336 1384.38C356.389 1391.39 331.628 1400.22 307.271 1397.54C117.363 1376.16 150.503 1565.44 87.6171 1652.17C44.5763 1711.54 -17.8643 1717.07 -59.633 1673.51C-100.553 1630.52 -89.4595 1586.36 -65.4453 1546.18C-57.4025 1532.71 -43.8745 1519.85 -31.8717 1506.41C-17.1179 1489.9 -3.75615 1472.86 -5.87157 1450.51C-12.1751 1383.92 -81.5135 1400.37 -133.192 1341.7C-219.674 1243.57 -92.2087 1086.06 47.3678 1222.13C174.695 1346.33 215.747 1123.44 333.369 1139.43Z"
                            stroke="url(#paint2_linear_30_81)" strokeWidth="0.07" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M330.259 1135.44C369.115 1140.6 394.452 1158.26 419.546 1168.02C470.088 1187.57 486.614 1242.12 476.435 1291.08C467.855 1332.39 436.121 1376.48 390.751 1385.08C357.791 1391.31 333.074 1402 309.6 1399.85C123.365 1382.74 155.344 1570.74 90.7685 1657.4C46.355 1717.04 -17.3527 1720.86 -58.7866 1676.14C-99.6162 1632.1 -87.163 1587.66 -63.3139 1547.09C-55.2954 1533.45 -42.1985 1520.3 -30.7443 1506.64C-16.6885 1489.88 -3.90072 1472.85 -6.42631 1450.41C-13.9446 1383.9 -83.1571 1398.95 -134.722 1339.34C-219.974 1240.85 -93.9243 1084.41 44.811 1218.13C171.303 1340.31 212.82 1119.88 330.259 1135.44Z"
                            stroke="url(#paint3_linear_30_81)" strokeWidth="0.09" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M327.583 1131.66C368.214 1136.91 393.683 1156.33 420.791 1164.9C474.419 1181.79 493.559 1237.97 484.411 1288.59C476.639 1331.65 443.306 1378.76 395.626 1385.99C359.584 1391.43 334.985 1404.02 312.355 1402.43C129.907 1389.56 160.606 1576.28 94.3505 1662.93C48.5598 1722.82 -16.3354 1724.82 -57.5135 1679.04C-98.1694 1633.88 -84.4529 1589.23 -60.7513 1548.29C-52.7619 1534.47 -40.0959 1521.02 -29.1822 1507.13C-15.8445 1490.18 -3.60589 1473.13 -6.59226 1450.61C-15.3124 1384.19 -84.4154 1397.87 -135.892 1337.35C-219.916 1238.54 -95.3446 1083.11 42.6317 1214.53C168.396 1334.47 210.402 1116.46 327.583 1131.66Z"
                            stroke="url(#paint4_linear_30_81)" strokeWidth="0.11" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M325.49 1127.9C367.874 1133.26 393.54 1154.48 422.601 1161.78C479.32 1176.03 501.114 1233.77 492.936 1286.12C485.972 1330.94 450.985 1381.06 401.057 1386.91C361.954 1391.51 337.469 1406.03 315.676 1405C137.034 1396.57 166.418 1581.85 98.4897 1668.47C51.3134 1728.61 -14.7404 1728.78 -55.6708 1681.97C-96.1448 1635.66 -81.1347 1590.84 -57.624 1549.48C-49.6508 1535.49 -37.424 1521.77 -27.0461 1507.66C-14.3898 1490.44 -2.74145 1473.43 -6.12615 1450.77C-16.0186 1384.41 -85.0125 1396.73 -136.41 1335.26C-219.198 1236.16 -96.0214 1081.8 41.0574 1210.82C165.986 1328.73 208.465 1113.11 325.49 1127.9Z"
                            stroke="url(#paint5_linear_30_81)" strokeWidth="0.13" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M323.866 1124.26C368.005 1129.72 393.817 1152.76 424.886 1158.8C484.659 1170.43 509.07 1229.83 501.936 1283.8C495.819 1330.39 459.127 1383.41 406.942 1387.98C364.777 1391.68 340.433 1408.2 319.462 1407.73C144.715 1403.82 172.691 1587.55 103.124 1674.09C54.5537 1734.51 -12.6787 1732.9 -53.3461 1684.96C-93.6464 1637.53 -77.3991 1592.48 -54.0183 1550.79C-45.6005 1535.66 -35.6968 1521.41 -24.4538 1508.24C-10.6204 1492.28 -1.43292 1473.83 -5.23247 1451.05C-16.3221 1384.8 -85.2272 1395.76 -136.486 1333.3C-218.056 1233.89 -96.3381 1080.62 39.9453 1207.23C164.086 1323.07 207.052 1109.83 323.866 1124.26Z"
                            stroke="url(#paint6_linear_30_81)" strokeWidth="0.16" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M322.712 1120.69C368.626 1126.24 394.576 1151.12 427.653 1155.9C490.467 1164.96 517.55 1225.9 511.427 1281.53C506.048 1329.81 467.772 1385.77 413.339 1389.1C368.072 1391.87 343.921 1410.43 323.727 1410.51C152.974 1411.23 179.48 1593.32 108.191 1679.85C58.2221 1740.52 -10.1308 1737.06 -50.5754 1688.12C-90.7067 1639.54 -73.2127 1594.3 -49.963 1552.21C-42.0479 1537.86 -32.5457 1522.25 -21.3944 1508.98C-7.94436 1492.99 0.375847 1474.33 -3.87622 1451.46C-16.1806 1385.29 -84.9588 1394.91 -136.109 1331.49C-216.48 1231.82 -96.1865 1079.44 39.2874 1203.79C162.667 1317.49 206.118 1106.59 322.712 1120.69Z"
                            stroke="url(#paint7_linear_30_81)" strokeWidth="0.18" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M322.032 1117.2C369.696 1122.83 395.788 1149.56 430.877 1153.04C496.73 1159.57 526.513 1222.04 521.374 1279.32C516.886 1329.42 476.856 1388.15 420.184 1390.29C371.816 1392.13 347.871 1412.73 328.474 1413.36C161.798 1418.83 186.695 1599.12 113.768 1685.62C62.406 1746.56 -7.07209 1741.21 -47.315 1691.27C-87.2644 1641.55 -68.4947 1596.11 -45.4136 1553.66C-37.5193 1539.12 -28.738 1523.15 -17.8322 1509.72C-4.80879 1493.67 2.63229 1474.85 -2.01703 1451.88C-15.4374 1385.84 -84.196 1394.09 -135.229 1329.68C-214.415 1229.76 -95.4748 1078.38 39.132 1200.36C161.722 1311.94 205.664 1103.38 322.032 1117.2Z"
                            stroke="url(#paint8_linear_30_81)" strokeWidth="0.2" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M321.853 1113.66C371.27 1119.4 397.484 1147.99 434.606 1150.17C503.497 1154.16 535.98 1218.15 531.813 1277.07C528.159 1328.95 486.428 1390.48 427.521 1391.45C376.054 1392.28 352.342 1414.98 333.679 1416.18C171.198 1426.53 194.366 1604.91 119.784 1691.4C67.0317 1752.59 -3.6417 1745.5 -43.5545 1694.38C-83.3137 1643.51 -63.3184 1597.89 -40.3765 1555.06C-32.4984 1540.34 -24.4467 1524.04 -13.7992 1510.45C-1.18134 1494.31 5.41199 1475.42 0.333831 1452.27C-14.2543 1386.32 -82.9457 1393.21 -133.862 1327.83C-211.801 1227.72 -94.3017 1077.26 39.4557 1196.9C161.249 1306.42 205.691 1100.17 321.853 1113.66Z"
                            stroke="url(#paint9_linear_30_81)" strokeWidth="0.22" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M322.104 1110.17C373.284 1115.99 399.628 1146.49 438.778 1147.34C510.662 1148.9 545.878 1214.31 542.7 1274.89C539.888 1328.53 496.467 1392.82 435.323 1392.65C380.826 1392.53 357.262 1417.36 339.361 1419.04C181.225 1434.35 202.513 1610.75 126.293 1697.22C72.1212 1758.66 0.323407 1749.74 -39.3877 1697.63C-79.0989 1645.52 -57.7143 1599.8 -34.9328 1556.6C-27.0709 1541.7 -17.1054 1526.73 -9.3435 1511.27C-0.243503 1493.13 8.63832 1476.01 3.08647 1452.78C-12.6435 1386.93 -81.2842 1392.49 -132.096 1326.12C-208.926 1225.75 -92.7434 1076.27 40.1986 1193.57C161.222 1300.91 206.16 1096.96 322.104 1110.17Z"
                            stroke="url(#paint10_linear_30_81)" stroke-width="0.25" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M322.788 1106.67C375.73 1112.59 402.205 1144.99 443.379 1144.49C518.285 1143.6 556.23 1210.46 554.024 1272.69C552.059 1328.1 506.948 1395.15 443.537 1393.86C385.898 1392.67 362.678 1419.68 345.412 1421.87C191.78 1442.2 211.024 1616.53 133.102 1702.93C77.5236 1764.64 4.58034 1753.9 -34.8832 1700.74C-74.3467 1647.59 -51.7729 1601.58 -29.1695 1557.97C-21.3826 1542.93 -11.8304 1527.69 -4.59716 1511.96C3.80701 1493.68 12.1257 1476.47 6.15072 1453.14C-10.7212 1387.4 -79.3242 1391.61 -130.049 1324.28C-205.726 1223.7 -90.9417 1075.13 41.1849 1190.09C161.637 1295.39 207.071 1093.74 322.788 1106.67Z"
                            stroke="url(#paint11_linear_30_81)" stroke-width="0.27" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M323.902 1103.16C378.615 1109.16 405.213 1143.47 448.401 1141.65C526.348 1138.32 567.04 1206.53 565.77 1270.48C564.66 1327.66 517.834 1397.45 452.176 1395.03C391.455 1392.8 367.815 1419.01 351.996 1424.75C200.894 1423.21 220.036 1622.42 140.566 1708.79C83.5885 1770.76 9.49183 1758.2 -29.7159 1703.99C-68.9236 1649.78 -45.182 1603.48 -22.6873 1559.52C-14.8752 1544.25 -5.83189 1528.87 0.930537 1512.76C8.62596 1494.34 16.4382 1477.1 9.96729 1453.63C-8.02531 1387.99 -76.5989 1390.88 -127.178 1322.49C-201.692 1221.8 -88.2727 1074.15 43.0127 1186.75C162.482 1289.85 208.407 1090.48 323.902 1103.16Z"
                            stroke="url(#paint12_linear_30_81)" stroke-width="0.29" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M325.419 1099.62C381.894 1105.7 408.619 1141.95 453.835 1138.75C534.805 1133.03 578.197 1202.7 577.918 1268.23C577.663 1327.18 529.138 1399.68 461.222 1396.19C397.427 1392.91 373.889 1421.04 358.932 1427.56C211.645 1428.48 229.443 1628.14 148.302 1714.54C89.9053 1776.76 14.6842 1762.37 -24.2888 1707.11C-63.2618 1651.85 -38.318 1605.26 -15.9837 1560.93C-8.18776 1545.48 0.413821 1529.91 6.61356 1513.52C13.6261 1494.97 20.8364 1477.62 13.9682 1454.05C-5.15332 1388.54 -73.7106 1390.09 -124.169 1320.69C-197.561 1219.75 -85.4246 1073.02 45.0527 1183.21C163.721 1284.29 210.146 1087.19 325.419 1099.62Z"
                            stroke="url(#paint13_linear_30_81)" stroke-width="0.31" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M327.327 1096.04C385.578 1102.22 412.429 1140.4 459.651 1135.83C543.704 1127.67 589.786 1198.77 590.449 1265.96C591.063 1326.68 540.833 1401.88 470.681 1397.3C403.786 1392.95 380.366 1423.03 366.258 1430.33C222.792 1433.73 239.222 1633.87 156.523 1720.21C96.7018 1782.66 20.3609 1766.47 -18.3775 1710.16C-57.2565 1653.61 -30.9697 1606.97 -8.78289 1562.28C-1.01601 1546.65 7.14381 1530.88 12.8196 1514.23C19.1621 1495.56 25.8008 1478.13 18.4837 1454.45C-1.74564 1389.04 -70.2941 1389.27 -120.637 1318.83C-192.909 1217.74 -82.1076 1071.9 47.5213 1179.8C165.352 1278.69 212.284 1083.85 327.327 1096.04Z"
                            stroke="url(#paint14_linear_30_81)" stroke-width="0.34" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M329.625 1092.41C389.633 1098.66 416.629 1138.81 465.857 1132.86C552.921 1122.36 601.751 1194.79 603.378 1263.63C604.847 1326.11 552.901 1404.01 480.495 1398.37C410.53 1392.93 387.208 1424.96 373.974 1433.06C234.311 1438.91 249.338 1639.54 165.025 1725.95C103.768 1788.69 26.3107 1770.65 -12.1717 1713.29C-50.8817 1655.59 -23.3403 1608.75 -1.30103 1563.69C6.43678 1547.88 14.1468 1531.94 19.2836 1514.9C24.9479 1496.12 31.0193 1478.59 23.2451 1454.8C1.91619 1389.49 -66.6328 1388.41 -116.855 1316.95C-188.008 1215.7 -78.5245 1070.73 50.2596 1176.31C167.351 1273.06 214.798 1080.45 329.625 1092.41Z"
                            stroke="url(#paint15_linear_30_81)" stroke-width="0.36" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M332.253 1088.72C394.032 1095.05 421.181 1137.15 472.415 1129.83C562.516 1116.93 614.069 1190.75 616.647 1261.23C618.997 1325.49 565.342 1406.06 490.662 1399.37C417.676 1392.76 394.415 1426.84 382.021 1435.73C246.195 1444.02 259.884 1645.13 173.895 1731.54C111.221 1794.5 32.6363 1774.68 -5.55979 1716.29C-44.0879 1657.46 -15.2996 1610.43 6.60038 1564.99C14.3091 1549 21.6255 1532.93 26.1975 1515.5C31.167 1496.64 36.6788 1478.99 28.4215 1455.07C5.98954 1389.89 -62.5725 1387.49 -112.658 1315C-182.738 1213.57 -74.3441 1069.2 53.4384 1172.7C169.931 1267.08 217.665 1076.99 332.253 1088.72Z"
                            stroke="url(#paint16_linear_30_81)" stroke-width="0.38" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M335.255 1084.97C398.791 1091.36 426.077 1135.44 479.312 1126.72C572.502 1111.47 626.752 1186.64 630.272 1258.78C633.485 1324.8 578.135 1408.05 501.193 1400.31C425.078 1392.65 401.96 1428.64 390.429 1438.32C258.418 1449.07 270.706 1650.71 183.193 1737.06C119.155 1800.28 39.3991 1778.62 1.39885 1719.15C-36.952 1659.16 -6.92542 1611.96 14.8436 1566.13C22.5233 1549.95 29.3683 1533.71 33.4318 1515.93C37.7618 1497 42.6717 1479.23 34.0254 1455.21C10.5068 1390.14 -58.0844 1386.45 -108.046 1312.9C-177.013 1211.39 -69.9002 1067.85 57.02 1169.01C172.672 1261.29 220.893 1073.45 335.255 1084.97Z"
                            stroke="url(#paint17_linear_30_81)" stroke-width="0.4" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M338.569 1081.12C403.881 1087.61 431.319 1133.64 486.547 1123.54C582.791 1105.93 639.768 1182.44 644.217 1256.23C648.312 1324.03 591.261 1409.94 512.045 1401.15C432.829 1392.37 409.803 1430.43 399.174 1440.83C270.979 1454.04 281.885 1656.17 192.681 1742.54C127.158 1805.98 46.3426 1782.52 8.5688 1722.03C-29.5872 1660.9 1.68508 1613.46 23.303 1567.3C30.9536 1550.94 37.3744 1534.54 40.8952 1516.41C44.5858 1497.39 48.9026 1479.5 39.7603 1455.34C15.1763 1390.37 -53.4658 1385.39 -103.282 1310.8C-171.179 1209.21 -65.3354 1066.46 60.74 1165.29C175.729 1255.42 224.44 1069.8 338.569 1081.12Z"
                            stroke="url(#paint18_linear_30_81)" stroke-width="0.43" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M342.201 1077.2C409.312 1083.69 436.867 1131.77 494.097 1120.27C593.421 1100.31 653.081 1178.18 658.49 1253.6C663.46 1323.16 604.74 1411.77 523.249 1401.87C440.915 1391.98 418.042 1432 408.149 1443.27C283.769 1458.93 293.282 1661.54 202.478 1747.91C135.523 1811.59 53.596 1786.32 16.0698 1724.78C-21.9171 1662.51 10.6019 1614.88 32.0806 1568.34C39.7151 1551.8 45.7408 1535.22 48.6638 1516.75C51.7454 1497.69 55.4378 1479.64 45.8255 1455.36C20.1677 1390.51 -48.4917 1384.19 -98.1952 1308.59C-164.882 1206.9 -60.3499 1064.95 64.889 1161.44C179.062 1249.43 228.314 1066.07 342.201 1077.2Z"
                            stroke="url(#paint19_linear_30_81)" stroke-width="0.45" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M346.128 1073.16C414.976 1079.78 442.74 1129.81 501.932 1116.89C604.336 1094.59 666.718 1173.77 673.039 1250.87C678.9 1322.2 618.449 1413.43 534.664 1402.6C449.356 1391.56 426.521 1433.56 417.562 1445.59C297.011 1463.72 305.121 1666.81 212.735 1753.16C144.348 1817.09 61.309 1789.99 24.0092 1727.42C-13.7147 1664.02 19.9863 1616.17 41.3397 1569.3C48.9451 1552.59 54.548 1535.9 56.9273 1517.03C59.3565 1497.88 62.4683 1479.71 52.3988 1455.32C25.6756 1390.57 -43.0675 1383.02 -92.5456 1306.33C-158.394 1204.46 -55.0099 1063.4 69.315 1157.51C182.745 1243.38 232.475 1062.24 346.128 1073.16Z"
                            stroke="url(#paint20_linear_30_81)" stroke-width="0.47" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M350.334 1069.02C420.948 1075.7 448.839 1127.72 510.046 1113.41C615.538 1088.76 680.626 1169.29 687.846 1248C694.587 1321.1 632.432 1414.98 546.377 1403.11C458.019 1390.92 435.262 1434.94 427.156 1447.77C310.421 1468.36 317.137 1671.92 223.127 1758.3C153.315 1822.47 69.1778 1793.57 32.0611 1729.9C-5.54553 1665.33 29.4398 1617.26 50.6375 1570.04C58.4753 1553.36 63.4394 1535.47 65.3199 1517.13C67.1355 1497.92 69.636 1479.6 59.1093 1455.1C31.2948 1390.43 -37.4895 1381.63 -86.8869 1303.85C-151.596 1201.96 -49.5157 1061.63 74.0666 1153.43C186.691 1237.21 236.91 1058.28 350.334 1069.02Z"
                            stroke="url(#paint21_linear_30_81)" stroke-width="0.49" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M354.81 1064.75C427.195 1071.5 455.236 1125.51 518.431 1109.8C627.041 1082.77 694.805 1164.67 703.049 1245.1C710.68 1319.98 646.786 1416.54 558.486 1403.61C467.07 1390.28 444.391 1436.32 437.146 1449.94C324.209 1472.94 329.548 1677.01 233.952 1763.4C162.682 1827.8 77.4512 1797.1 40.6207 1732.42C3.21255 1666.68 39.4373 1618.5 60.4947 1570.84C68.2635 1553.94 72.8673 1535.75 74.0777 1517.18C75.2761 1497.93 77.1738 1479.44 66.1641 1454.82C37.3358 1390.29 -31.5674 1380.19 -80.8113 1301.34C-144.541 1199.32 -43.6388 1059.84 79.0094 1149.31C190.898 1230.91 241.607 1054.21 354.81 1064.75Z"
                            stroke="url(#paint22_linear_30_81)" stroke-width="0.52" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M359.539 1060.37C433.668 1067.18 461.884 1123.19 527.067 1106.07C638.732 1076.74 709.17 1159.89 718.331 1241.97C726.851 1318.61 661.277 1417.78 570.653 1403.87C476.208 1389.39 453.598 1437.45 447.215 1451.86C338.146 1477.35 342.091 1681.89 244.836 1768.26C172.141 1832.9 85.7987 1800.38 49.2076 1734.67C11.9731 1667.81 49.4485 1619.4 70.3585 1571.38C78.089 1554.26 82.3663 1535.79 82.9455 1517.02C83.5725 1497.7 84.8086 1479.08 73.3417 1454.35C43.5337 1389.95 -25.5741 1378.53 -74.5998 1298.65C-137.299 1196.67 -37.6436 1057.83 84.2456 1145.01C195.352 1224.47 246.551 1050 359.539 1060.37Z"
                            stroke="url(#paint23_linear_30_81)" stroke-width="0.54" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M364.469 1055.84C440.386 1062.7 468.801 1120.68 535.927 1102.2C650.737 1070.57 723.909 1154.99 733.907 1238.75C743.311 1317.14 676.045 1418.95 583.135 1404.04C485.622 1388.38 463.107 1438.49 457.572 1453.67C352.298 1481.63 354.809 1686.62 256.024 1773.04C181.854 1837.88 94.4524 1803.58 58.096 1736.82C21.0517 1668.8 59.8036 1620.25 80.5226 1571.82C88.205 1554.47 92.1375 1535.7 92.0624 1516.74C92.1016 1497.37 92.7651 1478.62 80.7635 1453.72C49.8514 1389.4 -19.2623 1376.79 -68.1696 1295.79C-129.812 1193.72 -31.4079 1055.7 89.6447 1140.55C200.035 1217.92 251.74 1045.64 364.469 1055.84Z"
                            stroke="url(#paint24_linear_30_81)" stroke-width="0.56" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M369.626 1051.19C447.308 1058.1 475.845 1118.14 544.987 1098.19C662.851 1064.19 738.729 1150.01 749.703 1235.38C759.996 1315.54 691.025 1419.99 595.816 1404.06C495.261 1387.25 472.807 1439.39 468.18 1455.32C366.729 1485.72 367.813 1691.18 267.455 1777.62C191.873 1842.7 103.39 1806.62 67.2051 1738.82C30.338 1669.64 70.2805 1620.85 90.9331 1572.06C98.6005 1554.51 102.225 1535.46 101.537 1516.31C100.997 1496.88 100.955 1477.96 88.527 1452.99C56.5918 1388.76 -12.6011 1374.94 -61.3899 1292.81C-122.029 1190.71 -24.8574 1053.39 95.3892 1135.94C204.926 1211.2 257.112 1041.16 369.626 1051.19Z"
                            stroke="url(#paint25_linear_30_81)" stroke-width="0.58" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M374.983 1046.33C454.445 1053.31 483.102 1115.41 554.266 1094C675.236 1057.68 753.802 1144.82 765.684 1231.83C776.875 1313.75 706.251 1420.88 608.716 1403.92C505.114 1385.92 482.735 1440.11 478.915 1456.88C381.293 1489.75 380.944 1695.66 279.009 1782.14C201.978 1847.45 112.344 1809.52 76.4745 1740.69C39.8106 1670.36 80.966 1621.38 101.468 1572.26C109.102 1554.47 112.391 1535.12 111.063 1515.81C109.968 1496.34 109.261 1477.26 96.3704 1452.16C63.4038 1388.04 -5.95372 1372.96 -54.5596 1289.75C-114.209 1187.61 -18.1705 1051.04 101.193 1131.24C210.006 1204.33 262.729 1036.47 374.983 1046.33Z"
                            stroke="url(#paint26_linear_30_81)" stroke-width="0.61" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M380.52 1041.34C461.735 1048.36 490.605 1112.43 563.698 1089.66C687.787 1051.02 769.049 1139.46 781.852 1228.14C793.893 1311.8 721.573 1421.55 621.778 1403.6C515.134 1384.44 492.828 1440.69 489.878 1458.23C396.059 1493.54 394.329 1699.93 290.795 1786.42C212.318 1851.96 121.612 1812.24 85.9559 1742.36C49.482 1670.89 91.8582 1621.7 112.233 1572.21C119.837 1554.19 122.791 1534.54 120.824 1515.08C119.185 1495.55 117.818 1476.31 104.429 1451.11C70.4391 1387.08 0.955635 1370.77 -47.459 1286.47C-106.113 1184.3 -11.2852 1048.49 107.263 1126.36C215.269 1197.29 268.49 1031.63 380.52 1041.34Z"
                            stroke="url(#paint27_linear_30_81)" stroke-width="0.63" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M386.255 1036.18C469.248 1043.27 498.286 1109.35 573.29 1085.12C700.472 1044.15 784.422 1133.91 798.154 1224.24C811.137 1309.7 737.081 1422.06 634.978 1403.1C525.301 1382.77 503.056 1441.05 500.968 1459.38C410.977 1497.16 407.827 1704.01 302.711 1790.52C222.792 1856.27 130.992 1814.76 95.5629 1743.84C59.2792 1671.21 102.897 1621.82 123.129 1571.98C130.519 1553.78 133.203 1536.14 130.733 1514.15C128.535 1494.56 126.52 1475.19 112.643 1449.84C77.651 1385.9 7.97463 1368.42 -40.2157 1282.96C-97.9177 1180.79 -4.254 1045.69 113.462 1121.24C220.644 1190.04 274.423 1026.62 386.255 1036.18Z"
                            stroke="url(#paint28_linear_30_81)" stroke-width="0.65" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M392.053 1030.8C476.807 1037.93 506.024 1106.04 583.069 1080.44C713.369 1037.14 799.976 1128.18 814.647 1220.2C828.536 1307.41 752.775 1422.41 648.378 1402.46C535.662 1380.93 513.483 1441.27 512.248 1460.41C426.074 1500.64 421.516 1707.95 314.79 1794.51C233.412 1860.49 140.548 1817.17 105.345 1745.21C69.2517 1671.44 114.095 1621.86 134.188 1571.65C141.549 1553.26 143.907 1535.5 140.783 1513.12C138.052 1493.47 135.345 1473.93 120.994 1448.5C84.9921 1384.66 15.1932 1365.92 -32.8435 1279.4C-89.572 1177.21 2.91976 1042.83 119.807 1116.03C226.271 1182.69 280.492 1021.42 392.053 1030.8Z"
                            stroke="url(#paint29_linear_30_81)" stroke-width="0.67" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M398.022 1025.25C484.542 1032.43 513.939 1102.56 592.946 1075.54C726.373 1029.91 815.694 1122.29 831.248 1215.94C846.025 1304.92 768.568 1422.54 661.876 1401.61C546.135 1378.89 524.021 1441.29 523.627 1461.22C441.276 1503.89 435.299 1711.66 327.008 1798.22C244.193 1864.43 150.235 1819.32 115.267 1746.31C79.3635 1671.38 125.441 1621.61 145.387 1571.03C152.705 1552.46 154.759 1534.58 150.985 1511.81C147.709 1492.11 144.331 1472.4 129.501 1446.86C92.4436 1383.16 22.514 1363.17 -25.3279 1275.52C-81.1123 1173.33 10.2366 1039.66 126.292 1110.55C231.997 1175.12 286.706 1016.02 398.022 1025.25Z"
                            stroke="url(#paint30_linear_30_81)" stroke-width="0.7" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M404.166 1019.53C492.414 1026.8 522.016 1098.92 602.985 1070.48C739.532 1022.53 831.528 1116.21 848.011 1211.52C863.699 1302.26 784.536 1422.52 675.537 1400.58C556.757 1376.67 534.709 1441.14 535.152 1461.89C456.595 1506.97 449.234 1715.11 339.333 1801.76C255.055 1868.19 160.024 1821.28 125.282 1747.24C89.5822 1671.16 136.859 1621.2 156.692 1570.25C163.994 1551.5 165.739 1533.48 161.294 1510.34C157.533 1490.6 153.412 1470.74 138.121 1445.07C100.102 1381.4 29.9417 1360.26 -17.7007 1271.5C-72.5021 1169.34 17.6731 1036.34 132.884 1104.9C237.768 1167.31 293.01 1010.42 404.166 1019.53Z"
                            stroke="url(#paint31_linear_30_81)" stroke-width="0.72" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M410.308 1013.51C500.364 1020.76 530.104 1094.97 613.036 1065.11C752.701 1014.84 847.393 1109.83 864.776 1206.8C881.378 1299.27 800.491 1422.24 689.217 1399.25C567.382 1374.16 545.4 1440.69 546.737 1462.18C472.023 1509.78 463.201 1718.45 351.726 1805.12C266.007 1871.74 169.903 1823.04 135.367 1747.97C99.8732 1670.72 148.376 1620.57 168.073 1569.26C175.367 1550.27 176.796 1532.18 171.676 1508.64C167.364 1488.82 162.564 1468.8 146.791 1443.06C107.791 1379.47 37.4249 1357.14 -10.0014 1267.25C-63.9057 1165.08 25.1654 1032.81 139.556 1099.02C243.664 1159.31 299.461 1004.54 410.308 1013.51Z"
                            stroke="url(#paint32_linear_30_81)" stroke-width="0.74" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M416.533 1007.35C508.312 1014.61 538.275 1090.9 623.255 1059.65C766.013 1007.05 863.415 1103.35 881.641 1201.93C899.137 1296.19 816.594 1421.72 702.98 1397.78C578.136 1371.48 556.203 1440.1 558.381 1462.37C487.491 1512.43 477.216 1721.6 364.231 1808.21C277.061 1875.06 179.864 1824.57 145.574 1748.45C110.263 1670.06 159.999 1619.72 179.558 1568.03C186.76 1548.98 187.976 1530.62 182.156 1506.73C177.328 1486.84 171.887 1466.68 155.589 1440.82C115.587 1377.32 45.0279 1353.81 -2.18246 1262.78C-55.172 1160.64 32.7726 1029.05 146.345 1092.95C249.686 1151.02 305.915 998.568 416.533 1007.35Z"
                            stroke="url(#paint33_linear_30_81)" stroke-width="0.76" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M422.847 1000.94C516.421 1008.24 546.543 1086.55 633.413 1053.86C779.324 998.944 879.374 1096.54 898.625 1196.85C917.019 1292.86 832.779 1421.02 716.863 1396.1C588.924 1368.55 567.1 1439.27 570.152 1462.34C503.078 1514.85 491.403 1724.42 376.829 1811.13C288.188 1878.2 189.926 1825.9 155.863 1748.75C120.742 1669.2 171.673 1618.64 191.136 1566.62C198.346 1547.31 199.224 1528.88 192.734 1504.61C187.325 1484.6 181.253 1464.35 164.463 1438.38C123.468 1374.95 52.6949 1350.27 5.71344 1258.11C-46.3614 1155.99 40.4567 1025.08 153.192 1086.64C255.685 1142.63 312.431 992.27 422.847 1000.94Z"
                            stroke="url(#paint34_linear_30_81)" stroke-width="0.79" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M429.207 994.29C524.568 1001.64 554.835 1081.97 643.681 1047.87C792.719 990.62 895.419 1089.51 915.578 1191.47C934.922 1289.33 848.979 1419.98 730.701 1394.11C599.848 1365.43 577.978 1438.16 581.827 1461.98C518.581 1516.95 505.486 1726.98 389.331 1813.71C299.235 1880.99 199.906 1826.91 166.07 1748.72C131.138 1668.02 183.316 1617.28 202.618 1564.88C209.8 1545.38 210.411 1526.8 203.22 1502.18C197.406 1482.09 190.536 1461.69 173.26 1435.63C131.279 1372.26 60.2703 1346.41 13.5049 1253.12C-37.681 1151 48.0703 1020.79 159.961 1080.03C261.775 1133.95 319.05 985.783 429.207 994.29Z"
                            stroke="url(#paint35_linear_30_81)" stroke-width="0.81" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M435.605 987.409C532.761 994.795 563.192 1077.18 653.996 1041.63C806.178 982.082 911.574 1082.27 932.607 1185.89C952.822 1285.43 865.189 1418.84 744.598 1391.89C610.667 1362 588.902 1436.81 593.657 1461.46C534.226 1518.89 519.707 1729.35 401.983 1816.12C310.424 1883.6 210.022 1827.74 176.404 1748.52C141.684 1666.67 195.074 1615.73 214.25 1562.96C221.368 1543.28 221.747 1524.55 213.856 1499.57C207.502 1479.45 199.96 1458.87 182.193 1432.7C139.231 1369.41 67.9785 1342.41 21.4668 1247.95C-28.7858 1145.93 55.8797 1016.29 166.905 1073.26C267.874 1125 325.675 979.029 435.605 987.409Z"
                            stroke="url(#paint36_linear_30_81)" stroke-width="0.83" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M441.997 980.25C540.941 987.687 571.53 1072.1 664.309 1035.14C819.598 973.265 927.69 1074.76 949.644 1180.03C970.743 1281.33 881.427 1417.37 758.571 1389.47C621.611 1358.36 599.895 1435.27 605.519 1460.69C549.905 1520.57 533.944 1731.5 414.651 1818.3C321.651 1885.99 220.168 1828.34 186.797 1748.08C152.267 1665.08 206.857 1613.94 225.899 1560.82C233.022 1540.96 233.108 1522.06 224.517 1496.72C217.655 1476.51 209.431 1455.81 191.151 1429.52C147.217 1366.31 75.7205 1338.15 29.4542 1242.54C-19.9611 1140.49 63.5882 1011.62 173.845 1066.22C273.989 1115.83 332.301 972.019 441.997 980.25Z"
                            stroke="url(#paint37_linear_30_81)" stroke-width="0.85" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M448.362 972.82C549.037 980.253 579.841 1066.75 674.57 1028.36C833.011 964.17 943.765 1066.96 966.62 1173.91C988.638 1276.95 897.676 1415.65 772.448 1386.71C632.471 1354.41 610.804 1433.4 617.269 1459.61C565.491 1521.95 548.101 1733.29 427.236 1820.16C332.773 1888.06 230.23 1828.62 197.073 1747.31C162.734 1663.16 218.541 1611.86 237.472 1558.33C244.566 1538.29 244.386 1519.24 235.074 1493.55C226.73 1469.45 214.914 1446.7 199.999 1426.01C155.08 1362.85 83.3231 1333.57 37.3152 1236.81C-11.2138 1134.86 71.2378 1006.6 180.649 1058.87C280.106 1106.37 338.919 964.733 448.362 972.82Z"
                            stroke="url(#paint38_linear_30_81)" stroke-width="0.88" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M454.715 965.126C557.172 972.588 588.152 1061.15 684.843 1021.34C846.412 954.813 959.849 1058.9 983.604 1167.51C1006.54 1272.33 913.83 1413.6 786.343 1383.73C643.357 1350.21 621.726 1431.28 629.053 1458.28C581.091 1523.07 562.289 1734.85 439.847 1821.77C343.929 1889.87 240.318 1828.66 207.375 1746.31C173.247 1661 230.27 1609.47 249.063 1555.63C256.115 1535.39 255.681 1516.19 245.669 1490.15C236.662 1465.94 224.304 1443.11 208.956 1422.32C163.064 1359.24 91.0427 1328.8 45.2803 1230.89C-2.37273 1128.93 79.0037 1001.39 187.6 1051.32C286.176 1096.64 345.513 957.175 454.715 965.126Z"
                            stroke="url(#paint39_linear_30_81)" stroke-width="0.9" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M461.029 957.153C565.255 964.635 596.42 1055.24 695.066 1014.02C859.774 945.175 975.894 1050.56 1000.56 1160.84C1024.39 1267.41 929.99 1411.32 800.221 1380.46C654.218 1345.74 632.644 1428.88 640.811 1456.68C596.678 1523.93 576.438 1736.14 452.433 1823.12C355.047 1891.41 250.368 1828.42 217.664 1745.04C183.752 1658.6 241.962 1606.86 260.629 1552.64C267.652 1532.23 266.972 1512.82 256.234 1486.46C246.548 1462.1 233.626 1439.16 217.817 1418.25C170.94 1355.23 98.6368 1323.66 53.1621 1224.59C6.39085 1122.71 86.6737 995.792 194.408 1043.37C292.182 1086.61 352.067 949.337 461.029 957.153Z"
                            stroke="url(#paint40_linear_30_81)" stroke-width="0.92" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M467.289 948.892C573.285 956.396 604.639 1049.07 705.234 1006.42C873.074 935.263 991.826 1041.91 1017.44 1153.87C1042.2 1262.2 946.103 1408.73 814.018 1376.83C664.984 1340.9 643.46 1426.13 652.496 1454.7C612.179 1524.41 590.486 1737.08 464.933 1824.08C366.084 1892.59 260.345 1827.81 227.846 1743.4C194.12 1655.78 253.577 1603.86 272.109 1549.28C279.103 1528.68 278.18 1509.08 266.718 1482.4C256.346 1457.94 242.852 1434.92 226.573 1413.91C178.74 1350.94 106.148 1318.26 60.9478 1218.01C15.0242 1116.2 94.2395 989.929 201.188 1035.21C298.199 1076.34 358.555 941.204 467.289 948.892Z"
                            stroke="url(#paint41_linear_30_81)" stroke-width="0.94" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M473.462 940.339C581.236 947.85 612.766 1042.58 715.357 998.464C886.317 925.041 1007.77 1033.01 1034.31 1146.57C1059.98 1256.66 962.189 1405.82 827.794 1373.01C675.721 1335.88 654.263 1423.18 664.179 1452.56C627.677 1524.73 604.576 1737.8 477.451 1824.88C377.151 1893.6 270.327 1827.02 238.055 1741.57C204.515 1652.83 265.213 1600.65 283.594 1545.74C290.559 1524.96 289.399 1505.19 277.207 1478.17C266.168 1453.59 252.115 1430.48 235.372 1409.37C186.575 1346.45 113.666 1312.64 68.7544 1211.23C23.6737 1109.46 101.835 983.849 207.96 1026.8C304.106 1065.72 364.99 932.781 473.462 940.339Z"
                            stroke="url(#paint42_linear_30_81)" stroke-width="0.97" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M479.559 931.48C589.097 938.991 620.808 1035.8 725.341 990.269C899.465 914.484 1023.6 1023.75 1051 1139.02C1077.55 1250.89 978.041 1402.65 841.323 1368.92C686.258 1330.55 664.852 1419.9 675.571 1450.11C642.885 1524.73 618.351 1738.25 489.649 1825.38C387.89 1894.27 280.063 1825.97 247.995 1739.48C214.657 1649.54 276.527 1597.24 294.786 1541.98C301.742 1520.95 300.329 1501.04 287.442 1473.74C277.612 1452.91 265.185 1431.47 243.912 1404.6C194.138 1341.73 120.919 1306.81 76.3029 1204.23C32.1085 1102.55 109.176 977.569 214.486 1018.18C309.883 1054.86 371.302 924.042 479.559 931.48Z"
                            stroke="url(#paint43_linear_30_81)" stroke-width="0.99" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M485.537 922.293C596.832 929.817 628.733 1028.69 735.232 981.763C912.484 903.646 1039.3 1014.21 1067.59 1131.16C1095.08 1244.74 993.899 1399.16 854.834 1364.34C696.746 1324.76 675.349 1416.25 686.962 1447.2C658.1 1524.26 632.128 1738.21 501.876 1825.4C398.641 1894.5 289.648 1824.43 257.903 1736.84C224.769 1645.76 287.807 1593.22 305.995 1537.59C312.897 1516.42 311.267 1496.28 297.65 1468.58C287.359 1447.67 274.197 1426.08 252.417 1399.12C201.687 1336.29 128.183 1300.28 83.8369 1196.51C40.4173 1094.87 116.49 970.563 220.976 1008.85C315.588 1043.62 377.508 914.982 485.537 922.293Z"
                            stroke="url(#paint44_linear_30_81)" stroke-width="1.01" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M491.378 912.781C604.463 920.321 636.553 1021.27 744.973 972.924C925.377 892.492 1054.87 1004.34 1084.06 1122.94C1112.46 1238.3 1009.6 1395.34 868.204 1359.53C707.1 1318.75 685.759 1412.32 698.226 1444.06C673.18 1523.58 645.754 1737.99 513.954 1825.2C409.251 1894.5 299.245 1822.65 267.656 1734.05C234.732 1641.81 299.022 1589.11 317.046 1533.08C323.91 1511.74 322.046 1491.39 307.667 1463.41C296.851 1442.39 283.023 1420.69 260.743 1393.63C209.049 1330.86 135.174 1293.72 91.1841 1188.8C48.5781 1087.23 123.626 963.552 227.288 999.509C321.159 1032.08 383.598 905.593 491.378 912.781Z"
                            stroke="url(#paint45_linear_30_81)" stroke-width="1.03" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M497.089 902.931C611.939 910.47 644.21 1013.5 754.584 963.746C938.141 880.998 1070.29 994.152 1100.39 1114.42C1129.69 1231.52 1025.15 1391.18 881.437 1354.39C717.271 1312.37 696.011 1408.06 709.335 1440.57C688.109 1522.57 659.27 1737.36 525.825 1824.62C419.668 1894.11 308.61 1820.46 277.231 1730.85C244.51 1637.47 310 1584.57 327.901 1528.19C334.732 1506.65 332.635 1486.09 317.489 1457.86C306.169 1436.71 291.663 1414.91 268.875 1387.77C216.194 1325 141.997 1286.8 98.3242 1180.7C56.5317 1079.19 130.546 956.165 233.393 989.782C326.58 1020.21 389.537 895.87 497.089 902.931Z"
                            stroke="url(#paint46_linear_30_81)" stroke-width="1.06" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M502.627 892.753C619.242 900.292 651.715 1005.39 764.022 954.241C950.719 869.17 1085.47 983.582 1116.54 1105.56C1146.77 1224.4 1040.59 1386.75 894.496 1348.93C727.361 1305.71 706.098 1403.46 720.275 1436.77C702.873 1521.21 672.558 1736.41 537.665 1823.8C430.032 1893.49 317.931 1818.03 286.753 1727.42C254.231 1632.88 320.933 1579.78 338.708 1523.03C345.51 1501.31 343.179 1480.55 327.325 1451.98C315.47 1430.67 300.33 1408.81 276.975 1381.61C223.356 1318.92 148.78 1279.6 105.445 1172.31C64.4665 1070.87 137.468 948.491 239.492 979.781C331.831 1007.98 395.324 885.815 502.627 892.753Z"
                            stroke="url(#paint47_linear_30_81)" stroke-width="1.08" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M507.998 882.211C626.378 889.75 659.048 996.906 773.293 944.372C963.135 856.998 1100.63 972.727 1132.54 1096.33C1163.67 1216.94 1055.75 1381.86 907.376 1343.1C737.239 1298.65 716.017 1398.5 731.056 1432.6C717.406 1519.45 685.756 1735.15 549.226 1822.56C440.133 1892.43 326.999 1815.2 296.026 1723.56C263.716 1627.86 331.596 1574.57 349.266 1517.44C356 1495.51 353.442 1474.63 336.887 1445.7C324.58 1424.3 308.703 1402.22 284.88 1374.96C230.313 1312.3 155.354 1271.91 112.366 1163.42C72.1845 1062.07 144.148 940.314 245.378 969.259C336.912 995.412 400.923 875.401 507.998 882.211Z"
                            stroke="url(#paint48_linear_30_81)" stroke-width="1.1" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M513.395 871.144C633.562 878.677 666.388 987.952 782.645 933.92C975.635 844.21 1115.81 961.213 1148.6 1086.51C1180.66 1208.87 1071.04 1376.42 920.348 1336.69C747.199 1291.01 726.017 1392.96 741.905 1427.83C732.122 1517.18 698.971 1733.31 560.928 1820.76C450.368 1890.82 336.182 1811.79 305.419 1719.14C273.344 1622.24 342.413 1568.8 359.953 1511.33C366.761 1489.29 363.932 1468.09 346.578 1438.9C333.774 1417.37 317.166 1395.24 292.868 1367.84C237.327 1305.19 162.012 1263.73 119.362 1154.06C79.981 1052.77 150.932 931.66 251.376 958.247C342.019 982.321 406.556 864.447 513.395 871.144Z"
                            stroke="url(#paint49_linear_30_81)" stroke-width="1.12" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M520.926 858.017C642.865 865.538 675.886 976.901 794.092 921.44C990.214 829.419 1133.08 947.706 1166.74 1074.72C1199.69 1198.83 1088.39 1369.02 935.371 1328.29C759.212 1281.4 738.08 1385.44 754.821 1421.11C748.906 1512.95 714.307 1729.41 574.68 1816.97C462.619 1887.28 347.391 1806.49 316.874 1712.74C284.913 1614.69 355.257 1561.05 372.672 1503.21C379.386 1480.94 376.358 1459.54 358.301 1430.09C345.015 1408.43 327.714 1386.19 302.896 1358.69C246.442 1296.14 170.701 1253.55 128.412 1142.69C89.8143 1041.48 159.761 921.001 259.356 945.3C349.276 967.146 414.336 851.444 520.926 858.017Z"
                            stroke="url(#paint50_linear_30_81)" stroke-width="1.15" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M527.887 844.729C651.592 852.249 684.793 965.693 804.949 908.803C1004.23 814.454 1149.73 934.079 1184.33 1062.68C1218.21 1188.55 1105.18 1361.4 949.882 1319.64C770.727 1271.55 749.627 1377.64 767.221 1414.12C765.173 1508.44 729.085 1725.3 587.894 1812.92C474.398 1883.36 358.082 1800.86 327.771 1706.07C296.073 1606.91 367.578 1553.04 384.866 1494.83C391.539 1472.37 388.285 1450.76 369.487 1421.01C355.704 1399.21 337.666 1376.85 312.391 1349.29C254.938 1286.74 178.837 1243.12 136.928 1131.06C99.1018 1029.93 168.058 910.093 266.821 932.078C355.939 951.847 421.521 838.262 527.887 844.729Z"
                            stroke="url(#paint51_linear_30_81)" stroke-width="1.17" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M534.256 831.315C659.741 838.809 693.14 954.363 815.246 896.045C1017.66 799.385 1165.83 920.298 1201.35 1050.58C1236.15 1178.19 1121.47 1353.61 963.801 1310.93C781.619 1261.59 760.608 1369.85 779.02 1407.07C780.71 1503.8 743.258 1721.11 600.56 1808.84C485.604 1879.46 368.233 1795.2 338.132 1699.4C306.645 1599.07 379.337 1545 396.503 1486.44C403.147 1463.8 399.663 1441.94 380.136 1411.91C365.824 1390.04 347.159 1367.54 321.338 1339.88C262.924 1277.34 186.423 1232.67 144.882 1119.41C107.827 1018.35 175.792 899.154 273.765 918.76C362.025 936.399 428.138 824.971 534.256 831.315Z"
                            stroke="url(#paint52_linear_30_81)" stroke-width="1.19" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M540.078 817.789C667.336 825.269 700.908 942.916 824.977 883.178C1030.54 784.203 1181.38 906.403 1217.81 1038.35C1253.52 1167.71 1137.14 1345.77 977.153 1302.1C791.97 1251.54 770.935 1361.86 790.261 1399.89C795.767 1499.08 756.941 1716.86 612.563 1804.58C496.14 1875.38 377.713 1789.35 347.847 1692.5C316.55 1591.03 390.463 1536.76 407.536 1477.77C414.15 1454.95 410.441 1432.88 390.121 1402.52C375.355 1380.44 355.97 1357.91 329.62 1330.16C270.267 1267.64 193.346 1221.92 152.193 1107.45C115.901 1006.48 182.875 887.925 280.046 905.198C367.556 920.849 434.188 811.571 540.078 817.789Z"
                            stroke="url(#paint53_linear_30_81)" stroke-width="1.21" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M545.376 804.2C674.395 811.659 708.174 931.403 834.248 870.29C1042.99 768.974 1196.47 892.501 1233.8 1026.1C1270.42 1157.21 1152.37 1337.91 990.041 1293.24C801.849 1241.46 780.855 1353.88 800.99 1392.67C810.325 1494.32 770.001 1712.45 624.133 1800.31C506.242 1871.3 386.747 1783.5 357.107 1685.61C326.022 1582.98 401.096 1528.49 418.053 1469.23C424.646 1446.15 420.686 1423.9 399.673 1393.32C384.406 1371.09 364.335 1348.46 337.489 1320.63C277.176 1258.14 199.886 1211.4 159.08 1095.68C123.515 994.734 189.558 876.839 285.896 891.799C372.526 905.213 439.705 798.068 545.376 804.2Z"
                            stroke="url(#paint54_linear_30_81)" stroke-width="1.24" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M550.084 790.509C680.884 797.942 714.879 919.824 842.812 857.225C1054.68 753.598 1210.86 878.374 1249.08 1013.67C1286.62 1146.53 1166.87 1329.84 1002.23 1284.2C811.038 1231.18 790.094 1345.74 811.104 1385.32C824.263 1489.42 782.48 1707.98 635.06 1795.9C515.73 1867.02 395.161 1777.51 365.726 1678.59C334.852 1574.8 411.156 1520.14 427.948 1460.49C434.491 1437.29 430.351 1414.72 408.58 1383.88C392.83 1361.52 372.093 1338.74 344.698 1310.88C283.432 1248.4 205.637 1200.57 165.309 1083.71C130.517 982.89 195.504 865.594 291.121 878.238C376.982 889.523 444.716 784.443 550.084 790.509Z"
                            stroke="url(#paint55_linear_30_81)" stroke-width="1.26" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M554.311 776.801C686.88 784.2 721.034 908.167 850.944 844.156C1065.97 738.235 1224.81 864.29 1263.92 1001.25C1302.39 1135.87 1181 1321.82 1013.98 1275.18C819.773 1220.95 805.957 1334.46 820.737 1377.96C855.411 1480.16 794.508 1703.48 645.529 1791.47C524.698 1862.83 403.098 1771.52 373.885 1671.54C343.215 1566.6 420.718 1511.75 437.388 1451.75C443.89 1428.36 439.504 1405.58 417.025 1374.45C400.775 1351.93 379.325 1329.1 351.448 1301.12C289.221 1238.66 210.971 1189.78 171.067 1071.7C137.017 970.979 201.056 854.37 295.818 864.593C380.867 873.76 449.146 770.902 554.311 776.801Z"
                            stroke="url(#paint56_linear_30_81)" stroke-width="1.28" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M558.033 763.06C692.375 770.445 726.702 896.506 858.574 831.074C1076.74 722.829 1238.25 850.172 1278.28 988.807C1317.65 1125.19 1194.56 1313.71 1025.23 1266.14C828.002 1210.69 813.931 1326.37 829.886 1370.56C867.592 1474.93 806.042 1698.95 655.492 1787.01C533.201 1858.55 410.559 1765.48 381.551 1664.46C351.092 1558.38 429.782 1503.32 446.339 1442.95C452.811 1419.38 448.195 1396.36 424.98 1364.96C408.221 1342.3 386.085 1319.37 357.7 1291.3C294.533 1228.86 215.821 1178.94 176.392 1059.7C143.092 959.051 206.169 843.064 300.107 850.96C384.329 858.033 453.083 757.279 558.033 763.06Z"
                            stroke="url(#paint57_linear_30_81)" stroke-width="1.3" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M561.245 749.321C697.368 756.68 731.893 884.85 865.707 818.003C1087.03 707.43 1251.2 836.073 1292.11 976.358C1332.4 1114.47 1207.59 1305.66 1035.95 1257.08C835.686 1200.37 821.284 1318.22 838.482 1363.17C879.222 1469.83 817.017 1694.45 664.915 1782.57C541.144 1854.29 417.46 1759.46 388.67 1657.42C358.422 1550.18 438.299 1494.91 454.747 1434.2C461.186 1410.43 456.339 1387.18 432.387 1355.5C415.119 1332.7 392.306 1309.66 363.404 1281.52C299.277 1219.1 220.102 1168.14 181.066 1047.66C148.482 947.085 210.614 831.751 303.746 837.294C387.226 842.31 456.544 743.663 561.245 749.321Z"
                            stroke="url(#paint58_linear_30_81)" stroke-width="1.33" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M563.997 735.616C701.875 742.933 736.616 873.187 872.362 804.937C1096.84 692.049 1263.67 821.992 1305.5 963.96C1346.71 1103.82 1220.22 1297.65 1046.26 1248.07C843.027 1190.16 828.317 1310.14 846.665 1355.83C890.457 1464.8 827.582 1689.95 673.926 1778.18C548.695 1850.07 423.956 1753.48 395.367 1650.39C365.297 1542.04 446.407 1486.52 462.681 1425.43C469.083 1401.48 464.006 1377.99 439.318 1346.05C421.557 1323.08 398.049 1299.96 368.628 1271.78C303.557 1209.36 223.876 1157.36 185.305 1035.67C153.45 935.179 214.626 820.488 306.95 823.678C389.704 826.557 459.519 730.065 563.997 735.616Z"
                            stroke="url(#paint59_linear_30_81)" stroke-width="1.35" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M566.278 721.938C705.892 729.271 740.818 861.626 878.544 791.93C1106.18 676.727 1275.69 807.945 1318.41 951.593C1360.54 1093.21 1232.36 1289.65 1056.06 1239.11C849.816 1179.96 834.803 1302.1 854.34 1348.54C901.214 1459.86 837.643 1685.54 682.414 1773.83C555.724 1845.9 429.948 1747.57 401.572 1643.44C371.726 1533.88 454.007 1478.24 470.215 1416.8C476.549 1392.65 471.276 1368.92 445.851 1336.71C427.594 1313.6 403.407 1290.38 373.461 1262.08C307.425 1199.66 227.253 1146.64 189.173 1023.75C158.046 923.344 218.265 809.296 309.796 810.141C391.687 810.868 462.036 716.501 566.278 721.938Z"
                            stroke="url(#paint60_linear_30_81)" stroke-width="1.37" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M568.088 708.341C709.524 715.584 744.616 850.101 884.283 778.989C1115.01 661.471 1287.24 793.968 1330.86 939.282C1373.89 1082.64 1244.04 1281.73 1065.41 1230.18C856.171 1169.82 840.843 1294.1 861.631 1341.29C911.564 1455.05 847.308 1681.15 690.521 1769.52C562.35 1841.77 435.462 1741.62 407.369 1636.5C377.747 1525.8 461.215 1469.95 477.288 1408.15C483.619 1383.83 478.099 1359.84 451.928 1327.36C433.171 1304.1 408.306 1280.77 377.9 1252.45C310.92 1190.03 230.243 1135.98 192.615 1011.87C162.132 911.51 221.471 798.156 312.181 796.64C393.231 795.21 464.09 703.005 568.088 708.341Z"
                            stroke="url(#paint61_linear_30_81)" stroke-width="1.39" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M569.469 694.816C712.683 702.011 747.969 838.673 889.573 766.124C1123.49 646.328 1298.35 780.075 1342.88 927.064C1386.84 1072.17 1255.29 1273.89 1074.33 1221.36C862.081 1159.77 846.525 1286.28 868.398 1334.11C921.533 1450.27 856.456 1676.82 698.122 1765.28C568.479 1837.7 440.592 1735.84 412.666 1629.66C383.242 1517.79 467.91 1461.75 483.87 1399.58C490.158 1375.07 484.425 1350.82 457.514 1318.11C438.244 1294.68 412.697 1271.28 381.752 1242.83C313.824 1180.44 232.634 1125.37 195.48 1000.03C165.764 899.787 224.185 787.102 314.011 783.183C394.302 779.722 465.752 689.641 569.469 694.816Z"
                            stroke="url(#paint62_linear_30_81)" stroke-width="1.42" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M570.432 681.405C715.418 688.532 750.878 827.342 894.444 753.372C1131.53 631.202 1309.03 766.287 1354.46 914.956C1399.32 1061.86 1266.11 1266.15 1082.85 1212.6C867.593 1149.77 851.786 1278.48 874.799 1327C931.048 1445.6 865.244 1672.58 705.342 1761.12C574.24 1833.72 445.311 1730.1 417.585 1622.87C388.386 1509.85 474.232 1453.62 490.079 1391.09C496.338 1366.4 490.353 1341.91 462.727 1308.92C442.939 1285.36 416.731 1261.83 385.275 1233.33C316.39 1170.93 234.665 1114.85 197.998 988.291C168.998 888.118 226.402 776.056 315.506 769.821C394.958 764.314 466.964 676.353 570.432 681.405Z"
                            stroke="url(#paint63_linear_30_81)" stroke-width="1.44" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M570.974 668.144C717.728 675.236 753.387 816.155 898.903 740.757C1139.14 616.271 1319.31 752.645 1365.64 902.98C1411.43 1051.59 1276.52 1258.54 1090.89 1204.07C872.639 1140.02 856.593 1270.87 880.735 1320.13C940.161 1441.16 873.618 1668.61 712.093 1757.19C579.518 1829.96 449.538 1724.6 422.039 1616.33C393.082 1502.19 480.088 1445.74 495.809 1382.83C502.026 1357.95 495.82 1333.21 467.448 1299.97C447.185 1276.21 420.273 1252.62 388.306 1224.06C318.463 1161.65 236.205 1104.55 200.071 976.789C171.753 876.689 228.239 765.295 316.544 756.694C395.21 749.03 467.681 663.13 570.974 668.144Z"
                            stroke="url(#paint64_linear_30_81)" stroke-width="1.46" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M571.122 655.013C719.639 662.05 755.493 805.113 902.958 728.286C1146.37 601.459 1329.18 739.137 1376.42 891.147C1423.13 1041.51 1286.53 1251.1 1098.53 1195.69C877.278 1130.4 860.974 1263.5 886.26 1313.42C948.803 1437.06 881.46 1664.71 718.44 1753.41C584.393 1826.34 453.376 1719.25 426.082 1609.94C397.292 1494.6 485.529 1437.99 501.136 1374.72C507.324 1349.66 500.875 1324.67 471.78 1291.16C451.016 1267.26 423.418 1243.57 390.934 1214.94C320.14 1152.53 237.342 1094.4 201.72 965.436C174.097 865.416 229.673 754.679 317.158 743.716C395.05 733.902 468.031 650.166 571.122 655.013Z"
                            stroke="url(#paint65_linear_30_81)" stroke-width="1.48" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M570.865 642.06C721.16 649.05 757.204 794.235 906.619 715.98C1153.18 586.871 1338.74 725.853 1386.79 879.492C1434.43 1031.61 1296.14 1243.81 1105.86 1187.38C881.566 1120.84 865.063 1256.16 891.455 1306.77C957.236 1432.91 888.985 1660.87 724.483 1749.7C588.955 1822.82 456.884 1713.96 429.803 1603.6C401.238 1487.12 490.653 1430.31 506.119 1366.75C512.232 1341.55 505.628 1316.26 475.762 1282.48C454.484 1258.41 426.264 1234.67 393.213 1205.93C321.474 1143.53 238.1 1084.38 203.02 954.205C176.112 854.205 230.791 744.186 317.456 730.808C394.521 718.955 468.044 637.276 570.865 642.06Z"
                            stroke="url(#paint66_linear_30_81)" stroke-width="1.51" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M570.25 629.297C722.283 636.215 758.56 783.569 909.925 703.885C1159.62 572.453 1347.78 712.693 1396.8 868.041C1445.37 1021.9 1305.39 1236.73 1112.76 1179.32C885.522 1111.54 868.772 1249.07 896.274 1300.33C965.274 1429.04 896.243 1657.34 730.109 1746.22C593.13 1819.5 460.008 1708.89 433.133 1597.51C404.77 1479.88 495.381 1422.85 510.766 1358.88C516.883 1333.44 509.985 1307.93 479.404 1273.93C457.634 1249.69 428.676 1225.82 395.174 1197.05C322.478 1134.64 238.535 1074.48 203.997 943.079C177.729 843.215 231.494 733.831 317.369 718.129C393.612 704.204 467.665 624.628 570.25 629.297Z"
                            stroke="url(#paint67_linear_30_81)" stroke-width="1.53" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M569.274 616.758C723.143 623.648 759.539 773.096 912.858 692.005C1165.69 558.228 1356.53 699.765 1406.46 856.8C1455.93 1012.4 1314.27 1229.87 1119.32 1171.46C889.054 1102.48 872.109 1242.2 900.701 1294.17C972.924 1425.47 903.043 1654.05 735.35 1743C596.898 1816.45 462.726 1704.1 436.077 1591.68C407.926 1472.89 499.728 1415.68 515.025 1351.36C521.113 1325.74 513.981 1299.97 482.676 1265.71C460.385 1241.32 430.761 1217.35 396.734 1188.52C323.094 1126.1 238.575 1064.93 204.579 932.316C179.001 832.511 231.852 723.817 316.915 705.74C392.351 689.663 466.913 612.194 569.274 616.758Z"
                            stroke="url(#paint68_linear_30_81)" stroke-width="1.55" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M567.942 604.464C723.558 611.269 760.186 762.886 915.442 680.358C1171.45 544.283 1364.94 687.09 1415.76 845.792C1466.21 1003.18 1322.81 1223.24 1125.53 1163.86C892.259 1093.65 875.102 1235.58 904.787 1288.23C980.267 1422.12 909.545 1650.96 740.271 1740C600.339 1813.63 465.133 1699.51 438.689 1586.05C410.742 1466.12 503.73 1408.7 518.888 1344C524.934 1318.19 517.542 1292.2 485.531 1257.65C462.738 1233.1 432.449 1209.04 397.907 1180.13C323.301 1117.72 238.179 1055.53 204.759 921.699C179.842 821.97 231.822 713.956 316.009 693.464C390.733 675.368 465.825 600.002 567.942 604.464Z"
                            stroke="url(#paint69_linear_30_81)" stroke-width="1.57" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M566.288 592.418C723.675 599.155 760.515 752.889 917.695 668.972C1176.86 530.581 1373.02 674.676 1424.68 835.024C1476 994.116 1330.97 1216.85 1131.35 1156.48C895.029 1085.02 877.745 1229.13 908.499 1282.51C987.232 1419.02 915.605 1648.08 744.798 1737.21C603.393 1811.01 467.149 1695.15 440.937 1580.68C413.209 1459.58 507.388 1401.98 522.42 1336.91C528.437 1310.92 520.827 1284.64 488.067 1249.87C464.761 1225.16 433.786 1201 398.74 1172.02C323.19 1109.6 237.471 1046.42 204.614 911.362C180.379 811.705 231.479 704.385 314.943 681.521C388.759 661.372 464.414 588.058 566.288 592.418Z"
                            stroke="url(#paint70_linear_30_81)" stroke-width="1.6" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M564.301 580.691C723.46 587.358 760.457 743.268 919.604 657.896C1181.94 517.185 1380.75 662.572 1433.42 824.652C1485.65 985.495 1338.92 1210.84 1136.97 1149.5C897.761 1076.87 880.158 1223.16 912 1277.2C994.008 1416.33 921.496 1645.61 749.126 1734.85C606.258 1808.81 468.963 1691.2 442.976 1575.64C415.451 1453.4 510.818 1395.59 525.728 1330.16C531.703 1303.98 523.866 1277.43 490.379 1242.43C466.567 1217.54 434.918 1193.3 399.348 1164.26C322.824 1101.8 236.485 1037.62 204.238 901.356C180.664 801.775 230.872 695.139 313.482 669.911C386.52 647.591 462.668 576.41 564.301 580.691Z"
                            stroke="url(#paint71_linear_30_81)" stroke-width="1.62" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M562.029 569.214C722.968 575.8 760.152 733.866 921.253 647.086C1186.74 504.039 1388.27 650.747 1441.74 814.449C1494.89 977.043 1346.49 1205.01 1142.2 1142.69C899.923 1068.79 882.206 1217.34 915.116 1272.04C1000.4 1413.84 926.985 1643.31 753.069 1732.64C608.728 1806.76 470.383 1687.42 444.585 1570.84C417.271 1447.44 513.792 1389.48 528.626 1323.66C534.559 1297.29 526.488 1270.48 492.328 1235.25C468.004 1210.2 435.689 1185.86 399.608 1156.77C322.14 1094.31 235.177 1029.11 203.519 891.634C180.587 792.101 229.929 686.167 311.718 658.577C383.959 634.146 460.623 565.061 562.029 569.214Z"
                            stroke="url(#paint72_linear_30_81)" stroke-width="1.64" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M559.426 558.109C722.146 564.614 759.529 724.846 922.567 636.629C1191.22 491.275 1395.35 639.241 1449.77 804.63C1503.85 968.976 1353.76 1199.57 1147.13 1136.28C901.856 1061.15 883.962 1211.91 917.906 1267.27C1006.51 1411.71 932.17 1641.4 756.686 1730.82C610.873 1805.11 471.71 1683.97 445.892 1566.4C419.023 1441.8 516.535 1383.65 531.218 1317.5C537.122 1290.94 528.816 1263.88 493.865 1228.38C469.035 1203.14 436.055 1178.71 399.462 1149.56C321.037 1087.09 233.434 1020.9 202.382 882.194C180.128 782.767 228.598 677.514 309.584 647.537C381.075 621.062 458.257 554.071 559.426 558.109Z"
                            stroke="url(#paint73_linear_30_81)" stroke-width="1.66" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M556.648 547.367C721.126 553.795 758.688 716.196 923.697 626.546C1195.49 478.868 1402.29 628.122 1457.61 795.191C1512.59 961.3 1360.78 1194.48 1151.87 1130.23C903.602 1053.86 885.53 1206.84 920.564 1262.91C1012.47 1410.01 937.196 1639.88 760.154 1729.38C612.88 1803.85 472.699 1681 447.068 1562.37C420.389 1436.61 519.1 1378.26 533.67 1311.75C539.532 1285.01 530.992 1257.68 495.329 1221.93C469.986 1196.53 436.281 1171.98 399.224 1142.79C319.841 1080.31 231.593 1013.1 201.164 873.195C179.495 773.849 227.144 669.254 307.293 636.886C377.938 608.289 455.624 543.386 556.648 547.367Z"
                            stroke="url(#paint74_linear_30_81)" stroke-width="1.69" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M553.446 536.917C719.705 543.263 757.475 707.816 924.469 616.796C1199.42 466.824 1408.9 617.34 1465.12 786.075C1521.03 953.923 1367.57 1189.77 1156.28 1124.51C905.003 1046.91 886.773 1202.1 922.854 1258.84C1018.02 1408.58 941.843 1638.7 763.211 1728.24C614.46 1802.86 473.164 1678.22 447.812 1558.59C421.335 1431.69 521.246 1373.14 535.703 1306.26C541.536 1279.34 532.756 1251.73 496.353 1215.74C470.521 1190.14 436.177 1165.56 398.557 1136.29C318.226 1073.78 229.338 1005.58 199.462 864.412C178.482 765.07 225.236 661.249 304.561 626.499C374.541 595.894 452.74 533.065 553.446 536.917Z"
                            stroke="url(#paint75_linear_30_81)" stroke-width="1.71" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M550.01 526.918C718.037 533.175 756.024 699.921 924.876 607.416C1203 455.102 1415.44 606.82 1472.24 777.347C1529.39 946.82 1373.92 1185.41 1160.3 1119.17C906.006 1040.39 887.591 1197.8 924.763 1255.17C1023.33 1407.63 946.159 1637.84 766.008 1727.53C615.785 1802.32 473.442 1675.96 448.312 1555.27C422.026 1427.22 523.17 1368.42 537.513 1301.18C543.304 1274.06 534.289 1246.2 497.192 1209.93C470.83 1184.19 435.838 1159.49 397.693 1130.15C316.413 1067.62 226.825 998.412 197.67 856.082C177.224 756.845 223.164 653.814 301.668 616.647C370.825 583.915 449.527 523.172 550.01 526.918Z"
                            stroke="url(#paint76_linear_30_81)" stroke-width="1.73" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M546.42 517.274C716.223 523.428 754.547 692.665 925.203 598.419C1206.78 444.333 1421.57 596.8 1479.29 769.001C1537.28 940.198 1380.21 1181.44 1164.25 1114.21C906.973 1034.14 888.422 1193.77 926.587 1251.86C1028.5 1407.02 950.378 1637.39 768.658 1727.17C616.964 1802.13 473.562 1674.04 448.658 1552.31C422.576 1423.11 524.881 1364.15 539.111 1296.54C544.848 1269.28 535.615 1241.13 497.769 1204.64C470.893 1178.74 435.228 1153.95 396.58 1124.55C314.343 1062 224.055 991.784 195.566 848.242C175.872 748.994 220.953 646.597 298.602 607.066C366.962 572.278 446.181 513.63 546.42 517.274Z"
                            stroke="url(#paint77_linear_30_81)" stroke-width="1.75" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M542.588 508.096C714.157 514.16 752.668 685.553 925.279 589.9C1210 433.435 1427.44 587.249 1486.08 761.121C1545.01 934.098 1386.24 1177.94 1167.95 1109.73C907.678 1028.43 888.962 1190.27 928.165 1249.05C1033.43 1406.91 954.324 1637.42 771.063 1727.31C617.891 1802.41 473.453 1672.59 448.754 1549.82C422.883 1419.47 526.375 1360.3 540.483 1292.34C546.203 1264.85 536.706 1236.47 498.108 1199.78C470.714 1173.69 434.383 1148.81 395.224 1119.35C312.038 1056.78 221.054 985.575 193.231 840.821C174.222 741.611 218.466 639.897 295.274 598.009C362.853 561.14 442.572 504.558 542.588 508.096Z"
                            stroke="url(#paint78_linear_30_81)" stroke-width="1.78" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M538.535 499.375C711.872 505.35 750.565 678.879 925.147 581.848C1212.98 423.022 1433.09 578.178 1492.66 753.699C1552.54 928.388 1392.07 1174.89 1171.37 1105.68C908.111 1023.14 889.251 1187.2 929.479 1246.65C1038.11 1407.23 958.045 1637.83 773.192 1727.85C618.569 1803.12 473.05 1671.57 448.586 1547.75C422.905 1416.24 527.61 1356.89 541.618 1288.57C547.296 1260.89 537.585 1232.24 498.28 1195.27C470.372 1169.02 433.354 1144.04 393.684 1114.53C309.536 1051.93 217.852 979.709 190.707 833.751C172.253 734.64 215.69 633.648 291.677 589.344C358.549 550.422 438.756 495.952 538.535 499.375Z"
                            stroke="url(#paint79_linear_30_81)" stroke-width="1.8" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M534.332 491.176C709.453 497.036 748.324 672.734 924.86 574.295C1215.83 413.112 1438.6 569.604 1499.09 746.799C1559.83 923.296 1397.74 1172.37 1174.76 1102.21C908.496 1018.43 889.5 1184.71 930.797 1244.87C1042.79 1408.14 961.716 1638.92 775.333 1728.99C619.224 1804.43 472.668 1671.14 448.418 1546.28C422.964 1413.59 528.831 1354.06 542.725 1285.39C548.366 1257.54 538.403 1228.6 498.383 1191.41C469.958 1164.97 432.288 1139.9 392.106 1110.34C306.988 1047.72 214.609 974.507 188.15 827.332C170.338 728.213 212.944 627.981 288.085 581.299C354.034 540.205 434.745 487.822 534.332 491.176Z"
                            stroke="url(#paint80_linear_30_81)" stroke-width="1.82" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M529.855 483.406C706.784 489.111 745.796 667.009 924.292 567.184C1218.35 403.678 1443.82 561.461 1505.25 740.326C1566.87 918.571 1403.11 1170.27 1177.78 1099.12C908.544 1014.12 889.404 1182.61 931.687 1243.42C1047.07 1409.42 965.001 1640.33 776.977 1730.46C619.405 1806.05 471.79 1671.03 447.83 1545.17C422.542 1411.37 529.527 1351.58 543.423 1282.56C548.974 1254.51 538.824 1225.32 498.085 1187.89C469.146 1161.28 430.81 1136.12 390.104 1106.49C304.038 1043.85 210.924 969.625 185.191 821.257C167.942 722.226 209.766 622.677 284.077 573.591C349.349 530.482 430.551 480.178 529.855 483.406Z"
                            stroke="url(#paint81_linear_30_81)" stroke-width="1.84" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M525.246 476.241C703.913 481.874 743.175 661.915 923.599 560.666C1220.83 394.729 1448.93 553.919 1511.27 734.459C1573.78 914.473 1408.51 1168.35 1180.74 1096.66C908.679 1009.95 889.217 1181.11 932.497 1242.61C1051.3 1411.29 968.027 1642.03 778.686 1732.63C619.5 1808.1 470.963 1671.62 447.166 1544.68C422.068 1409.72 530.265 1349.74 544.047 1280.36C549.557 1252.12 539.159 1222.66 497.67 1184.95C468.205 1158.17 429.217 1132.92 387.974 1103.22C300.95 1040.57 207.094 965.341 182.06 815.765C165.487 716.728 206.536 617.994 279.924 566.481C344.48 521.332 426.174 473.106 525.246 476.241Z"
                            stroke="url(#paint82_linear_30_81)" stroke-width="1.87" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M520.489 469.563C700.932 475.093 740.304 657.297 922.751 554.647C1223.11 386.344 1453.88 546.878 1517.16 729.088C1580.57 910.845 1413.58 1167.41 1183.53 1094.68C908.405 1006.79 888.827 1180.09 933.184 1242.3C1055.35 1413.77 971.119 1644.62 780.162 1735.25C619.555 1810.92 469.908 1672.67 446.345 1544.68C421.45 1408.58 530.868 1348.4 544.503 1278.65C549.945 1250.2 539.338 1220.5 497.169 1182.57C467.198 1155.62 427.524 1130.27 385.851 1100.54C297.873 1037.84 203.267 961.621 178.953 810.832C162.975 711.776 203.157 613.743 275.731 559.945C339.463 512.669 421.648 466.521 520.489 469.563Z"
                            stroke="url(#paint83_linear_30_81)" stroke-width="1.89" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M515.564 463.433C697.761 468.865 737.345 653.186 921.734 549.176C1225.23 378.508 1458.65 540.388 1522.84 724.239C1587.14 907.765 1418.55 1166.98 1186.11 1093.23C907.954 1004.19 888.33 1179.65 933.652 1242.51C1059.21 1416.7 973.991 1647.73 781.503 1738.36C619.424 1814.25 468.726 1674.21 445.377 1545.17C420.685 1407.93 531.315 1347.56 544.824 1277.43C550.34 1248.87 539.382 1218.84 496.494 1180.67C465.997 1153.54 425.654 1128.13 383.417 1098.31C294.491 1035.58 199.116 958.351 175.541 806.377C160.173 707.42 199.699 610.242 271.364 553.881C334.349 504.726 416.959 460.505 515.564 463.433Z"
                            stroke="url(#paint84_linear_30_81)" stroke-width="1.91" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M510.539 457.913C694.529 463.217 734.275 649.734 920.618 544.317C1227.25 371.291 1463.34 534.493 1528.45 720.04C1593.65 905.3 1423.34 1167.26 1188.69 1092.39C907.524 1002.19 887.792 1179.78 934.109 1243.33C1063.01 1420.28 976.873 1651.44 782.776 1742.17C619.204 1818.24 467.383 1676.48 444.269 1546.39C419.775 1407.99 531.605 1347.41 545.001 1276.93C550.423 1248.14 539.291 1217.87 495.662 1179.46C464.651 1152.17 423.659 1126.64 380.895 1096.79C291.007 1034.03 194.868 955.802 172.035 802.611C157.292 703.671 195.978 607.211 266.827 548.454C329.081 497.141 412.158 455.092 510.539 457.913Z"
                            stroke="url(#paint85_linear_30_81)" stroke-width="1.93" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M505.408 453.016C691.144 457.98 731.093 646.883 919.371 540.118C1229.14 364.713 1467.88 529.276 1533.92 716.48C1599.97 903.684 1428.03 1168.15 1190.99 1092.26C906.788 1000.92 886.97 1180.63 934.325 1244.82C1066.71 1424.49 979.493 1655.83 783.793 1746.59C618.831 1822.9 465.962 1679.3 443.027 1548.16C418.724 1408.61 531.775 1347.83 545.036 1276.99C550.429 1248.02 539.036 1217.47 494.701 1178.83C463.121 1151.38 421.565 1125.65 378.239 1095.82C287.468 1032.88 190.474 953.795 168.4 799.416C154.254 700.511 192.136 604.739 262.132 543.672C323.672 490.229 407.273 450.152 505.408 453.016Z"
                            stroke="url(#paint86_linear_30_81)" stroke-width="1.96" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M500.177 448.763C687.718 453.662 727.806 644.655 918.08 536.474C1230.97 358.694 1472.39 524.608 1539.34 713.466C1606.3 902.323 1432.67 1169.58 1193.38 1092.67C906.175 1000.14 886.242 1182.06 934.571 1246.94C1070.39 1429.36 982.144 1660.85 784.935 1751.67C618.505 1828.17 464.556 1682.78 441.861 1550.68C417.761 1409.97 532.024 1349.01 545.159 1277.79C550.523 1248.64 538.924 1217.76 493.829 1178.95C461.835 1151.23 419.475 1125.53 375.663 1095.61C283.915 1032.72 186.139 952.553 164.845 796.98C158.324 749.79 163.35 701.722 179.491 656.902C195.632 612.081 222.406 571.847 257.517 539.65C318.171 483.949 402.265 446.061 500.177 448.763Z"
                            stroke="url(#paint87_linear_30_81)" stroke-width="1.98" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                        <path
                            d="M160.978 795.001C136.675 609.399 285.826 439.725 494.862 445.149C684.16 450.06 724.446 643.108 916.674 533.518C1232.69 353.386 1476.77 520.622 1544.66 711.15C1612.55 901.677 1437.2 1171.71 1195.53 1093.72C905.28 1000.06 885.294 1184.06 934.611 1249.64C1073.9 1434.86 984.587 1666.45 785.75 1757.28C617.886 1833.97 462.861 1686.83 440.412 1553.62C406.711 1353.63 650.102 1314.02 492.652 1179.5C391.874 1093.42 191.282 1026.62 160.978 795.001Z"
                            stroke="url(#paint88_linear_30_81)" stroke-width="2" stroke-miterlimit="10"
                            stroke-dasharray="1 9.99"/>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_30_81" x1="-523.09" y1="1517.65" x2="436.752" y2="1477.06"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_30_81" x1="-529.655" y1="1517.31" x2="442.244" y2="1473.03"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_30_81" x1="-535.928" y1="1517.43" x2="448.42" y2="1469.93"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_30_81" x1="-541.71" y1="1517.77" x2="455.256" y2="1467.31"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_30_81" x1="-547.106" y1="1518.42" x2="462.642" y2="1465.19"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_30_81" x1="-551.914" y1="1519.18" x2="470.681" y2="1463.44"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_30_81" x1="-556.335" y1="1520.06" x2="479.272" y2="1461.85"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_30_81" x1="-560.319" y1="1521.11" x2="488.351" y2="1460.65"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint8_linear_30_81" x1="-563.807" y1="1522.21" x2="498.012" y2="1459.52"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint9_linear_30_81" x1="-566.856" y1="1523.41" x2="508.177" y2="1458.68"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint10_linear_30_81" x1="-569.543" y1="1524.65" x2="518.815" y2="1457.87"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint11_linear_30_81" x1="-572.011" y1="1525.77" x2="529.934" y2="1456.85"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint12_linear_30_81" x1="-573.61" y1="1527.21" x2="541.515" y2="1456.61"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint13_linear_30_81" x1="-575.119" y1="1528.44" x2="553.509" y2="1455.96"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint14_linear_30_81" x1="-576.08" y1="1529.68" x2="565.95" y2="1455.43"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint15_linear_30_81" x1="-576.886" y1="1530.99" x2="578.754" y2="1455.03"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint16_linear_30_81" x1="-577.277" y1="1532.19" x2="591.956" y2="1454.53"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint17_linear_30_81" x1="-577.227" y1="1533.33" x2="605.534" y2="1454.03"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint18_linear_30_81" x1="-577.103" y1="1534.45" x2="619.442" y2="1453.52"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint19_linear_30_81" x1="-576.589" y1="1535.52" x2="633.696" y2="1453.03"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint20_linear_30_81" x1="-575.697" y1="1536.45" x2="648.251" y2="1452.41"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint21_linear_30_81" x1="-574.663" y1="1537.29" x2="663.064" y2="1451.74"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint22_linear_30_81" x1="-573.342" y1="1538.06" x2="678.304" y2="1450.96"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint23_linear_30_81" x1="-571.801" y1="1538.69" x2="693.615" y2="1450.14"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint24_linear_30_81" x1="-570.107" y1="1539.17" x2="709.246" y2="1449.14"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint25_linear_30_81" x1="-568.077" y1="1539.54" x2="725.093" y2="1448.07"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint26_linear_30_81" x1="-565.989" y1="1539.84" x2="741.16" y2="1446.98"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint27_linear_30_81" x1="-563.639" y1="1539.94" x2="757.38" y2="1445.74"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint28_linear_30_81" x1="-561.212" y1="1539.83" x2="773.79" y2="1444.23"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint29_linear_30_81" x1="-558.628" y1="1539.65" x2="790.382" y2="1442.73"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint30_linear_30_81" x1="-555.934" y1="1539.19" x2="807.086" y2="1440.91"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint31_linear_30_81" x1="-553.121" y1="1538.58" x2="823.961" y2="1438.94"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint32_linear_30_81" x1="-550.281" y1="1537.77" x2="840.849" y2="1436.87"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint33_linear_30_81" x1="-547.312" y1="1536.74" x2="857.845" y2="1434.53"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint34_linear_30_81" x1="-544.297" y1="1535.52" x2="874.955" y2="1432.02"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint35_linear_30_81" x1="-541.412" y1="1533.95" x2="892.068" y2="1429.07"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint36_linear_30_81" x1="-538.299" y1="1532.29" x2="909.221" y2="1426.2"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint37_linear_30_81" x1="-535.267" y1="1530.33" x2="926.393" y2="1423"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint38_linear_30_81" x1="-532.327" y1="1528.07" x2="943.542" y2="1419.44"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint39_linear_30_81" x1="-529.271" y1="1525.64" x2="960.681" y2="1415.85"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint40_linear_30_81" x1="-526.36" y1="1522.87" x2="977.79" y2="1411.84"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint41_linear_30_81" x1="-523.513" y1="1519.75" x2="994.839" y2="1407.42"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint42_linear_30_81" x1="-520.665" y1="1516.47" x2="1011.86" y2="1402.97"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint43_linear_30_81" x1="-518.043" y1="1512.97" x2="1028.72" y2="1398.41"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint44_linear_30_81" x1="-515.653" y1="1508.86" x2="1045.5" y2="1392.89"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint45_linear_30_81" x1="-513.274" y1="1504.59" x2="1062.14" y2="1387.4"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint46_linear_30_81" x1="-511.128" y1="1499.91" x2="1078.65" y2="1381.43"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint47_linear_30_81" x1="-509.003" y1="1495.03" x2="1095.01" y2="1375.37"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint48_linear_30_81" x1="-507.174" y1="1489.71" x2="1111.16" y2="1368.83"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint49_linear_30_81" x1="-505.206" y1="1483.85" x2="1127.43" y2="1361.8"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint50_linear_30_81" x1="-501.261" y1="1476.06" x2="1145.77" y2="1352.86"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint51_linear_30_81" x1="-497.807" y1="1467.92" x2="1163.53" y2="1343.47"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint52_linear_30_81" x1="-494.929" y1="1459.78" x2="1180.75" y2="1334.15"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint53_linear_30_81" x1="-492.803" y1="1451.41" x2="1197.39" y2="1324.46"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint54_linear_30_81" x1="-491.024" y1="1443.11" x2="1213.57" y2="1314.95"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint55_linear_30_81" x1="-489.883" y1="1434.64" x2="1229.06" y2="1305.3"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint56_linear_30_81" x1="-489.27" y1="1426.18" x2="1244.12" y2="1295.64"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint57_linear_30_81" x1="-489.075" y1="1417.71" x2="1258.66" y2="1286"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint58_linear_30_81" x1="-489.59" y1="1409.18" x2="1272.68" y2="1276.22"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint59_linear_30_81" x1="-490.528" y1="1400.67" x2="1286.28" y2="1266.43"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint60_linear_30_81" x1="-491.857" y1="1392.31" x2="1299.4" y2="1256.93"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint61_linear_30_81" x1="-493.633" y1="1383.94" x2="1312.05" y2="1247.34"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint62_linear_30_81" x1="-495.939" y1="1375.64" x2="1324.27" y2="1237.8"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint63_linear_30_81" x1="-498.683" y1="1367.38" x2="1336.07" y2="1228.24"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint64_linear_30_81" x1="-501.869" y1="1359.4" x2="1347.45" y2="1219.07"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint65_linear_30_81" x1="-505.48" y1="1351.56" x2="1358.44" y2="1210.03"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint66_linear_30_81" x1="-509.398" y1="1343.78" x2="1369.03" y2="1200.97"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint67_linear_30_81" x1="-513.757" y1="1336.2" x2="1379.25" y2="1192.1"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint68_linear_30_81" x1="-518.464" y1="1328.93" x2="1389.11" y2="1183.65"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint69_linear_30_81" x1="-523.635" y1="1321.79" x2="1398.64" y2="1175.16"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint70_linear_30_81" x1="-529.06" y1="1314.97" x2="1407.76" y2="1167.14"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint71_linear_30_81" x1="-534.824" y1="1308.46" x2="1416.69" y2="1159.3"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint72_linear_30_81" x1="-540.89" y1="1302.18" x2="1425.23" y2="1151.79"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint73_linear_30_81" x1="-547.362" y1="1296.16" x2="1433.47" y2="1144.37"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint74_linear_30_81" x1="-554.009" y1="1290.61" x2="1441.51" y2="1137.53"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint75_linear_30_81" x1="-561.171" y1="1285.26" x2="1449.23" y2="1130.7"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint76_linear_30_81" x1="-568.476" y1="1280.48" x2="1456.69" y2="1124.71"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint77_linear_30_81" x1="-575.953" y1="1275.98" x2="1463.88" y2="1118.84"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint78_linear_30_81" x1="-583.732" y1="1271.96" x2="1470.9" y2="1113.45"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint79_linear_30_81" x1="-591.829" y1="1268.32" x2="1477.67" y2="1108.37"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint80_linear_30_81" x1="-599.896" y1="1265.3" x2="1484.32" y2="1104.06"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint81_linear_30_81" x1="-608.455" y1="1262.61" x2="1490.65" y2="1099.92"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint82_linear_30_81" x1="-617.088" y1="1260.45" x2="1496.86" y2="1096.3"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint83_linear_30_81" x1="-625.899" y1="1258.87" x2="1502.94" y2="1093.3"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint84_linear_30_81" x1="-634.825" y1="1257.81" x2="1508.84" y2="1090.8"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint85_linear_30_81" x1="-644.063" y1="1257.35" x2="1514.64" y2="1088.83"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint86_linear_30_81" x1="-653.493" y1="1257.44" x2="1520.32" y2="1087.3"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint87_linear_30_81" x1="-662.787" y1="1258.26" x2="1525.94" y2="1086.71"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <linearGradient id="paint88_linear_30_81" x1="-672.536" y1="1259.5" x2="1531.42" y2="1086.22"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00CBFF"/>
                            <stop offset="1" stop-color="#00FFA1"/>
                        </linearGradient>
                        <clipPath id="clip0_30_81">
                            <rect width="1775.01" height="1304.65" fill="white"
                                  transform="translate(-372.668 1495.8) rotate(-57.4263)"/>
                        </clipPath>
                    </defs>
                </svg>
            </svg>

        </div>
    );
};

export default ContactImg;
import "../App.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 'swiper/css';
import {Navigation } from "swiper";

const OurPartners = () => {
    const partners = [
      { id: 3, img: require('../img/partner_pics/tips_am.png'), name: 'Tips.am', link: 'https://tips.am' },
      { id: 9, img: require('../img/partner_pics/fnet.png'), name: 'Fnet Telecom', link: 'https://www.fnet.am/' },
      { id: 4, img: require('../img/partner_pics/dubai_watch_store.png'), name: 'Dubai Watch Store', link: 'https://dubaiwatchstores.com/' },
      { id: 2, img: require('../img/partner_pics/4thbf.png'), name: '4THE Bright Future', link: 'https://www.4tbf.company/' },
      { id: 7, img: require('../img/partner_pics/repeat.png'), name: 'Repeat', link: 'https://getrepeat.io/' },
      { id: 1, img: require('../img/partner_pics/basis.png'), name: 'Basis LLC', link: 'https://basis.am/' },
    ]
    return (
        <div className="swiper partnersSwiper">
            <p className='ourPartners'>our partners</p>
            <Swiper
                breakpoints={{
                    1450: {
                        slidesPerView: 4.35
                    },
                    1380: {
                        slidesPerView: 4
                    },
                    1230: {
                        slidesPerView: 3.5
                    },
                    1120: {
                        slidesPerView: 3
                    },
                    1000: {
                        slidesPerView: 2.5
                    },
                    850: {
                        slidesPerView: 2
                    },
                    710: {
                        slidesPerView: 1.5
                    },
                    635: {
                        slidesPerView: 1
                    }

                }}
                navigation={true}
                grabCursor={true}
                spaceBetween={1}
                updateOnWindowResize={true} modules={[Navigation]}>
                {
                    partners.map(({ id, name, img, link }) => {
                        return <SwiperSlide key={id}>
                            <div className="swiperBox" style={{
                                height: '500px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '48px',
                            }}>
                                <div className="swipperDiv" style={{
                                    width: "275px",
                                    height: "275px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: '30px',
                                    border: '2px dotted #00ffa1',
                                    marginBottom: '25px',
                                }}>
                                  <a href={link} target='_blank' style={{
                                    width: "100%",
                                    alignItems: "center",
                                    height: "100%",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}>
                                    <img style={{width:'90%',height:'auto',objectFit:'contain',borderRadius:'19px',overflow:'hidden'}} src={img} alt="image" />
                                  </a>
                                </div>
                                <p>
                                    {name}
                                </p>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );
};
export default OurPartners;

import "../App.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 'swiper/css';
import {Navigation } from "swiper";

const Swipper = () => {
    const team = [
        { id: 1, img: require('../img/profile_pics/milen.png'), name: 'Milena Gevorgyan', profession: 'CEO|CO-FOUNDER' },
        { id: 2, img: require('../img/profile_pics/vlad.png'), name: 'Volodya Sargsyan', profession: 'CTO|CO-FOUNDER' },
        { id: 3, img: require('../img/profile_pics/narina.png'), name: 'Narina Sargsyan', profession: 'Back-end Engineer' },
        { id: 7, img: require('../img/profile_pics/arsen.png'), name: 'Arsen Grigoryan', profession: 'Project Manager' },
        { id: 9, img: require('../img/profile_pics/metaks.png'), name: 'Metaks Avagyan', profession: 'UI|UX Designer' },
        { id: 4, img: require('../img/profile_pics/narek.png'), name: 'Narek Shmavonyan', profession: 'Back-end Engineer' },
        { id: 5, img: require('../img/profile_pics/mila.png'), name: 'Mila Hakobyan', profession: 'Back-end Engineer' },
        { id: 6, img: require('../img/profile_pics/artur.png'), name: 'Artur Torosyan', profession: 'Front-end Engineer' },
        { id: 8, img: require('../img/profile_pics/lilit.png'), name: 'Lilit Davtyan', profession: 'Back-end Engineer' },

    ]
    return (
        <div className="swiper">
            <Swiper
                breakpoints={{
                    1450: {
                        slidesPerView: 4.35
                    },
                    1380: {
                        slidesPerView: 4
                    },
                    1230: {
                        slidesPerView: 3.5
                    },
                    1120: {
                        slidesPerView: 3
                    },
                    1000: {
                        slidesPerView: 2.5
                    },
                    850: {
                        slidesPerView: 2
                    },
                    710: {
                        slidesPerView: 1.5
                    },
                    635: {
                        slidesPerView: 1
                    }

                }}
                navigation={true}
                grabCursor={true}
                spaceBetween={1}
                updateOnWindowResize={true} modules={[Navigation]}>
                {
                    team.map(({ id, name, img, profession }) => {
                        return <SwiperSlide key={id}>
                            <div className="swiperBox" style={{
                                height: '500px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '48px',
                            }}>
                                <div className="swipperDiv" style={{
                                    width: "275px",
                                    height: "275px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: '50%',
                                    border: '2px dotted #00ffa1',
                                    marginBottom: '25px',
                                }}>
                                    <img style={{width:'90%',height:'90%'}} src={img} alt="image" />
                                </div>
                                <p>
                                    {name}
                                </p>
                                <p style={{
                                    marginTop: '18px'
                                }}>
                                    {profession}
                                </p>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    );
};
export default Swipper;

import React, {useEffect, useRef} from 'react';

const Back1 = () => {

    const myElem = useRef(null)
    const svgRef = useRef(null) //may be deleted
    const handleMouseEnter = () => {
        if (window.innerWidth > 500) {
            if (myElem.current) {
                const tags = Array.from(myElem.current.querySelectorAll('path'));
                tags.forEach((tag, index) => {
                    tag.style.transform = `translate(${Math.random() * 41 - 10}px)`;
                    tag.style.transition = ".7s"
                });
            }
        }
    }
    const handleMouseLeave = () => {
        if (window.innerWidth > 500) {
            if (myElem.current) {
                const tags = Array.from(myElem.current.querySelectorAll('path'));
                tags.forEach((tag) => {
                    tag.style.transform = `translate(0px, 0px)`;
                });
            }
        }
    }
    useEffect(() => { //may be deleted
        if (window.innerWidth > 500) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            handleMouseEnter()
                            svgRef.current.classList.add('animate');
                        } else {
                            handleMouseLeave()
                            svgRef.current.classList.remove('animate');
                        }
                    });
                },
                {threshold: 0.5}
            );

            observer.observe(svgRef.current);

            return () => {
                observer.unobserve(svgRef.current);
            };
        }
    }, []);


    return (
        <div className='back1SVG' style={{
            position: 'absolute',
            right: '0px',
            width: '40%',
            zIndex: '1'
        }}>
            <svg ref={svgRef} /*ref may be deleted */ onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                 className='back1' width="100%" height="100%" viewBox="0 0 683 673" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g ref={myElem} clipPath="url(#clip0_11_157)">
                    <path
                        d="M857.471 200.688C883.913 147.673 806.903 95.268 764.11 174.588C725.047 246.982 678.787 153.054 630.301 175.467C553.396 211.004 615.962 303.13 678.779 272.713C760.681 233.051 769.469 320.225 805.275 350.227C829.421 370.463 856.118 367.782 869.199 345.081C881.99 322.871 873.306 305.068 857.837 291.406C846.432 281.326 821.458 277.559 817.053 260.181C809.708 231.193 842.227 231.233 857.471 200.688Z"
                        stroke="url(#paint0_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M631.614 172.823C620.137 178.023 610.063 185.874 602.221 195.731C588.283 212.857 590.544 235.19 601.822 252.353C611.105 266.463 627.161 277.766 645.236 278.701C656.615 279.29 667.892 278.427 678.573 273.479C759.426 235.955 768.606 322.543 805.094 352.367C829.884 372.5 856.824 368.923 869.671 345.8C882.235 323.129 872.972 305.385 857.544 291.561C852.44 286.968 844.699 283.514 837.419 279.571C828.466 274.727 820.039 269.215 817.79 259.56C810.98 230.438 843.273 229.9 858.345 198.978C884.188 145.95 807.957 93.8422 765.217 172.055C726.234 243.437 680.095 150.678 631.614 172.823Z"
                        stroke="url(#paint1_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M632.775 170.373C618.236 176.943 609.365 186.181 601.784 194.052C586.06 210.397 587.543 233.77 598.586 251.799C607.754 266.754 624.838 279.323 643.763 279.218C656.311 279.143 667.83 279.037 678.216 274.439C758.058 239.123 767.494 325.154 804.755 354.687C830.137 374.808 857.372 370.253 869.991 346.713C882.327 323.572 872.535 305.913 857.09 291.91C851.954 287.217 844.38 283.581 837.304 279.498C828.61 274.462 820.451 268.892 818.36 259.129C812.086 229.883 844.144 228.768 859.053 197.468C884.306 144.437 808.84 92.6217 766.163 169.717C727.268 240.097 681.268 148.465 632.775 170.373Z"
                        stroke="url(#paint2_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M633.811 168.132C618.528 174.972 609.77 184.971 601.221 192.583C583.75 208.143 584.418 232.569 595.225 251.455C604.252 267.232 622.415 281.029 642.159 279.939C655.898 279.175 667.643 279.845 677.754 275.626C756.527 242.523 766.329 327.936 804.316 357.239C830.317 377.298 857.807 371.811 870.207 347.854C882.305 324.243 871.942 306.687 856.524 292.443C851.364 287.684 843.922 283.864 837.072 279.605C828.646 274.375 820.746 268.749 818.819 258.892C813.074 229.517 844.913 227.839 859.648 196.143C884.31 143.133 809.622 91.5848 767.001 167.568C728.153 236.943 682.252 146.452 633.811 168.132Z"
                        stroke="url(#paint3_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M634.639 166.028C618.622 173.138 609.986 183.915 600.461 191.259C581.256 206.02 581.081 231.519 591.661 251.253C600.584 267.902 619.799 282.822 640.353 280.802C655.291 279.318 667.249 280.801 677.048 276.919C754.792 246.108 764.924 330.827 803.624 359.897C830.252 379.893 858.068 373.509 870.172 349.096C882.104 325.049 871.122 307.545 855.741 293.134C850.57 288.293 843.286 284.292 836.634 279.878C828.476 274.455 820.829 268.758 819.069 258.802C813.852 229.313 845.46 227.053 860.021 194.97C884.109 141.951 810.175 90.6862 767.607 165.562C728.843 233.93 683.047 144.564 634.639 166.028Z"
                        stroke="url(#paint4_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M635.289 164.051C618.527 171.421 610.058 182.97 599.518 190.057C578.645 204.068 577.565 230.587 587.909 251.168C596.712 268.666 616.992 284.685 638.368 281.782C654.519 279.583 666.676 281.883 676.184 278.347C752.832 249.842 763.355 333.849 802.779 362.694C830.025 382.628 858.12 375.316 869.981 350.482C881.695 325.974 870.145 308.538 854.784 293.946C849.599 289.028 842.462 284.847 836.017 280.268C828.148 274.669 820.729 268.889 819.153 258.856C814.467 229.248 845.854 226.415 860.263 193.954C883.779 140.955 810.606 89.9345 768.078 163.729C729.352 231.058 683.667 142.779 635.289 164.051Z"
                        stroke="url(#paint5_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M635.731 162.193C618.229 169.827 609.904 182.173 598.361 188.97C575.831 202.229 573.838 229.778 583.959 251.201C592.633 269.559 614.01 286.659 636.171 282.885C653.537 279.927 665.881 283.08 675.102 279.894C750.683 253.767 761.584 336.995 801.726 365.61C829.6 385.482 857.937 377.264 869.586 351.982C881.078 327.008 868.95 309.66 853.615 294.872C848.419 289.872 841.435 285.515 835.192 280.767C827.582 274.975 820.421 269.138 818.993 258.989C814.823 229.258 845.989 225.852 860.239 192.998C883.193 140.028 810.754 89.2922 768.302 161.944C729.653 228.296 684.069 141.103 635.731 162.193Z"
                        stroke="url(#paint6_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M635.97 160.421C617.724 168.325 609.567 181.471 597.002 187.979C572.83 200.5 569.897 229.066 579.801 251.335C588.338 270.563 610.809 288.697 633.78 284.084C652.346 280.353 664.883 284.382 673.827 281.536C748.317 257.847 759.615 340.231 800.465 368.617C828.972 388.431 857.617 379.273 868.979 353.578C880.258 328.138 867.575 310.853 852.248 295.898C846.717 290.465 840.657 285.597 834.159 281.367C826.199 276.26 819.915 269.498 818.655 259.24C815.008 229.41 845.964 225.43 860.034 192.165C882.42 139.22 810.75 88.7619 768.334 160.283C729.755 225.624 684.257 139.514 635.97 160.421Z"
                        stroke="url(#paint7_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M636.007 158.746C617.016 166.911 609.037 180.857 595.469 187.054C569.666 198.856 565.778 228.426 575.464 251.532C583.903 271.583 607.421 290.772 631.196 285.351C650.971 280.837 663.692 285.752 672.363 283.251C745.732 262.055 757.462 343.534 799.021 371.702C828.159 391.448 857.071 381.323 868.198 355.241C879.249 329.34 866.003 312.128 850.696 296.992C845.469 291.829 839.411 286.324 832.948 282.039C825.148 276.863 819.211 269.911 818.128 259.553C815.012 229.604 845.744 225.066 859.644 191.4C881.489 138.492 810.521 88.2627 768.188 158.694C729.655 223.05 684.248 138.008 636.007 158.746Z"
                        stroke="url(#paint8_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M635.849 157.147C616.109 165.577 608.309 180.332 593.69 186.251C566.266 197.343 561.409 227.913 570.881 251.85C579.169 272.787 603.792 292.955 628.38 286.734C649.395 281.401 662.256 287.255 670.664 285.078C742.885 266.435 755.078 346.954 797.345 374.893C827.116 394.581 856.249 383.532 867.176 357.031C878.009 330.659 864.194 313.515 848.904 298.203C843.667 292.958 837.873 287.212 831.5 282.822C823.865 277.572 818.288 270.425 817.365 259.989C814.749 229.947 845.298 224.823 859.024 190.751C880.317 137.89 810.076 87.9031 767.8 157.212C729.351 220.552 684.018 136.555 635.849 157.147Z"
                        stroke="url(#paint9_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M635.489 155.617C615.009 164.312 607.389 179.886 591.736 185.497C562.683 195.889 556.869 227.444 566.133 252.216C574.283 274.013 599.992 295.171 625.391 288.166C647.579 282.037 660.635 288.796 668.794 286.957C739.83 270.924 752.523 350.416 795.494 378.142C825.898 397.762 855.318 385.809 865.958 358.842C876.568 331.994 862.195 314.937 846.922 299.439C841.669 294.117 836.145 288.134 829.857 283.637C822.391 278.306 817.169 271.023 816.412 260.449C814.299 230.309 844.647 224.611 858.208 190.132C878.97 137.318 809.436 87.5641 767.218 155.771C728.859 218.117 683.621 135.193 635.489 155.617Z"
                        stroke="url(#paint10_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M634.938 154.148C613.713 163.103 606.278 179.51 589.592 184.803C558.945 194.527 552.13 227.045 561.181 252.649C569.192 275.316 595.986 297.445 622.195 289.664C645.563 282.745 658.826 290.438 666.72 288.903C736.546 275.568 749.767 353.94 793.443 381.452C824.479 401.005 854.164 388.129 864.582 360.759C874.999 333.39 860.032 316.461 844.776 300.787C839.512 295.384 833.341 290.208 828.05 284.553C821.838 277.917 815.859 271.682 815.29 261.017C813.685 230.774 843.842 224.511 857.228 189.617C877.459 136.858 808.637 87.342 766.481 154.431C728.167 215.754 683.007 133.879 634.938 154.148Z"
                        stroke="url(#paint11_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M634.2 152.735C612.23 161.95 604.98 179.19 587.256 184.161C554.996 193.218 547.203 226.693 556.041 253.128C563.915 276.664 591.783 299.755 618.828 291.212C643.405 283.447 656.774 292.095 664.441 290.926C732.951 280.296 746.803 357.546 791.201 384.814C822.879 404.298 852.819 390.501 863.003 362.71C873.186 334.918 857.667 318.018 842.435 302.148C837.186 296.675 831.15 291.344 826.054 285.496C820.129 278.709 814.372 272.377 813.974 261.608C812.873 231.267 842.844 224.433 856.066 189.141C875.776 136.438 807.673 87.1304 765.566 153.118C727.267 213.418 682.212 132.606 634.2 152.735Z"
                        stroke="url(#paint12_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M633.278 151.37C610.558 160.84 603.504 178.923 584.74 183.571C550.873 191.966 542.066 226.366 550.726 253.654C558.457 278.056 587.405 302.113 615.266 292.809C641.038 284.203 654.529 292.449 662.022 292.945C726.667 273.243 743.729 361.156 788.783 388.223C821.093 407.64 851.294 392.915 861.243 364.703C871.193 336.49 855.102 319.609 839.909 303.601C834.619 298.029 828.787 292.567 823.846 286.499C818.202 279.566 812.667 273.146 812.461 262.261C811.853 231.824 841.643 224.424 854.673 188.693C873.863 136.055 806.443 86.987 764.398 151.857C726.223 211.167 681.236 131.385 633.278 151.37Z"
                        stroke="url(#paint13_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M632.175 150.039C608.71 159.77 601.842 178.695 582.044 183.016C546.566 190.763 536.787 226.134 545.222 254.226C552.809 279.493 582.836 304.496 611.529 294.445C638.49 284.994 652.155 294.107 659.401 295.024C722.721 276.866 740.411 364.8 786.19 391.662C819.146 411.005 849.593 395.359 859.304 366.73C869.015 338.101 852.401 321.247 837.193 305.035C831.888 299.386 826.226 293.782 821.481 287.53C816.118 280.452 810.823 273.913 810.785 262.929C810.672 232.404 840.286 224.433 853.131 188.287C871.819 135.712 805.086 86.86 763.103 150.622C724.989 208.931 680.069 130.171 632.175 150.039Z"
                        stroke="url(#paint14_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M630.898 148.747C606.693 158.733 600.011 178.51 579.168 182.495C542.073 189.58 531.321 225.903 539.557 254.829C546.997 280.967 578.097 306.913 607.617 296.109C635.768 285.813 649.634 295.774 656.61 297.137C718.604 280.514 736.944 368.495 783.383 395.132C816.976 414.402 847.682 397.829 857.155 368.784C866.667 339.61 849.465 322.908 834.275 306.52C828.959 300.789 823.462 295.047 818.909 288.617C813.818 281.403 808.759 274.76 808.898 263.667C809.279 233.045 838.717 224.512 851.386 187.941C869.564 135.44 803.517 86.8032 761.586 149.448C723.575 206.739 678.743 129.018 630.898 148.747Z"
                        stroke="url(#paint15_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M629.457 147.475C604.501 157.716 598.038 178.373 576.134 181.998C537.443 188.459 525.682 225.702 533.718 255.454C541.011 282.462 573.184 309.35 603.541 297.804C632.871 286.654 646.939 297.47 653.649 299.274C714.313 284.192 733.326 372.195 780.468 398.662C814.713 417.872 845.66 400.374 854.894 370.903C864.183 341.263 846.422 324.628 831.27 308.092C825.943 302.279 820.612 296.409 816.251 289.738C811.438 282.392 806.608 275.632 806.925 264.439C807.795 233.725 837.072 224.625 849.55 187.625C867.233 135.202 801.862 86.7803 759.999 148.313C721.997 204.567 677.204 127.879 629.457 147.475Z"
                        stroke="url(#paint16_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M627.856 146.228C602.15 156.726 595.848 178.212 572.935 181.523C532.638 187.34 519.879 225.522 527.716 256.1C534.866 283.973 568.106 311.799 599.301 299.519C629.784 287.483 644.089 299.178 650.53 301.438C709.868 287.881 729.506 375.928 777.371 402.18C812.248 421.313 843.456 402.898 852.437 373.026C861.504 342.92 843.189 326.357 828.022 309.617C822.685 303.723 817.508 297.75 813.343 290.841C808.809 283.375 804.181 276.518 804.721 265.184C806.051 234.36 835.181 224.716 847.479 187.285C864.672 134.945 799.843 86.6321 758.16 147.141C720.137 202.333 675.535 126.754 627.856 146.228Z"
                        stroke="url(#paint17_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M626.1 144.982C599.644 155.735 593.529 178.094 569.58 181.058C527.659 186.253 513.92 225.352 521.567 256.755C528.542 285.521 562.873 314.258 594.91 301.24C626.594 288.368 641.079 300.901 647.255 303.602C705.262 291.585 725.575 379.683 774.082 405.721C809.58 424.787 841.054 405.439 849.814 375.131C858.653 344.554 839.785 328.073 824.625 311.148C819.277 305.172 814.284 299.06 810.28 291.946C806.019 284.364 801.658 277.357 802.32 265.956C804.151 235.068 833.114 224.843 845.22 186.983C861.933 134.724 797.76 86.6096 756.14 146.01C718.224 200.189 673.704 125.635 626.1 144.982Z"
                        stroke="url(#paint18_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M624.183 143.76C596.986 154.768 591.054 177.985 566.07 180.611C522.544 185.182 507.779 225.179 515.232 257.411C522.078 287.027 557.458 316.713 590.333 302.962C623.208 289.21 637.918 302.647 643.798 305.772C700.48 295.289 721.451 383.415 770.683 409.257C806.846 428.229 838.547 407.981 847.079 377.256C855.7 346.208 836.263 329.795 821.124 312.699C815.766 306.641 810.904 300.388 807.124 293.069C803.127 285.371 799.001 278.242 799.858 266.72C802.173 235.736 830.985 224.952 842.895 186.659C859.138 134.5 795.61 86.5649 754.058 144.862C716.174 198.052 671.728 124.533 624.183 143.76Z"
                        stroke="url(#paint19_linear_11_157)" stroke-width="0.506358" strokeMiterlimit="10"/>
                    <path
                        d="M622.125 142.534C594.153 153.775 588.429 177.882 562.418 180.151C517.268 184.099 501.544 225.042 508.793 258.096C515.486 288.577 551.925 319.211 585.64 304.693C619.671 290.084 634.58 304.357 640.227 307.951C695.578 299.008 717.203 387.167 767.125 412.801C803.934 431.699 835.881 410.53 844.175 379.389C852.573 347.875 832.577 331.53 817.455 314.257C812.086 308.117 807.397 301.76 803.794 294.206C800.053 286.413 796.179 279.129 797.218 267.503C799.998 236.434 828.658 225.072 840.397 186.358C856.16 134.281 793.278 86.5439 751.788 143.734C713.97 195.917 669.585 123.405 622.125 142.534Z"
                        stroke="url(#paint20_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M619.927 141.295C591.226 152.81 585.661 177.78 558.623 179.692C511.859 183.018 495.134 224.87 502.204 258.757C508.739 290.11 546.258 321.647 580.834 306.442C616.033 290.961 631.165 306.109 636.532 310.149C690.562 302.736 712.846 390.932 763.443 416.355C800.903 435.174 833.092 413.089 841.157 381.531C849.3 349.568 828.772 333.269 813.659 315.85C808.279 309.628 803.758 303.172 800.338 295.376C796.918 287.581 793.225 280.057 794.451 268.32C797.702 237.16 826.24 225.258 837.74 186.108C853.032 134.121 790.8 86.5682 749.369 142.655C711.61 193.755 667.313 122.283 619.927 141.295Z"
                        stroke="url(#paint21_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M617.601 140.052C588.148 151.813 582.781 177.654 554.703 179.224C506.31 181.933 488.604 224.694 495.488 259.391C501.872 291.62 540.462 324.08 575.876 308.141C612.238 291.783 627.593 307.805 632.699 312.291C685.399 306.418 708.342 394.642 759.64 419.875C797.741 438.617 830.176 415.611 838.02 383.615C845.963 351.16 824.852 334.936 809.765 317.34C804.295 311.152 799.907 304.087 796.784 296.444C793.567 288.426 790.169 280.877 791.582 269.03C795.312 237.769 823.709 225.327 835.031 185.722C849.874 133.835 788.267 86.4626 746.903 141.447C709.157 191.622 664.911 121.147 617.601 140.052Z"
                        stroke="url(#paint22_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M615.136 138.78C584.938 150.792 579.755 177.511 550.645 178.729C500.618 180.806 481.932 224.494 488.596 260.062C494.827 293.157 534.49 326.559 570.747 309.882C608.276 292.642 623.828 309.525 628.685 314.475C680.064 310.123 703.66 398.389 755.645 423.429C794.392 442.087 827.073 418.17 834.666 385.774C842.375 352.844 820.722 336.72 805.641 318.919C800.163 312.641 795.879 305.416 792.999 297.602C790.043 289.492 786.887 281.79 788.482 269.839C792.664 238.508 820.948 225.495 832.073 185.447C846.462 133.664 785.485 86.4673 744.179 140.349C706.55 189.446 662.372 119.993 615.136 138.78Z"
                        stroke="url(#paint23_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M612.555 137.49C581.607 149.757 576.607 177.352 546.471 178.213C494.831 179.696 475.162 224.254 481.64 260.642C487.714 294.608 528.429 328.915 565.549 311.528C604.237 293.415 620.036 311.195 624.603 316.574C674.638 313.777 698.903 402.07 751.582 426.888C790.975 445.472 823.898 420.639 831.261 387.825C838.776 354.408 816.536 338.373 801.467 320.402C795.978 314.03 791.799 306.641 789.169 298.657C786.449 290.444 783.555 282.607 785.342 270.541C789.961 239.146 818.165 225.536 829.066 185.075C843.004 133.391 782.643 86.3755 741.41 139.148C703.822 187.247 659.707 118.819 612.555 137.49Z"
                        stroke="url(#paint24_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M609.861 136.172C578.157 148.681 573.325 177.14 542.178 177.667C488.901 178.553 468.216 224.007 474.549 261.222C480.461 296.054 522.242 331.28 560.215 313.173C600.07 294.168 616.088 312.837 620.39 318.657C669.086 317.42 694.04 405.695 747.397 430.34C787.449 448.826 820.596 423.097 827.725 389.862C834.99 356.001 812.201 340.042 797.165 321.864C791.671 315.387 787.603 307.826 785.225 299.677C782.751 291.369 780.087 283.405 782.078 271.207C787.181 239.706 815.237 225.586 825.949 184.653C839.453 133.081 779.711 86.2319 738.536 137.902C701.001 185.03 656.936 117.59 609.861 136.172Z"
                        stroke="url(#paint25_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M607.075 134.794C574.616 147.554 569.993 176.913 537.794 177.069C482.902 177.323 461.234 223.719 467.366 261.74C473.115 297.439 515.958 333.588 554.79 314.756C595.802 294.87 612.053 314.423 616.064 320.671C663.452 320.938 689.052 409.266 743.086 433.718C783.779 452.136 817.163 425.496 824.087 391.838C831.124 357.506 807.799 341.589 792.742 323.257C787.226 316.687 783.256 308.964 781.123 300.659C778.886 292.257 776.502 284.128 778.667 271.846C784.225 240.25 812.155 225.604 822.67 184.207C835.734 132.744 776.616 86.0461 735.495 136.629C698.035 182.75 654.052 116.335 607.075 134.794Z"
                        stroke="url(#paint26_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M604.144 133.416C570.939 146.418 566.517 176.686 533.275 176.462C476.755 176.108 454.1 223.408 460.038 262.259C465.63 298.83 509.544 335.892 549.229 316.34C591.395 295.567 607.874 316.009 611.64 322.726C657.703 324.528 683.974 412.868 738.691 437.142C780.025 455.472 813.677 427.895 820.33 393.838C827.14 359.035 803.265 343.195 788.236 324.696C782.704 318.023 778.837 310.132 776.957 301.675C774.956 293.179 772.838 284.901 775.185 272.504C781.194 240.828 809.041 225.619 819.34 183.781C831.969 132.43 773.437 85.9058 732.38 135.4C694.985 180.442 651.069 115.039 604.144 133.416Z"
                        stroke="url(#paint27_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M601.133 131.969C567.176 145.216 562.918 176.353 528.665 175.785C470.522 174.828 446.876 223.028 452.634 262.703C458.082 300.129 503.04 338.126 543.578 317.854C586.906 296.195 603.618 317.52 607.115 324.683C651.842 328.021 678.777 416.393 734.174 440.46C776.163 458.705 810.044 430.229 816.477 395.744C823.058 360.471 798.63 344.703 783.608 326.028C778.06 319.25 774.3 311.191 772.673 302.59C770.903 293.996 769.047 285.563 771.591 273.065C778.051 241.3 805.797 225.549 815.874 183.264C828.083 132.019 770.157 85.6774 729.17 134.034C691.826 178.086 647.972 113.69 601.133 131.969Z"
                        stroke="url(#paint28_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M598.032 130.463C563.325 143.956 559.247 175.984 523.989 175.034C464.218 173.469 439.586 222.573 445.155 263.073C450.432 301.386 496.465 340.281 537.866 319.289C582.351 296.738 599.287 318.956 602.513 326.556C645.92 331.433 673.512 419.824 729.596 443.707C772.231 461.86 806.349 432.482 812.553 397.58C818.907 361.837 793.929 346.136 778.919 327.29C773.444 320.404 770.059 313.174 768.324 303.421C766.776 294.738 765.187 286.165 767.921 273.542C774.827 241.684 802.501 225.407 812.367 182.675C824.152 131.552 766.82 85.3637 725.897 132.622C688.566 175.696 644.791 112.286 598.032 130.463Z"
                        stroke="url(#paint29_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M594.848 128.901C559.385 142.636 555.491 175.551 519.189 174.264C457.781 172.102 432.175 222.085 437.556 263.429C442.662 302.619 489.76 342.423 532.02 320.715C577.653 297.274 594.826 320.38 597.793 328.435C639.869 334.843 668.129 423.251 724.91 446.96C768.196 465.034 802.55 434.735 808.525 399.417C814.647 363.208 789.115 347.579 774.122 328.557C768.632 321.594 765.366 314.269 763.866 304.266C762.55 295.494 761.227 286.762 764.158 274.038C771.51 242.096 799.077 225.263 808.734 182.049C820.109 131.042 763.368 85.0217 722.501 131.168C685.258 173.2 641.527 110.818 594.848 128.901Z"
                        stroke="url(#paint30_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M591.584 127.27C555.37 141.25 551.655 175.058 514.326 173.413C451.295 170.638 424.69 221.54 429.889 263.699C434.838 303.75 482.998 344.478 526.121 322.05C572.906 297.713 590.313 321.712 593.01 330.214C633.755 338.161 662.682 426.586 720.13 450.094C764.07 468.074 798.662 436.875 804.399 401.14C810.275 364.481 784.216 348.894 769.235 329.701C763.734 322.656 760.584 315.24 759.319 304.987C758.22 296.123 757.174 287.241 760.291 274.407C768.11 242.398 795.584 225.003 804.991 181.364C815.957 130.472 759.8 84.6067 719.002 129.65C681.851 170.664 638.181 109.279 591.584 127.27Z"
                        stroke="url(#paint31_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M588.243 125.563C551.294 139.804 547.748 174.484 509.392 172.482C444.719 169.106 417.132 220.907 422.152 263.889C426.934 304.813 476.16 346.459 520.146 323.31C568.079 298.082 585.723 322.969 588.166 331.931C627.57 341.4 657.151 429.858 715.291 453.177C759.881 471.069 794.71 438.959 800.218 402.807C805.888 365.652 779.258 350.166 764.284 330.798C758.772 323.671 755.737 316.155 754.708 305.663C753.817 296.716 753.065 287.673 756.374 274.724C764.604 242.609 792.026 224.697 801.207 180.597C811.759 129.845 756.196 84.1239 715.456 128.064C678.369 168.054 634.76 107.676 588.243 125.563Z"
                        stroke="url(#paint32_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M584.837 123.782C547.139 138.215 543.772 173.841 504.393 171.477C438.088 167.498 409.514 220.193 414.354 264.01C418.963 305.791 469.277 348.373 514.111 324.491C563.201 298.381 581.069 324.162 583.226 333.546C621.325 344.568 651.582 433.023 710.41 456.179C755.645 473.978 790.707 440.962 795.995 404.392C801.428 366.758 774.253 351.344 759.292 331.805C753.749 324.578 750.844 316.985 750.049 306.243C749.389 297.188 748.904 287.995 752.406 274.96C761.076 242.747 788.432 224.305 797.376 179.745C807.542 129.102 752.54 83.5513 711.858 126.379C674.826 165.364 631.273 105.988 584.837 123.782Z"
                        stroke="url(#paint33_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M581.371 121.921C542.911 136.6 539.738 173.112 499.306 170.412C431.377 165.82 401.807 219.42 406.49 264.035C410.942 306.697 462.292 350.17 508.004 325.591C558.231 298.572 576.353 325.254 578.245 335.089C615.009 347.647 645.958 436.132 705.428 459.073C751.317 476.778 786.612 442.867 791.667 405.876C796.872 367.781 769.148 352.434 754.193 332.719C748.686 325.451 745.839 317.718 745.3 306.735C744.848 297.588 744.625 288.25 748.337 275.098C757.448 242.797 784.742 223.819 793.449 178.799C803.225 128.281 748.789 82.8853 708.169 124.615C671.217 162.588 627.725 104.209 581.371 121.921Z"
                        stroke="url(#paint34_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M577.85 119.958C538.625 134.88 535.637 172.288 494.214 169.199C424.634 164.016 394.087 218.509 398.559 263.985C402.849 307.513 455.255 351.906 501.858 326.575C553.232 298.665 571.593 326.245 573.215 336.526C608.653 350.619 640.253 439.098 700.421 461.875C746.966 479.496 782.492 444.669 787.314 407.266C792.291 368.701 764.022 353.416 749.075 333.535C743.526 326.158 740.811 318.358 740.517 307.124C740.299 297.854 740.334 288.397 744.243 275.134C753.786 242.745 781.022 223.236 789.492 177.756C798.875 127.367 745.012 82.1169 704.477 122.767C667.529 159.699 624.114 102.341 577.85 119.958Z"
                        stroke="url(#paint35_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M574.28 117.907C534.294 133.075 531.496 171.374 489.05 167.923C417.833 162.133 386.317 217.509 390.6 263.819C394.744 308.228 448.175 353.503 495.64 327.505C548.148 298.711 566.76 327.172 568.13 337.88C602.243 353.507 634.515 441.999 695.36 464.592C742.549 482.121 778.314 446.393 782.906 408.573C787.656 369.537 758.833 354.324 743.903 334.267C738.343 326.808 735.723 318.909 735.684 307.435C735.635 298.075 735.99 288.46 740.1 275.091C750.069 242.609 777.258 222.569 785.487 176.634C794.493 126.353 741.182 81.2646 700.683 120.794C663.842 156.756 620.462 100.382 574.28 117.907Z"
                        stroke="url(#paint36_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M570.665 115.753C529.914 131.163 527.31 170.358 483.837 166.54C410.987 160.148 378.471 216.434 382.593 263.557C386.554 308.824 441.078 355.05 489.404 328.293C543.073 298.592 561.905 327.962 562.997 339.136C595.784 356.298 628.718 444.802 690.24 467.213C738.085 484.648 774.077 448.02 778.45 409.783C782.963 370.277 753.595 355.135 738.672 334.903C733.121 327.351 730.581 319.359 730.798 307.643C730.971 298.205 731.592 288.431 735.899 274.951C746.303 242.376 773.446 221.814 781.423 175.415C790.04 125.268 737.298 80.3202 696.858 118.747C660.103 153.668 616.766 98.3118 570.665 115.753Z"
                        stroke="url(#paint37_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M567.014 113.485C525.502 129.142 523.087 169.228 478.593 165.048C404.105 158.058 370.603 215.239 374.544 263.186C378.339 309.325 433.929 356.46 483.104 329.007C537.92 298.406 556.996 328.678 557.813 340.294C589.27 358.996 622.885 447.512 685.081 469.745C733.574 487.082 769.804 449.553 773.944 410.894C778.229 370.918 748.312 355.853 733.401 335.449C727.825 327.821 725.39 319.72 725.861 307.763C726.241 298.224 727.148 288.298 731.652 274.717C742.487 242.045 769.588 220.956 777.308 174.089C785.564 124.063 733.363 79.2776 692.986 116.596C656.301 150.516 613.034 96.1373 567.014 113.485Z"
                        stroke="url(#paint38_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M563.319 111.09C521.042 126.989 518.818 167.976 473.3 163.433C397.175 155.837 362.691 213.916 366.458 262.692C370.08 309.698 426.714 357.767 476.775 329.568C532.733 298.072 552.057 329.241 552.605 341.313C582.736 361.54 617.008 450.064 679.891 472.124C729.04 489.367 765.498 450.938 769.408 411.862C773.465 371.415 743.014 356.421 728.101 335.843C722.513 328.123 720.173 319.923 720.905 307.729C721.429 296.334 723.612 285.08 727.385 274.32C738.651 241.56 765.719 219.943 773.188 172.612C781.07 122.744 729.423 78.0851 689.104 114.301C652.475 147.225 609.258 93.8349 563.319 111.09Z"
                        stroke="url(#paint39_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M559.629 108.607C516.618 124.713 514.561 166.626 468.022 161.721C390.25 153.528 354.784 212.496 358.371 262.106C361.831 309.979 419.534 358.942 470.446 330.058C527.546 297.657 547.114 329.728 547.396 342.24C576.197 364.008 611.14 452.535 674.702 474.421C724.504 491.561 761.195 452.236 764.877 412.743C768.702 371.831 737.691 356.895 722.772 336.176C717.179 328.379 714.934 320.08 715.921 307.645C716.717 296.118 719.122 284.762 723.068 273.908C734.76 241.045 761.801 218.906 769.009 171.121C776.52 121.356 725.419 76.864 685.158 111.977C648.607 143.798 605.477 91.4367 559.629 108.607Z"
                        stroke="url(#paint40_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M555.923 105.993C512.151 122.335 510.275 165.15 462.718 159.888C383.309 151.087 346.86 210.954 350.268 261.388C353.561 310.133 412.326 360.01 464.096 330.411C522.348 297.114 542.149 330.077 542.167 343.041C569.642 366.343 605.257 454.882 669.492 476.592C719.943 493.633 756.868 453.412 760.33 413.502C763.908 372.121 732.367 357.25 717.5 336.304C711.896 328.425 709.721 320.014 710.988 307.351C712.054 295.677 714.688 284.202 718.819 273.237C730.937 240.281 757.955 217.623 764.902 169.365C772.043 119.742 721.497 75.3872 681.302 109.378C644.774 140.283 601.67 88.8972 555.923 105.993Z"
                        stroke="url(#paint41_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M552.178 103.226C507.648 119.791 505.953 163.517 457.374 157.892C376.328 148.494 338.905 209.23 342.12 260.514C345.246 310.121 405.067 360.917 457.699 330.577C517.089 296.381 537.139 330.251 536.881 343.657C563.031 368.494 599.318 457.045 664.23 478.571C715.336 495.52 752.488 454.398 755.722 414.071C759.076 372.214 726.981 357.415 712.105 336.324C706.49 328.364 704.394 319.84 705.927 306.945C707.275 295.138 710.144 283.557 714.463 272.49C726.996 239.433 754.003 216.256 760.678 167.536C767.465 118.049 717.449 73.8371 677.304 106.745C640.901 136.616 597.853 86.2228 552.178 103.226Z"
                        stroke="url(#paint42_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M548.44 100.33C503.154 117.127 501.64 161.764 452.022 155.753C369.334 145.754 330.916 207.404 333.97 259.501C336.924 309.976 397.806 361.685 451.314 330.673C511.851 295.586 532.135 330.349 531.613 344.205C556.432 370.583 593.38 459.127 658.979 480.48C710.736 497.34 748.12 455.313 751.126 414.569C754.262 372.25 721.593 357.506 706.738 336.253C701.113 328.21 699.08 319.605 700.905 306.446C702.524 294.505 705.622 282.815 710.126 271.643C723.085 238.484 750.074 214.793 756.483 165.604C762.91 116.249 713.434 72.1803 673.347 103.986C637.031 132.815 594.029 83.4154 548.44 100.33Z"
                        stroke="url(#paint43_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M544.704 97.2896C498.656 114.314 497.328 159.867 446.698 153.493C362.368 142.901 322.968 205.441 325.842 258.361C328.634 309.713 390.573 362.332 444.962 330.578C506.63 294.577 527.147 330.234 526.38 344.549C549.874 372.461 587.498 461.013 653.771 482.198C706.181 498.945 743.776 456.047 746.562 414.886C749.451 372.089 716.268 357.433 701.425 336.009C695.769 327.866 693.853 319.136 695.919 305.796C697.489 295.621 700.106 284.848 705.824 270.635C719.199 237.384 746.186 213.172 752.314 163.522C758.377 114.314 709.454 70.3719 669.416 101.075C633.148 128.866 590.211 80.4583 544.704 97.2896Z"
                        stroke="url(#paint44_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M540.972 94.0992C494.168 111.355 493.026 157.814 441.378 151.082C355.411 139.884 315.024 203.318 317.724 257.077C320.339 309.291 383.344 362.828 438.602 330.359C501.407 293.458 522.198 330.034 521.141 344.778C543.303 374.22 581.594 462.779 648.55 483.786C701.614 500.44 739.49 456.633 741.998 415.039C744.654 371.776 710.944 357.162 696.076 335.579C690.429 327.363 688.577 318.507 690.906 304.905C692.669 294.634 695.571 283.711 701.496 269.396C715.315 236.012 742.263 211.322 748.124 161.195C753.859 112.101 705.444 68.3284 665.469 97.9242C629.285 124.77 586.394 77.3564 540.972 94.0992Z"
                        stroke="url(#paint45_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M537.255 90.7581C489.693 108.236 488.732 155.606 436.073 148.521C348.459 136.727 307.09 201.049 309.615 255.637C312.067 308.718 376.124 363.17 432.25 329.985C496.202 292.183 517.232 329.657 515.914 344.846C536.747 375.829 575.719 464.399 643.338 485.23C697.056 501.78 735.142 457.084 737.455 415.075C739.879 371.337 705.61 356.81 690.768 335.04C685.11 326.742 683.332 317.769 685.953 303.958C687.931 293.565 691.107 282.516 697.233 268.095C711.455 234.635 738.436 209.394 744.003 158.81C749.392 109.852 701.503 66.2182 661.581 94.7165C625.435 120.515 582.59 74.0943 537.255 90.7581Z"
                        stroke="url(#paint46_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M533.556 87.2502C485.232 104.956 484.458 153.242 430.786 145.793C341.53 133.397 299.183 198.613 301.524 254.03C303.809 307.983 368.922 363.344 425.931 329.447C491.034 290.721 512.294 329.121 510.701 344.752C530.213 377.274 569.841 465.834 638.172 486.474C692.561 502.9 730.848 457.325 732.942 414.909C735.138 370.701 700.325 356.245 685.49 334.3C679.821 325.92 678.118 316.83 681.03 302.81C683.204 292.306 686.667 281.116 693 266.593C707.641 233.016 734.627 207.279 739.907 156.228C744.956 107.401 697.593 63.9157 657.723 91.3069C621.604 116.102 578.809 70.6698 533.556 87.2502Z"
                        stroke="url(#paint47_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M529.909 83.5972C480.828 101.525 480.234 150.722 425.55 142.92C334.648 129.927 291.346 196.011 293.49 252.283C295.603 307.103 361.755 363.404 419.65 328.76C485.859 289.18 507.403 328.436 505.546 344.519C523.727 378.571 564.039 467.118 633.017 487.631C688.038 503.979 726.565 457.479 728.441 414.654C730.4 369.976 695.047 355.586 680.214 333.472C674.54 325.004 672.911 315.798 676.083 301.54C678.467 290.901 682.189 279.61 688.759 264.993C703.812 231.33 730.823 205.061 735.808 153.545C740.516 104.847 693.669 61.5116 653.862 87.7949C617.793 111.517 575.054 67.0776 529.909 83.5972Z"
                        stroke="url(#paint48_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M526.25 79.7261C476.408 97.8811 475.986 147.99 420.299 139.834C327.76 126.244 283.444 193.244 285.439 250.313C287.381 306 354.613 363.195 413.358 327.866C480.721 287.355 502.496 327.539 500.37 344.067C517.247 379.628 558.195 468.219 627.869 488.538C683.544 504.784 722.294 457.378 723.974 414.12C725.695 368.972 689.803 354.658 674.972 332.363C669.3 323.799 667.753 314.509 671.186 300.015C673.747 289.275 677.763 277.804 684.525 263.091C699.989 229.322 727.023 202.546 731.707 150.559C736.089 101.996 689.763 58.8048 649.995 83.9869C614.019 106.765 571.33 63.313 526.25 79.7261Z"
                        stroke="url(#paint49_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M522.524 75.6592C471.919 94.0319 471.699 145.07 414.982 136.499C320.795 122.312 275.487 190.192 277.314 248.104C279.088 304.654 347.38 362.762 406.999 326.721C475.482 285.332 497.492 326.428 495.091 343.408C510.625 380.525 552.277 469.111 622.605 489.254C678.929 505.401 717.897 457.096 719.34 413.45C720.813 367.814 684.397 353.58 669.575 331.129C663.883 322.493 662.38 313.068 666.127 298.349C668.885 287.499 673.204 275.909 680.157 261.081C696.036 227.201 723.092 199.918 727.476 147.47C731.522 99.0326 685.712 55.9901 645.991 80.0509C610.165 101.788 567.518 59.3251 522.524 75.6592Z"
                        stroke="url(#paint50_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M517.629 70.9755C466.261 89.5658 466.223 141.525 408.47 132.607C312.637 117.824 266.366 186.601 267.998 245.333C269.605 302.755 338.96 361.777 399.447 325.02C469.084 282.701 491.356 324.688 488.695 342.113C502.885 380.786 545.196 469.336 616.218 489.329C673.222 505.386 712.418 456.19 713.612 412.11C714.886 366.001 677.897 351.841 663.086 329.209C657.383 320.491 655.968 310.924 659.978 296.007C662.931 285.036 667.532 273.311 674.688 258.386C690.974 224.425 718.075 196.608 722.149 143.691C725.869 95.3878 680.57 52.49 640.918 75.4669C605.133 96.1946 562.535 54.7202 517.629 70.9755Z"
                        stroke="url(#paint51_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M512.958 66.1419C460.833 84.9545 460.977 137.834 402.201 128.554C304.726 113.169 257.468 182.84 258.935 242.401C260.371 300.69 330.797 360.644 392.141 323.153C462.916 279.934 485.437 322.827 482.505 340.69C495.379 380.887 538.356 469.445 610.052 489.27C667.689 505.206 707.135 455.137 708.11 410.639C709.128 364.082 671.617 349.966 656.814 327.169C651.101 318.369 649.749 308.676 654.041 293.54C657.189 282.449 662.091 270.577 669.44 255.566C686.159 221.473 713.274 193.169 717.043 139.787C720.441 91.6124 675.649 48.8646 636.06 70.7433C600.351 90.4742 557.773 49.9707 512.958 66.1419Z"
                        stroke="url(#paint52_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M508.52 61.1933C455.627 80.219 455.962 134.019 396.165 124.376C297.048 108.39 248.813 178.954 250.105 239.344C251.341 298.531 322.831 359.355 385.068 321.161C456.975 277.038 479.731 320.854 476.553 339.146C488.097 380.883 531.74 469.439 604.105 489.112C662.391 504.939 702.066 453.98 702.826 409.069C703.607 362.041 665.552 347.998 650.756 325.025C645.037 316.138 643.754 306.324 648.332 290.973C651.671 279.758 656.842 267.774 664.391 252.628C681.521 218.429 708.678 189.617 712.132 135.77C715.208 87.7247 670.922 45.1269 631.376 65.8895C595.759 84.5931 553.253 45.0958 508.52 61.1933Z"
                        stroke="url(#paint53_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M504.307 56.1216C450.656 75.3598 451.173 130.09 390.363 120.085C289.594 103.497 240.381 174.945 241.494 236.168C242.586 296.192 315.11 357.962 378.221 319.056C451.264 274.024 474.283 318.73 470.82 337.484C481.039 380.756 525.335 469.335 598.41 488.799C657.35 504.523 697.249 452.678 697.78 407.34C698.329 359.848 659.733 345.86 644.918 322.699C639.188 313.73 637.98 303.79 642.848 288.21C646.361 276.846 651.84 264.75 659.621 249.524C677.157 215.215 704.365 185.893 707.495 131.583C710.255 83.6712 666.475 41.2232 626.977 60.8741C591.414 78.6165 548.958 40.1074 504.307 56.1216Z"
                        stroke="url(#paint54_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M500.318 50.9456C445.904 70.4015 446.604 126.053 384.776 115.68C282.355 98.4907 232.175 170.842 233.108 232.889C234.028 293.78 307.613 356.463 371.592 316.842C445.772 270.91 469.045 316.518 465.326 335.703C474.219 380.509 519.204 469.061 592.935 488.387C652.533 504.003 692.661 451.267 692.963 405.512C693.274 357.55 654.152 343.613 639.362 320.319C633.601 311.25 632.472 301.197 637.608 285.425C641.338 273.958 647.079 261.717 655.072 246.395C673.022 211.964 700.277 182.139 703.082 127.365C705.534 79.5566 662.238 37.2747 622.799 55.8328C587.293 72.5357 544.872 35.0013 500.318 50.9456Z"
                        stroke="url(#paint55_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M496.552 45.673C441.371 65.3421 442.248 121.939 379.416 111.183C275.354 93.4017 224.176 166.628 224.94 229.519C225.692 291.272 300.329 354.87 365.186 314.532C440.498 267.686 464.02 314.219 460.028 333.855C467.59 380.202 513.245 468.746 587.66 487.904C647.882 503.399 688.259 449.791 688.337 403.624C688.416 355.186 648.745 341.336 633.966 317.852C628.22 308.714 627.133 298.507 632.561 282.535C636.487 270.948 642.495 258.557 650.71 243.146C669.066 208.615 696.383 178.28 698.853 123.043C700.995 75.392 658.228 33.2342 618.799 50.7026C583.394 66.3584 541.019 29.8174 496.552 45.673Z"
                        stroke="url(#paint56_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M493.004 40.3174C437.055 60.1997 438.134 117.706 374.265 106.603C268.562 88.2302 216.406 162.341 216.995 226.06C217.571 288.686 293.25 353.208 359.004 312.143C435.453 264.397 455.779 311.383 454.957 331.915C453.023 380.174 507.514 468.329 582.607 487.323C643.508 502.728 684.094 448.221 683.943 401.627C683.789 352.724 643.579 338.941 628.808 315.29C623.05 306.07 622.02 295.767 627.742 279.541C631.858 267.83 638.164 255.326 646.575 239.805C665.346 205.162 692.72 174.323 694.861 118.617C696.691 71.0947 654.417 29.112 615.058 45.4333C579.687 60.129 537.385 24.5406 493.004 40.3174Z"
                        stroke="url(#paint57_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M489.663 34.8885C432.956 54.9833 434.218 113.42 369.332 101.95C261.986 82.9749 208.843 157.98 209.258 222.527C209.671 286.02 286.405 351.433 353.029 309.68C430.615 261.035 451.335 308.908 450.097 329.886C447.156 379.439 501.994 467.833 577.767 486.664C639.316 501.962 680.131 446.563 679.756 399.557C679.365 350.185 638.614 336.467 623.85 312.641C618.082 303.339 617.143 292.88 623.124 276.47C627.44 264.633 634.029 252.003 642.643 236.385C661.828 201.622 689.268 170.277 691.052 114.134C692.575 66.7351 650.793 24.8984 611.488 40.1222C576.247 53.7687 533.963 19.195 489.663 34.8885Z"
                        stroke="url(#paint58_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M486.537 29.4009C429.068 49.7037 430.511 109.071 364.617 97.2325C255.62 77.6566 201.5 153.555 201.749 218.921C201.98 283.281 279.791 349.622 347.273 307.144C426.009 257.574 447.142 306.342 445.472 327.808C441.523 378.708 496.714 467.282 573.156 485.95C635.363 501.139 676.402 444.856 675.808 397.432C675.184 347.584 633.894 333.933 619.137 309.941C613.363 300.552 612.483 289.962 618.751 273.348C623.258 261.386 630.128 248.62 638.954 232.905C658.555 198.031 686.061 166.181 687.519 109.555C688.74 62.2832 647.45 20.6022 608.193 34.7144C572.989 47.3764 530.745 13.7819 486.537 29.4009Z"
                        stroke="url(#paint59_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M483.607 23.8467C425.369 44.3627 426.989 104.647 360.083 92.4545C249.443 72.2769 194.346 149.069 194.407 215.279C194.471 280.502 273.343 347.756 341.694 304.562C421.558 254.103 443.084 303.748 441.008 325.669C436.043 377.957 491.59 466.677 568.72 485.181C631.582 500.266 672.844 443.087 672.031 395.236C671.19 344.937 629.339 331.334 614.604 307.161C608.824 297.685 608.017 286.968 614.562 270.141C619.263 258.049 626.418 245.166 635.466 229.363C655.471 194.368 683.058 162.017 684.167 104.924C685.086 57.7799 644.284 16.2599 605.074 29.2604C569.947 40.9352 527.743 8.32007 483.607 23.8467Z"
                        stroke="url(#paint60_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M480.908 18.2769C421.928 39.019 423.74 100.223 355.797 87.6647C243.51 66.8905 187.426 144.567 187.317 211.61C187.209 277.701 267.133 345.869 336.366 301.964C417.358 250.595 439.296 301.126 436.806 323.523C430.822 377.203 486.734 466.058 564.538 484.404C628.048 499.372 669.536 441.327 668.498 393.054C667.404 342.271 625.039 328.757 610.292 304.42C604.51 294.851 603.758 284.009 610.584 266.973C615.486 254.756 622.918 241.742 632.177 225.822C652.588 190.717 680.26 157.859 681.012 100.309C681.633 53.2879 641.319 11.9287 602.157 23.8176C567.106 34.4517 524.942 2.81567 480.908 18.2769Z"
                        stroke="url(#paint61_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M478.402 12.6745C418.638 33.5969 420.644 95.7598 351.697 82.828C237.754 61.458 180.697 140.023 180.413 207.895C180.137 274.847 261.114 343.94 331.22 299.314C413.343 247.041 435.689 298.453 432.757 321.327C425.766 376.447 482.025 465.395 560.508 483.578C624.676 498.438 666.41 439.471 665.126 390.812C663.794 339.559 620.89 326.111 606.151 301.608C600.349 291.968 599.673 280.975 606.782 263.74C611.879 251.392 619.589 238.247 629.042 222.261C649.863 187.04 677.616 153.671 678.019 95.6636C678.36 48.7443 638.516 7.56677 599.402 18.344C564.468 27.9444 522.349 -2.71752 478.402 12.6745Z"
                        stroke="url(#paint62_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M476.093 7.04306C415.555 28.1741 417.759 91.2715 347.789 77.9773C232.225 56.0235 174.16 135.465 173.707 204.16C173.26 271.99 255.297 341.987 326.276 296.649C409.532 243.472 432.264 295.81 428.948 319.095C420.886 375.645 477.55 464.691 556.717 482.706C621.529 497.462 663.479 437.631 661.993 388.533C660.42 336.816 616.98 323.429 602.244 298.755C596.436 289.028 595.819 277.895 603.22 260.46C608.512 247.982 616.509 234.716 626.178 218.617C647.408 183.27 675.257 149.404 675.292 90.9311C675.323 44.1595 635.98 3.12734 596.918 12.7878C562.027 21.4178 519.958 -8.26561 476.093 7.04306Z"
                        stroke="url(#paint63_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M473.993 1.42157C412.686 22.7464 415.083 86.7931 344.096 73.1313C226.865 50.5629 167.843 130.906 167.215 200.44C166.606 269.146 249.689 340.043 321.526 293.971C405.913 239.89 429.031 293.135 425.308 316.865C416.225 374.851 473.256 463.99 553.102 481.85C618.572 496.489 660.746 435.772 659.032 386.257C657.225 334.065 613.251 320.749 598.517 295.905C592.699 286.096 592.159 274.831 599.832 257.187C605.33 244.588 613.594 231.182 623.475 214.986C645.12 179.528 673.065 145.155 672.741 86.2156C672.479 39.5614 633.625 -1.30018 594.6 7.24932C559.79 14.887 517.756 -13.8217 473.993 1.42157Z"
                        stroke="url(#paint64_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M472.067 -4.22142C409.997 17.3114 412.577 82.2886 340.572 68.2593C221.694 45.0943 161.689 126.326 160.886 196.689C160.09 266.249 244.241 338.074 316.977 291.313C402.501 236.322 425.983 290.466 421.884 314.65C411.753 374.104 469.145 463.275 549.692 481C615.815 495.526 658.214 433.933 656.275 383.996C654.219 331.359 609.722 318.089 594.995 293.069C589.171 283.174 588.695 271.773 596.65 253.93C602.332 241.182 610.885 227.664 620.983 211.375C643.033 175.797 671.078 140.921 670.376 81.5263C669.822 34.9992 631.451 -5.69621 592.469 1.73715C557.743 8.35793 515.743 -19.3858 472.067 -4.22142Z"
                        stroke="url(#paint65_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M470.337 -9.83898C407.499 11.8974 410.271 77.814 337.248 63.4172C216.724 39.6557 155.737 121.777 154.765 192.972C153.791 263.395 238.999 336.139 312.638 288.684C399.289 232.785 423.173 287.859 418.661 312.465C407.508 373.418 465.293 462.64 546.492 480.179C613.27 494.602 655.897 432.118 653.74 381.763C651.454 328.637 606.414 315.448 591.685 290.263C585.824 280.263 585.447 268.748 593.68 250.702C599.556 237.824 608.392 224.179 618.697 207.798C641.156 172.095 669.307 136.711 668.233 76.8657C667.395 30.4552 629.493 -10.0683 590.564 -3.75179C555.891 1.84444 513.941 -24.9207 470.337 -9.83898Z"
                        stroke="url(#paint66_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M468.795 -15.4202C405.193 6.51451 408.149 73.3714 334.108 58.607C211.937 34.2489 149.977 117.258 148.821 189.283C147.685 260.573 233.944 334.232 308.436 286.037C396.219 229.234 420.478 285.225 415.575 310.272C403.355 372.703 461.565 461.949 543.43 479.35C610.851 493.661 653.707 430.295 651.325 379.519C648.807 325.917 603.232 312.799 588.532 287.466C582.707 277.425 582.336 265.735 590.861 247.479C596.942 234.47 606.033 220.72 616.568 204.222C639.432 168.398 667.702 132.51 666.26 72.1892C665.133 25.9006 627.715 -14.4568 588.802 -9.27889C554.227 -4.64216 512.312 -30.4327 468.795 -15.4202Z"
                        stroke="url(#paint67_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M467.443 -20.9815C403.086 1.14117 406.221 68.9531 331.163 53.8212C207.345 28.8665 144.403 112.765 143.077 185.623C141.764 257.776 229.08 332.354 304.449 283.442C393.364 225.735 417.979 282.625 412.704 308.12C399.433 372.053 458.021 461.346 540.578 478.568C608.662 492.765 651.743 428.514 649.137 377.325C646.386 323.259 600.267 310.202 585.548 284.681C579.701 274.529 579.423 262.711 588.226 244.271C594.502 231.123 603.889 217.231 614.618 200.656C637.887 164.712 666.272 128.315 664.424 67.5604C663.02 21.3931 626.071 -18.7828 587.236 -14.7088C552.744 -11.089 510.868 -35.9099 467.443 -20.9815Z"
                        stroke="url(#paint68_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M466.272 -26.4856C401.124 -4.14324 404.47 64.5875 328.398 49.0925C202.938 23.5265 139.004 108.324 137.509 182.016C136.024 255.036 224.401 330.527 300.639 280.9C390.716 222.306 415.697 280.114 410.024 306.025C395.688 371.475 454.681 460.784 537.912 477.839C606.639 491.923 649.954 426.796 647.125 375.185C644.136 320.648 597.487 307.657 582.757 281.981C576.899 271.748 576.689 259.789 585.77 241.144C592.24 227.866 601.91 213.848 612.852 197.185C636.534 161.111 665.039 124.21 662.8 63.0061C661.111 16.9458 624.638 -23.044 585.85 -20.0913C551.416 -17.5109 509.615 -41.3403 466.272 -26.4856Z"
                        stroke="url(#paint69_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M465.284 -31.9305C399.375 -9.40463 402.9 60.2811 325.807 44.4237C198.691 18.2715 133.788 103.943 132.118 178.463C130.443 252.371 219.895 328.761 297.011 278.417C388.194 218.896 413.568 277.645 407.507 303.991C392.113 370.962 451.489 460.279 535.419 477.169C604.804 491.145 648.344 425.132 645.29 373.099C642.065 318.102 594.879 305.172 580.17 279.305C574.302 268.99 574.176 256.904 583.523 238.047C590.193 224.633 600.136 210.489 611.285 193.734C635.382 157.54 664.011 120.129 661.371 58.4763C659.408 12.532 623.404 -27.2858 584.677 -25.47C550.318 -23.8232 508.555 -46.6928 465.284 -31.9305Z"
                        stroke="url(#paint70_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M464.483 -37.3299C397.806 -14.6102 401.509 56.0113 323.436 39.774C194.673 13.0251 128.788 99.5857 126.966 174.918C125.132 249.668 215.627 326.993 293.617 275.938C385.95 215.492 411.652 275.171 405.233 301.95C388.771 370.462 448.565 459.76 533.169 476.502C603.203 490.36 646.962 423.467 643.685 371.031C640.217 315.56 592.502 302.696 577.791 276.664C571.916 266.261 571.847 254.025 581.488 234.978C588.352 221.434 598.578 207.164 609.939 190.312C634.441 153.997 663.204 116.077 660.162 53.9752C657.921 8.14244 622.382 -31.4886 583.688 -30.7786C549.373 -30.0925 507.624 -52.0152 464.483 -37.3299Z"
                        stroke="url(#paint71_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M463.823 -42.6082C396.376 -19.6947 400.279 51.8902 321.159 35.297C190.745 7.94712 123.883 95.4013 121.85 171.604C119.844 247.221 211.391 325.461 290.263 273.693C383.688 212.38 409.803 272.954 402.996 300.158C385.461 370.208 445.666 459.482 530.95 476.07C601.637 489.816 645.626 422.041 642.117 369.159C638.418 313.223 590.162 300.425 575.459 274.227C569.578 263.738 569.577 251.361 579.5 232.114C586.559 218.431 597.059 204.045 608.637 187.1C633.547 150.65 662.45 112.224 658.997 49.6746C656.482 3.95787 621.408 -35.4864 582.761 -35.8977C548.615 -36.2634 506.899 -57.2405 463.823 -42.6082Z"
                        stroke="url(#paint72_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M463.353 -47.8105C395.132 -24.7077 399.228 47.8264 319.096 30.8702C187.025 2.92442 119.172 91.2781 117.001 168.278C114.823 244.763 207.422 323.916 287.172 271.432C381.751 209.189 408.206 270.712 401.02 298.341C382.419 369.951 443.035 459.193 528.998 475.617C600.333 489.255 644.552 420.599 640.835 367.309C636.897 310.903 588.123 298.189 573.411 271.802C567.519 261.231 567.582 248.709 577.774 229.288C585.027 215.465 595.804 200.948 607.595 183.916C632.91 147.345 661.952 108.404 658.097 45.4058C655.303 -0.199374 620.695 -39.4568 582.1 -40.9945C547.998 -42.3745 506.337 -62.3684 463.353 -47.8105Z"
                        stroke="url(#paint73_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M463.056 -52.8998C394.065 -29.6128 398.355 43.8802 317.2 26.5519C183.492 -1.99113 114.67 87.2359 112.307 165.085C109.951 242.433 203.611 322.5 284.24 269.3C379.951 206.152 406.758 268.609 399.218 296.646C379.525 369.804 440.572 459.021 527.214 475.292C599.182 488.789 643.544 419.287 639.705 365.573C635.424 308.704 586.205 296.031 571.511 269.487C565.614 258.838 565.739 246.171 576.232 226.53C583.684 212.572 594.735 197.929 606.738 180.809C632.44 144.14 661.657 104.662 657.378 41.2208C654.315 -4.27354 620.167 -43.3392 581.618 -46.0079C547.57 -48.3492 505.958 -67.384 463.056 -52.8998Z"
                        stroke="url(#paint74_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M462.913 -57.891C393.153 -34.4101 397.637 40.0322 315.47 22.3409C180.105 -6.79807 110.305 83.3129 107.767 161.991C105.25 240.215 199.974 321.172 281.468 267.28C378.3 203.219 405.466 266.604 397.552 295.08C376.779 369.795 438.247 458.978 525.563 475.09C598.21 488.497 642.767 418.112 638.717 363.96C634.204 306.626 584.45 294.014 569.754 267.305C563.845 256.564 564.048 243.756 574.813 223.916C582.465 209.823 593.812 195.038 606.015 177.846C632.138 141.026 661.481 101.058 656.787 37.1744C653.475 -8.19101 619.758 -47.0821 581.261 -50.8773C547.297 -54.2258 505.724 -72.2911 462.913 -57.891Z"
                        stroke="url(#paint75_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M462.934 -62.7486C392.405 -39.0836 397.073 36.3085 313.87 18.28C176.864 -11.451 106.081 79.5393 103.368 159.055C100.674 238.133 196.442 320.034 278.822 265.406C376.796 200.44 404.309 264.753 396.027 293.654C374.146 369.966 436.061 459.075 524.067 475.033C597.361 488.322 642.175 417.025 637.869 362.487C633.128 304.683 582.834 292.136 568.141 265.258C562.226 254.43 562.486 241.481 573.54 221.447C581.376 207.205 592.993 192.328 605.426 175.019C631.958 138.073 661.46 97.5987 656.335 33.268C652.733 -12.0143 619.498 -50.6857 581.052 -55.6074C547.182 -59.9734 505.662 -77.0846 462.934 -62.7486Z"
                        stroke="url(#paint76_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M463.144 -67.4785C391.84 -43.6242 396.698 32.732 312.505 14.3049C173.846 -16.0368 101.935 75.8526 99.204 156.196C96.1818 236.142 193.162 318.961 276.42 263.616C375.542 197.76 403.413 263.011 394.746 292.312C371.793 370.171 434.119 459.257 522.8 475.057C596.747 488.233 641.787 416.07 637.26 361.095C632.287 302.825 581.432 290.327 566.742 263.278C560.816 252.368 561.149 239.274 572.465 219.032C580.515 204.658 592.401 189.652 605.036 172.255C632.014 135.206 661.698 94.2182 656.144 29.4601C652.249 -15.6951 619.48 -54.1797 581.094 -60.2479C547.262 -65.5887 505.78 -81.7402 463.144 -67.4785Z"
                        stroke="url(#paint77_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M463.456 -72.0649C391.377 -48.0309 396.406 29.4364 311.222 10.4843C170.85 -20.1771 98.0384 72.3187 95.1232 153.501C91.9611 234.288 189.961 318.048 274.097 261.987C374.33 195.208 402.543 261.384 393.543 291.131C369.496 370.562 432.251 459.594 521.611 475.24C596.212 488.303 641.49 415.258 636.735 359.866C631.53 301.132 580.151 288.713 565.467 261.489C559.551 250.506 559.941 237.261 571.545 216.834C579.79 202.321 591.949 187.189 604.801 169.699C632.152 132.494 661.979 90.9853 655.99 25.7855C651.852 -19.2995 619.522 -57.5662 581.178 -64.7506C547.437 -71.0651 506.004 -86.2574 463.456 -72.0649Z"
                        stroke="url(#paint78_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M463.939 -76.4932C391.087 -52.2699 396.309 26.1467 310.108 6.83666C168.087 -24.4504 94.3098 68.9673 91.205 150.975C87.8626 232.649 186.933 317.303 271.941 260.53C373.302 192.843 401.868 259.952 392.504 290.118C367.368 371.136 430.556 460.108 520.6 475.596C595.849 488.542 641.362 414.62 636.378 358.811C630.94 299.602 579.021 287.25 564.349 259.854C558.407 248.775 558.876 235.399 570.783 214.791C579.227 200.142 591.659 184.884 604.723 167.308C632.483 129.967 662.483 87.946 656.055 22.3097C651.652 -22.6697 619.763 -60.7432 581.481 -69.0547C547.786 -76.3737 506.401 -90.6068 463.939 -76.4932Z"
                        stroke="url(#paint79_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M464.571 -80.738C390.948 -56.3402 396.363 23.0158 309.146 3.35755C165.486 -28.546 90.7284 65.7801 87.4392 148.617C83.9397 231.162 184.046 316.727 269.961 259.216C372.448 190.62 401.357 258.664 391.639 289.247C365.405 371.853 429.015 460.748 519.754 476.095C595.656 488.928 641.399 414.125 636.186 357.9C630.519 298.219 578.066 285.928 563.388 258.373C557.439 247.207 557.97 233.686 570.15 212.869C578.788 198.081 591.498 182.702 604.78 165.032C632.939 127.567 663.12 85.0133 656.244 18.9509C651.609 -25.9396 620.134 -63.7889 581.908 -73.232C548.276 -81.5131 506.945 -94.7824 464.571 -80.738Z"
                        stroke="url(#paint80_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M465.349 -84.8105C390.951 -60.2331 396.569 20.0715 308.326 0.0657856C163.027 -32.4641 87.2932 62.7748 83.8202 146.451C80.1577 229.853 181.312 316.338 268.081 258.136C371.705 188.631 400.948 257.609 390.862 288.636C363.52 372.829 427.589 461.668 518.992 476.849C595.546 489.559 641.523 413.88 636.082 357.237C630.172 297.073 577.189 284.867 562.514 257.141C556.564 245.893 557.163 232.221 569.615 211.216C578.458 196.287 591.441 180.782 604.935 163.025C633.508 125.429 663.862 82.3626 656.541 15.8592C651.603 -28.9229 620.604 -66.5763 582.439 -77.1465C548.928 -86.4762 507.636 -98.776 465.349 -84.8105Z"
                        stroke="url(#paint81_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M466.264 -88.6904C391.071 -63.9514 396.908 17.3153 307.653 -3.04377C160.706 -36.1895 84.001 59.9573 80.3436 144.463C76.5195 228.741 178.715 316.141 266.376 257.207C371.122 186.798 400.713 256.705 390.286 288.135C361.847 373.925 426.352 462.691 518.467 477.691C595.67 490.284 641.881 413.728 636.215 356.663C630.084 296.052 576.597 283.861 561.872 255.993C555.931 244.652 556.583 230.845 569.318 209.641C578.36 194.577 591.612 178.951 605.324 161.101C634.3 123.375 664.836 79.7864 657.062 12.8524C651.873 -31.8299 621.312 -69.2751 583.203 -80.977C549.708 -91.246 508.469 -102.592 466.264 -88.6904Z"
                        stroke="url(#paint82_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M467.333 -92.347C391.384 -67.42 397.382 14.8031 307.118 -5.92424C158.527 -39.7007 80.8515 57.3634 77.0149 142.703C73.0329 227.843 176.157 315.996 264.796 256.523C370.53 185.03 400.598 256.042 389.827 287.899C360.254 375.264 425.26 463.81 518.003 478.84C595.883 491.177 642.301 413.883 636.406 356.402C630.047 295.32 576.011 283.206 561.298 255.157C555.346 243.734 556.07 229.781 569.092 208.362C578.333 193.163 591.867 177.411 605.791 159.464C635.181 121.608 665.909 77.4954 657.677 10.1359C652.222 -34.46 622.081 -71.6668 584.051 -84.5262C550.622 -95.772 509.445 -106.174 467.333 -92.347Z"
                        stroke="url(#paint83_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M468.529 -95.8103C391.806 -70.7036 398.03 12.4615 306.721 -8.61203C156.487 -43.0096 77.8397 54.9623 73.8051 141.146C69.6556 227.149 173.862 316.233 263.344 256.032C370.245 183.647 400.634 255.565 389.495 287.855C358.836 376.837 424.265 465.315 517.7 480.156C596.22 492.396 642.885 414.21 636.76 356.302C630.169 294.755 575.588 282.703 560.883 254.488C554.938 242.962 555.721 228.879 569.003 207.287C578.448 191.947 592.251 176.065 606.359 158.052C636.149 120.061 667.077 75.4243 658.377 7.64002C652.667 -36.8605 622.981 -73.88 584.994 -87.8458C551.673 -100.115 510.554 -109.568 468.529 -95.8103Z"
                        stroke="url(#paint84_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M469.864 -99.0623C392.376 -73.7767 398.778 10.3241 306.463 -11.0886C154.585 -46.1072 74.9898 52.7563 70.7522 139.781C66.4455 226.654 171.68 316.67 262.054 255.736C370.108 182.475 400.791 255.311 389.325 287.998C357.549 378.587 423.426 467.01 517.519 481.661C596.694 493.817 643.586 414.72 637.233 356.395C630.415 294.378 575.289 282.397 560.591 254.007C554.6 242.441 555.491 228.17 569.055 206.378C578.695 190.899 592.786 174.905 607.12 156.789C637.323 118.658 668.443 73.4974 659.28 5.29274C653.314 -39.1124 624.028 -75.8482 586.127 -91.011C552.843 -104.192 511.807 -112.747 469.864 -99.0623Z"
                        stroke="url(#paint85_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M471.323 -102.061C393.044 -76.6091 399.66 8.44934 306.323 -13.3161C152.802 -48.9558 72.2036 50.8419 67.8144 138.679C63.3403 226.415 169.655 317.396 260.844 255.692C370.047 181.55 401.047 255.297 389.232 288.407C356.373 380.597 422.669 468.958 517.46 483.453C597.28 495.516 644.42 415.518 637.833 356.781C630.782 294.289 575.112 282.379 560.421 253.813C554.441 242.139 555.387 227.744 569.224 205.763C579.068 190.143 593.422 174.014 607.979 155.82C638.59 117.554 669.916 71.8639 660.272 3.24032C654.049 -41.0792 625.205 -77.6119 587.355 -93.9108C554.156 -108.089 513.174 -115.671 471.323 -102.061Z"
                        stroke="url(#paint86_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M472.899 -104.813C393.816 -79.3061 400.664 6.8252 306.311 -15.2884C151.14 -51.5538 69.5809 49.1608 64.9976 137.828C60.4143 226.495 167.739 318.355 259.82 255.918C370.176 180.9 401.478 255.545 389.312 289.058C355.303 382.854 422.081 471.162 517.564 485.468C598.033 497.433 645.406 416.539 638.604 357.37C631.32 294.412 575.101 282.57 560.422 253.833C554.432 242.077 555.445 227.531 569.566 205.361C579.633 189.599 594.199 173.32 609.005 155.059C639.968 116.609 671.561 70.4436 661.435 1.40104C654.956 -42.8328 626.549 -79.1671 588.772 -96.5648C555.6 -111.737 514.648 -118.411 472.899 -104.813Z"
                        stroke="url(#paint87_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M474.609 -107.303C394.765 -81.5684 401.788 5.4599 306.418 -17.0019C149.605 -53.8883 67.0737 47.734 62.3015 137.236C57.5293 226.737 165.948 319.567 258.863 256.412C370.358 180.513 401.997 256.078 389.487 290.019C354.362 385.422 421.593 473.67 517.734 487.805C598.849 499.677 646.459 417.882 639.435 358.319C631.924 294.891 575.16 283.12 560.465 254.234C554.465 242.396 555.524 227.681 569.953 205.335C580.164 189.408 595.075 173.046 610.078 154.684C641.47 116.126 673.248 69.4042 662.65 -0.0580533C659.457 -21.1334 651.201 -41.1304 638.589 -58.3341C625.976 -75.5378 609.384 -89.4348 590.235 -98.8334C557.159 -115.14 516.278 -120.798 474.609 -107.303Z"
                        stroke="url(#paint88_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                    <path
                        d="M664.084 -1.42613C650.958 -84.1461 565.436 -138.187 476.43 -109.524C395.824 -83.5622 403.037 4.367 306.646 -18.4475C148.194 -55.9505 64.6817 46.5801 59.7251 136.911C54.7685 227.242 164.273 321.052 258.072 257.132C370.731 180.365 402.656 256.815 389.812 291.182C353.571 388.203 421.254 476.38 518.112 490.33C599.87 502.099 647.724 419.408 640.45 359.41C629.535 269.325 520.084 282.987 570.566 205.413C602.874 155.748 680.465 101.813 664.084 -1.42613Z"
                        stroke="url(#paint89_linear_11_157)" stroke-width="0.506358" stroke-miterlimit="10"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_11_157" x1="1046.43" y1="223.786" x2="635.195" y2="328.338"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00CBFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_11_157" x1="1049.3" y1="222.559" x2="632.488" y2="326.852"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#00CBFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_11_157" x1="1052.06" y1="221.58" x2="629.608" y2="325.825"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stopColor="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_11_157" x1="1054.78" y1="220.855" x2="626.579" y2="325.301"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_11_157" x1="1057.3" y1="220.278" x2="623.309" y2="325.049"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_11_157" x1="1059.68" y1="219.859" x2="619.865" y2="325.071"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear_11_157" x1="1061.87" y1="219.587" x2="616.207" y2="325.342"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint7_linear_11_157" x1="1063.88" y1="219.43" x2="612.333" y2="325.802"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint8_linear_11_157" x1="1065.72" y1="219.351" x2="608.303" y2="326.404"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint9_linear_11_157" x1="1067.35" y1="219.399" x2="603.988" y2="327.169"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint10_linear_11_157" x1="1068.82" y1="219.512" x2="599.517" y2="328.062"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint11_linear_11_157" x1="1070.12" y1="219.721" x2="594.856" y2="329.093"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint12_linear_11_157" x1="1071.26" y1="219.961" x2="589.991" y2="330.18"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint13_linear_11_157" x1="1072.19" y1="220.297" x2="584.961" y2="331.421"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint14_linear_11_157" x1="1072.99" y1="220.643" x2="579.731" y2="332.671"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint15_linear_11_157" x1="1073.56" y1="221.059" x2="574.331" y2="334.035"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint16_linear_11_157" x1="1074.07" y1="221.514" x2="568.785" y2="335.479"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint17_linear_11_157" x1="1074.39" y1="221.985" x2="563.062" y2="336.95"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint18_linear_11_157" x1="1074.51" y1="222.485" x2="557.171" y2="338.464"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint19_linear_11_157" x1="1074.58" y1="222.956" x2="551.127" y2="339.963"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint20_linear_11_157" x1="1074.48" y1="223.458" x2="544.937" y2="341.512"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint21_linear_11_157" x1="1074.23" y1="224.035" x2="538.657" y2="343.22"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint22_linear_11_157" x1="1073.96" y1="224.454" x2="532.188" y2="344.692"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint23_linear_11_157" x1="1073.43" y1="225.016" x2="525.579" y2="346.391"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint24_linear_11_157" x1="1072.86" y1="225.472" x2="518.903" y2="347.97"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint25_linear_11_157" x1="1072.2" y1="225.878" x2="512.063" y2="349.497"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint26_linear_11_157" x1="1071.39" y1="226.277" x2="505.151" y2="351.036"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint27_linear_11_157" x1="1070.51" y1="226.697" x2="498.117" y2="352.643"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint28_linear_11_157" x1="1069.52" y1="227.025" x2="490.992" y2="354.147"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint29_linear_11_157" x1="1068.48" y1="227.273" x2="483.777" y2="355.569"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint30_linear_11_157" x1="1067.37" y1="227.512" x2="476.445" y2="357.006"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint31_linear_11_157" x1="1066.11" y1="227.684" x2="469.058" y2="358.379"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint32_linear_11_157" x1="1064.83" y1="227.788" x2="461.6" y2="359.708"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint33_linear_11_157" x1="1063.52" y1="227.793" x2="454.074" y2="360.932"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint34_linear_11_157" x1="1062.11" y1="227.709" x2="446.474" y2="362.061"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint35_linear_11_157" x1="1060.69" y1="227.529" x2="438.808" y2="363.1"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint36_linear_11_157" x1="1059.22" y1="227.263" x2="431.121" y2="364.073"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint37_linear_11_157" x1="1057.69" y1="226.917" x2="423.387" y2="364.98"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint38_linear_11_157" x1="1056.14" y1="226.47" x2="415.619" y2="365.794"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint39_linear_11_157" x1="1054.56" y1="225.866" x2="407.787" y2="366.436"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint40_linear_11_157" x1="1052.93" y1="225.256" x2="400.009" y2="367.133"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint41_linear_11_157" x1="1051.42" y1="224.368" x2="392.145" y2="367.466"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint42_linear_11_157" x1="1049.74" y1="223.414" x2="384.271" y2="367.783"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint43_linear_11_157" x1="1048.12" y1="222.367" x2="376.417" y2="368.057"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint44_linear_11_157" x1="1046.51" y1="221.151" x2="368.567" y2="368.124"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint45_linear_11_157" x1="1044.98" y1="219.734" x2="360.702" y2="367.973"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint46_linear_11_157" x1="1043.43" y1="218.197" x2="352.862" y2="367.72"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint47_linear_11_157" x1="1041.91" y1="216.461" x2="345.025" y2="367.248"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint48_linear_11_157" x1="1040.41" y1="214.653" x2="337.26" y2="366.745"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint49_linear_11_157" x1="1038.95" y1="212.552" x2="329.47" y2="365.918"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint50_linear_11_157" x1="1037.3" y1="210.348" x2="321.655" y2="365.073"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint51_linear_11_157" x1="1034.63" y1="207.448" x2="312.599" y2="363.453"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint52_linear_11_157" x1="1032.14" y1="204.403" x2="303.79" y2="361.699"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint53_linear_11_157" x1="1029.88" y1="201.271" x2="295.215" y2="359.875"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint54_linear_11_157" x1="1027.89" y1="197.958" x2="286.87" y2="357.849"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint55_linear_11_157" x1="1026.11" y1="194.581" x2="278.75" y2="355.782"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint56_linear_11_157" x1="1024.51" y1="191.124" x2="270.852" y2="353.641"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint57_linear_11_157" x1="1023.18" y1="187.573" x2="263.165" y2="351.401"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint58_linear_11_157" x1="1022.02" y1="183.955" x2="255.705" y2="349.104"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint59_linear_11_157" x1="1021.16" y1="180.23" x2="248.448" y2="346.685"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint60_linear_11_157" x1="1020.47" y1="176.445" x2="241.358" y2="344.193"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint61_linear_11_157" x1="1020" y1="172.701" x2="234.542" y2="341.786"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint62_linear_11_157" x1="1019.68" y1="168.906" x2="227.908" y2="339.32"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint63_linear_11_157" x1="1019.64" y1="165.028" x2="221.449" y2="336.73"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint64_linear_11_157" x1="1019.78" y1="161.154" x2="215.19" y2="334.146"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint65_linear_11_157" x1="1020.11" y1="157.322" x2="209.137" y2="331.657"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint66_linear_11_157" x1="1020.67" y1="153.506" x2="203.282" y2="329.181"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint67_linear_11_157" x1="1021.38" y1="149.637" x2="197.568" y2="326.581"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint68_linear_11_157" x1="1022.28" y1="145.875" x2="192.083" y2="324.146"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint69_linear_11_157" x1="1023.37" y1="142.151" x2="186.771" y2="321.745"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint70_linear_11_157" x1="1024.68" y1="138.437" x2="181.608" y2="319.321"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint71_linear_11_157" x1="1026.19" y1="134.768" x2="176.719" y2="316.977"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint72_linear_11_157" x1="1027.77" y1="131.295" x2="171.841" y2="314.802"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint73_linear_11_157" x1="1029.6" y1="127.836" x2="167.239" y2="312.661"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint74_linear_11_157" x1="1031.61" y1="124.423" x2="162.754" y2="310.498"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint75_linear_11_157" x1="1033.78" y1="121.199" x2="158.477" y2="308.602"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint76_linear_11_157" x1="1036.1" y1="118.089" x2="154.306" y2="306.785"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint77_linear_11_157" x1="1038.66" y1="115.071" x2="150.324" y2="305.092"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint78_linear_11_157" x1="1041.32" y1="112.181" x2="146.494" y2="303.464"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint79_linear_11_157" x1="1044.15" y1="109.463" x2="142.798" y2="302.032"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint80_linear_11_157" x1="1047.16" y1="106.881" x2="139.251" y2="300.72"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint81_linear_11_157" x1="1050.24" y1="104.571" x2="135.879" y2="299.73"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint82_linear_11_157" x1="1053.6" y1="102.302" x2="132.594" y2="298.701"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint83_linear_11_157" x1="1057" y1="100.322" x2="129.492" y2="297.997"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint84_linear_11_157" x1="1060.56" y1="98.567" x2="126.513" y2="297.532"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint85_linear_11_157" x1="1064.27" y1="96.962" x2="123.661" y2="297.181"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint86_linear_11_157" x1="1068.15" y1="95.612" x2="120.923" y2="297.095"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint87_linear_11_157" x1="1072.21" y1="94.4489" x2="118.284" y2="297.156"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint88_linear_11_157" x1="1076.29" y1="93.6706" x2="115.822" y2="297.681"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <linearGradient id="paint89_linear_11_157" x1="1080.68" y1="92.9512" x2="113.406" y2="298.146"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00CBFF"/>
                        <stop offset="1" stop-color="#00FFA1"/>
                    </linearGradient>
                    <clipPath id="clip0_11_157">
                        <rect width="794.803" height="582.406" fill="white"
                              transform="matrix(-0.754587 -0.6562 -0.6562 0.754587 981.923 232.55)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default Back1;